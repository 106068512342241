import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, TabContent, TabPane, Nav, NavItem, NavLink, Table } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getCampaignRead,
  postAvailability,
  postAvailabilityException,
} from "../../../helpers/backend";
import WeeklyCalendar from "./WeeklyCalendar";
import "./AvailabilityStyles.css";

const AvailabilityForm = (props) => {
  const campaignId = useParams().id;
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [selectedDay, setSelectedDay] = useState("");
  const [initialAvailability, setInitialAvailability] = useState([]);
  const [initialExceptions, setInitialExceptions] = useState([]);
  const [newAvailability, setNewAvailability] = useState([]);
  const [newExceptions, setNewExceptions] = useState([]);
  const [currentStartTime, setCurrentStartTime] = useState("06:00 AM");
  const [currentEndTime, setCurrentEndTime] = useState("07:00 AM");
  const [isRecurrent, setIsRecurrent] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [maxCitas, setMaxCitas] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [refreshCalendar, setRefreshCalendar] = useState(false);

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const getData = async () => {
    try {
      const response = await getCampaignRead(campaignId);
      const { horarios_atencion, excepciones_horario } = response.data;
      
      const formattedAvailability = (horarios_atencion || []).map(item => ({
        ...item,
        startTime: item.hora_inicio,
        endTime: item.hora_fin,
        maxCitas: item.max_citas,
        dayOfWeek: item.dia_semana
      }));
      
      const formattedExceptions = (excepciones_horario || []).map(item => ({
        ...item,
        startTime: item.hora_inicio,
        endTime: item.hora_fin,
        maxCitas: item.max_citas,
        date: item.fecha,
        motivo: item.motivo,
        horario_especial: item.horario_especial
      }));

      setInitialAvailability(formattedAvailability);
      setInitialExceptions(formattedExceptions);
      setRefreshCalendar(prev => !prev);
    } catch (error) {
      console.error("Error al obtener datos de disponibilidad:", error);
      setAlert({
        type: "error",
        message: "Hubo un error al cargar los datos de disponibilidad.",
      });
    }
  };

  const normalizeDayOfWeek = (day) => {
    if (!day) return '';
    return day
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
  };

  const handleAddAvailability = (newEntry) => {
    const isRecurrentEntry = !!newEntry.dayOfWeek;
    
    if (isRecurrentEntry) {
      const normalizedDay = normalizeDayOfWeek(newEntry.dayOfWeek);
      const availabilityItem = {
        ...newEntry,
        dayOfWeek: normalizedDay,
        dia_semana: normalizedDay,
        hora_inicio: newEntry.startTime,
        hora_fin: newEntry.endTime,
        max_citas: newEntry.maxCitas
      };

      const isDuplicate = [...initialAvailability, ...newAvailability].some(
        (item) =>
          normalizeDayOfWeek(item.dia_semana || item.dayOfWeek) === normalizedDay &&
          (item.hora_inicio || item.startTime) === newEntry.startTime &&
          (item.hora_fin || item.endTime) === newEntry.endTime
      );

      if (isDuplicate) {
        setAlert({
          type: "error",
          message: "Este horario ya existe. No se puede agregar duplicados.",
        });
        return;
      }

      setNewAvailability((prev) => [...prev, availabilityItem]);
    } else {
      const exceptionItem = {
        ...newEntry,
        fecha: newEntry.date,
        hora_inicio: newEntry.startTime,
        hora_fin: newEntry.endTime,
        max_citas: newEntry.maxCitas,
        motivo: newEntry.motivo || "Horario especial",
        horario_especial: newEntry.horario_especial || false
      };

      const isDuplicate = [...initialExceptions, ...newExceptions].some(
        (item) =>
          (item.fecha || item.date) === newEntry.date &&
          (item.hora_inicio || item.startTime) === newEntry.startTime &&
          (item.hora_fin || item.endTime) === newEntry.endTime
      );

      if (isDuplicate) {
        setAlert({
          type: "error",
          message: "Esta excepción ya existe. No se puede agregar duplicados.",
        });
        return;
      }

      setNewExceptions((prev) => [...prev, exceptionItem]);
    }
  };

  const handleDeleteAvailability = (index, isInitial) => {
    if (isRecurrent) {
      if (isInitial) {
        const updatedInitial = [...initialAvailability];
        updatedInitial[index].deleted = true;
        setInitialAvailability(updatedInitial);
      } else {
        const updatedNew = [...newAvailability];
        updatedNew.splice(index, 1);
        setNewAvailability(updatedNew);
      }
    } else {
      if (isInitial) {
        const updatedInitial = [...initialExceptions];
        updatedInitial[index].deleted = true;
        setInitialExceptions(updatedInitial);
      } else {
        const updatedNew = [...newExceptions];
        updatedNew.splice(index, 1);
        setNewExceptions(updatedNew);
      }
    }
    setRefreshCalendar(prev => !prev);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      // Solo procesar horarios manuales nuevos
      if (newAvailability.length > 0) {
        const availabilityData = newAvailability.map((entry) => ({
          campaign: campaignId,
          dia_semana: entry.dayOfWeek,
          hora_inicio: entry.startTime,
          hora_fin: entry.endTime,
          max_citas: entry.maxCitas,
        }));
        await postAvailability(availabilityData);
      }

      if (newExceptions.length > 0) {
        const exceptionData = newExceptions.map((entry) => ({
          campaign: campaignId,
          fecha: entry.date,
          motivo: entry.motivo,
          hora_inicio: entry.startTime,
          hora_fin: entry.endTime,
          max_citas: entry.maxCitas,
          horario_especial: entry.horario_especial
        }));
        await postAvailabilityException(exceptionData);
      }

      setAlert({
        type: "success",
        message: "Se han guardado los horarios manuales correctamente.",
      });
      
      await getData();
      setNewAvailability([]);
      setNewExceptions([]);
    } catch (error) {
      console.error("Error al guardar la disponibilidad:", error);
      setAlert({
        type: "error",
        message: "Hubo un error al guardar los horarios manuales.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const daysOfWeek = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];

  const generateHourOptions = () => {
    const hours = [];
    for (let i = 6; i <= 22; i++) {
      const hour =
        i < 12 ? `${i}:00 AM` : i === 12 ? "12:00 PM" : `${i - 12}:00 PM`;
      hours.push(hour);
    }
    return hours;
  };

  const convertTo24HourFormat = (time12hr) => {
    const [time, period] = time12hr.split(" ");
    const [hours, minutes] = time.split(":");
    let hours24 = parseInt(hours, 10);
    if (period === "PM" && hours24 !== 12) {
      hours24 += 12;
    } else if (period === "AM" && hours24 === 12) {
      hours24 = 0;
    }
    return `${hours24.toString().padStart(2, "0")}:${minutes}`;
  };

  const formatTo12HourFormat = (time24hr) => {
    const [hours, minutes] = time24hr.split(":");
    const parsedHours = parseInt(hours, 10);
    const period = parsedHours >= 12 ? "PM" : "AM";
    const formattedHours = parsedHours % 12 || 12;
    return `${formattedHours}:${minutes} ${period}`;
  };

  const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleDateString("es-ES", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const filteredInitialAvailability = initialAvailability.filter(item => !item.deleted);
  const filteredInitialExceptions = initialExceptions.filter(item => !item.deleted);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="p-4 availability-form-container">
          <h4 className="text-primary">Horario de Campaña</h4>
          <hr />
          <Form style={{ paddingRight: "20px", paddingLeft: "20px" }}>
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <div className="mb-2 d-flex justify-content-center">
                    <Button
                      color={isRecurrent ? "primary" : "light"}
                      onClick={() => setIsRecurrent(true)}
                      className="mr-2 btn-sm ms-1"
                    >
                      Horario Semanal
                    </Button>
                    <Button
                      color={!isRecurrent ? "primary" : "light"}
                      onClick={() => setIsRecurrent(false)}
                      className="mr-2 btn-sm ms-1"
                    >
                      Excepciones
                    </Button>
                  </div>
                </Col>
              </Row>
              <Nav tabs className="availability-tabs">
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => setActiveTab("1")}
                  >
                    <i className="fas fa-calendar-alt me-2"></i>Calendario
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => setActiveTab("2")}
                  >
                    <i className="fas fa-keyboard me-2"></i>Manual
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <WeeklyCalendar
                    key={refreshCalendar ? 'refresh' : 'normal'}
                    onAddAvailability={activeTab === "2" ? handleAddAvailability : null}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    isRecurrent={isRecurrent}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    initialAvailability={filteredInitialAvailability}
                    initialExceptions={filteredInitialExceptions}
                    normalizeDayOfWeek={normalizeDayOfWeek}
                    campaignId={campaignId}
                    activeTab={activeTab}
                    getData={getData}
                    setAlert={setAlert}
                  />
                </TabPane>
                <TabPane tabId="2">
                  {isRecurrent && (
                    <Row>
                      <Col lg={12}>
                        <label className="required-field">Selecciona un día:</label>
                        <div className="mb-2 d-flex justify-content-center">
                          {daysOfWeek.map((day) => (
                            <Button
                              key={day}
                              color={selectedDay === day ? "primary" : "light"}
                              onClick={() => setSelectedDay(day)}
                              className="mr-2 btn-sm ms-1"
                              title={`Seleccionar ${day}`}
                            >
                              {day.substring(0, 3)}
                            </Button>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {!isRecurrent && (
                    <Row>
                      <Col lg={4}>
                        <label className="required-field">Selecciona Fecha:</label>
                        <input
                          type="date"
                          className="form-control"
                          value={selectedDate || ""}
                          onChange={(e) => setSelectedDate(e.target.value)}
                          min={new Date().toISOString().split('T')[0]}
                        />
                      </Col>
                    </Row>
                  )}
                  <div className="time-selector-container">
                    <Row className="time-selector-row">
                      <Col lg={4}>
                        <label className="required-field">Hora de inicio:</label>
                        <select
                          className="form-select"
                          value={currentStartTime}
                          onChange={(e) => setCurrentStartTime(e.target.value)}
                        >
                          {generateHourOptions().map((hour) => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col lg={4}>
                        <label className="required-field">Hora de fin:</label>
                        <select
                          className="form-select"
                          value={currentEndTime}
                          onChange={(e) => setCurrentEndTime(e.target.value)}
                        >
                          {generateHourOptions().map((hour) => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col lg={2}>
                        <label className="required-field">Máximo de citas:</label>
                        <input
                          type="number"
                          className="form-control"
                          value={maxCitas}
                          onChange={(e) => setMaxCitas(parseInt(e.target.value, 10))}
                          min="1"
                        />
                      </Col>
                      <Col className="d-flex align-items-end">
                        <Button
                          color="primary"
                          className="add-time-btn"
                          onClick={() => {
                            if ((isRecurrent && !selectedDay) || (!isRecurrent && !selectedDate)) {
                              setAlert({
                                type: "error",
                                message: isRecurrent 
                                  ? "Debes seleccionar un día de la semana" 
                                  : "Debes seleccionar una fecha"
                              });
                              return;
                            }
                            handleAddAvailability({
                              date: selectedDate,
                              startTime: convertTo24HourFormat(currentStartTime),
                              endTime: convertTo24HourFormat(currentEndTime),
                              maxCitas,
                              dayOfWeek: selectedDay,
                            });
                          }}
                          title="Agregar horario"
                        >
                          <i className="fas fa-plus me-2"></i>Agregar
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </TabContent>
              {activeTab === "2" && (
                <Row className="mt-4">
                  <Col>
                    <Table
                      className="availability-table"
                      responsive
                      hover
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "200px" }}>Día/Fecha</th>
                          <th>Hora de inicio</th>
                          <th>Hora de fin</th>
                          <th>Máximo de citas</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(isRecurrent
                          ? [...filteredInitialAvailability, ...newAvailability]
                          : [...filteredInitialExceptions, ...newExceptions]
                        ).map((entry, index) => (
                          <tr key={`${isRecurrent ? 'recurrent' : 'exception'}-${index}`}>
                            <td>
                              {isRecurrent
                                ? entry.dia_semana || entry.dayOfWeek
                                : formatDate(entry.fecha || entry.date)}
                            </td>
                            <td>
                              {formatTo12HourFormat(
                                entry.hora_inicio || entry.startTime
                              )}
                            </td>
                            <td>
                              {formatTo12HourFormat(
                                entry.hora_fin || entry.endTime
                              )}
                            </td>
                            <td>{entry.max_citas || entry.maxCitas}</td>
                            <td>
                              <Button
                                color="link"
                                className="delete-btn text-danger"
                                onClick={() => handleDeleteAvailability(
                                  index, 
                                  index < (isRecurrent 
                                    ? filteredInitialAvailability.length 
                                    : filteredInitialExceptions.length)
                                )}
                                title="Eliminar horario"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                        {[...filteredInitialAvailability, ...newAvailability].length === 0 && 
                         [...filteredInitialExceptions, ...newExceptions].length === 0 && (
                          <tr>
                            <td colSpan="5" className="text-center text-muted py-4">
                              <i className="fas fa-calendar-times fa-2x mb-2"></i>
                              <p>No hay horarios configurados</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
            </Container>
          </Form>
          {activeTab === "2" && (
            <Row>
              <Col className="d-flex justify-content-end mt-3 action-buttons">
                <Button className="btn btn-light text-dark me-2">
                  <i className="fas fa-arrow-left me-2"></i>Atrás
                </Button>
                <Button
                  className={`btn btn-primary ${isSubmitting ? 'btn-submitting' : ''}`}
                  onClick={handleSubmit}
                  disabled={isSubmitting || (newAvailability.length === 0 && newExceptions.length === 0)}
                >
                  {isSubmitting ? "Guardando..." : (
                    <>
                      <i className="fas fa-save me-2"></i>Guardar
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          )}
        </div>

        {alert.type === "success" && (
          <SweetAlert
            title={<><i className="fas fa-check-circle me-2"></i> Éxito!</>}
            success
            timeout={2000}
            onConfirm={() => setAlert({ type: "", message: "" })}
          >
            {alert.message}
          </SweetAlert>
        )}

        {alert.type === "error" && (
          <SweetAlert
            title={<><i className="fas fa-exclamation-circle me-2"></i> Error</>}
            danger
            timeout={2000}
            onConfirm={() => setAlert({ type: "", message: "" })}
          >
            {alert.message}
          </SweetAlert>
        )}
      </div>
    </React.Fragment>
  );
};

export default AvailabilityForm;