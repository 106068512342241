import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  Form,
  InputGroup,
  Input,
  Table
} from "reactstrap";
import Select from "react-select";
import styles from "../Contacts/Contacts.module.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";
import { projectFilter, projectList } from "../../store/project/actions";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getContactsFullList,
  getGrupoContactoFilter,
  postProjectCreate
} from "../../helpers/backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faList } from "@fortawesome/free-solid-svg-icons";
import { TbLayoutList, TbLayoutGrid, } from "react-icons/tb";
import './ProjectList.css'


const ProjectList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState([]);
  const [contactGroup, setContactGroup] = useState([]);
    const [active, setActive] = useState(true)
  
  const [formData, setFormData] = useState({
    name: "",
    descriptions: "",
    group_contact: [],
    contact: []
  });
  const [viewMode, setViewMode] = useState("list"); // Estado para controlar la vista

  const getContactData = async () => {
    const response = await getContactsFullList();
    setContacts(response.data);
  };

  const getContactGroup = async () => {
    try {
      const response = await getGrupoContactoFilter("FullList/");
      setContactGroup(response.data);
    } catch (error) {
      console.error("Error fetching contact group data", error);
    }
  };

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    dispatch(projectFilter(`?search=${search}`));
  };

  const resetSearch = () => {
    dispatch(projectFilter(`?search=`));
    setSearch("");
  };

  const toggleModal = () => setModal(!modal);

  const onSubmit = async () => {
    const groupContactIds = formData.group_contact.map(
      (option) => option.value
    ); // Extrae los IDs
    const contactIds = formData.contact.map((option) => option.value); // Extrae los IDs

    const payload = {
      ...formData,
      group_contact: groupContactIds, // Envía solo los IDs
      contacts: contactIds // Envía solo los IDs
    };

    await postProjectCreate(payload);
    dispatch(projectList());
    setModal(false);
  };

  useEffect(() => {
    dispatch(projectList());
    getContactData();
    getContactGroup();
  }, [dispatch]);

  const params = {
    count: props.project.count,
    next: props.project.next,
    previous: props.project.previous,
    results: props.project.results
  };

  const theme = useSelector((state) => state.theme.theme) || 'light'

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Proyecto</title>
        </MetaTags>
        <Container fluid>
          <div style={{ width: "100%", paddingBottom: "1.5rem" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>
              Proyecto
            </span>
          </div>
          <Card>
            <CardBody>
              <Row>
                <Col md={7}>
                  <Link
                    className="btn btn-primary waves-effect waves-light"
                    to="#"
                    onClick={() => setModal(true)}
                  >
                    Agregar{" "}
                  </Link>
                </Col>
                <Col md={5} className="d-flex justify-content-end gap-4">
                  <Form className="search-box ms-2" onSubmit={onSubmitSearch}>
                    <InputGroup>
                      <Input
                        type="text"
                        className="form-control"
                        name="search"
                        placeholder="Buscar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="bx bx-search-alt search-icon" />
                      <Button color="primary" onClick={resetSearch}>
                        <span className=" bx bx-x fa-lg align-middle" />
                      </Button>
                    </InputGroup>
                  </Form>
                  <div className="d-flex gap-2 justify-content-end mb-1">
                    <button
                      className={` ${
                        theme === "dark"
                          ? "button_view_dark"
                          : "button_view"
                      } ${!active ? "border-primary" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setViewMode("card");
                      }}
                    >
                      <TbLayoutGrid
                        className={` ${active ? "text-dark" : "text-primary"} `}
                        size={20}
                        strokeWidth={1.9}
                      />
                    </button>
                    <button
                      className={` ${
                        theme === "dark"
                          ? "button_view_dark"
                          : "button_view"
                      } ${active ? "border-primary" : ""}`}
                      onClick={() => {
                        setActive(true);
                        setViewMode("list");
                      }}
                    >
                      <TbLayoutList
                        className={` ${active ? "text-primary" : "text-dark"}`}
                        size={20}
                        strokeWidth={1.9}
                      />
                    </button>{" "}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {props.loading ? (
            <Loader />
          ) : params.results && params.results.length > 0 ? (
            viewMode === "card" ? (
              <div className={`${styles["card-container"]}`}>
                {params.results.map((item) => (
                  <Card
                    key={item.id}
                    className={`${styles.card} custom-card`}
                    onClick={() => history.push(`/tasks/${item.id}`)}
                  >
                    <CardBody className="d-flex flex-column justify-content-between h-100">
                      <div className=" justify-content-center">
                        <h5 className="text-dark">{item.name}</h5>
                        <h6>{item.descriptions}</h6>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </div>
            ) : (
              <div className="table-responsive mt-3">
                <Table
                  responsive
                  hover
                  className={`font-size-13 text-center ${
                    theme === "dark" ? "table-dark" : "table-light"
                  }`}
                >
                  <thead>
                    <tr>
                      <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                        Nombre
                      </th>
                      <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                        Descripción
                      </th>
                      <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                        Creado por
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className={` ${theme === "dark" ? "" : "table-light"}`}
                  >
                    {params.results.map((item) => (
                      <tr
                        onClick={() => history.push(`/tasks/${item.id}`)}
                        style={{ cursor: "pointer" }}
                        key={item.id}
                      >
                        <td
                          style={{
                            backgroundColor:
                              theme === "dark" ? "#121e2d" : "#fff",
                            height: "60px",
                          }}
                        >
                          {item.name}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              theme === "dark" ? "#121e2d" : "#fff",
                          }}
                          className={!item.descriptions ? "text-muted" : null}
                        >
                          {item.descriptions
                            ? item.descriptions
                            : "Sin descripción"}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              theme === "dark" ? "#121e2d" : "#fff",
                          }}
                        >
                          {item.created_by}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )
          ) : (
            <CardBody>
              <h4 className="card-title">No se encontraron resultados</h4>
            </CardBody>
          )}
          <Pagination
            count={params.count}
            next={params.next}
            previous={params.previous}
            limit={30}
            baseUrl="task/project/"
            filterFunction={projectList}
          />
        </Container>
      </div>
      {modal && (
        <Modal isOpen={modal} toggle={() => toggleModal(null)} size="lg">
          <ModalBody>
            <h4 className="mb-4">Agregar Proyecto</h4>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nombre del Proyecto
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={formData.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="descriptions" className="form-label">
                  Descripción
                </label>
                <textarea
                  className="form-control"
                  id="descriptions"
                  value={formData.descriptions}
                  onChange={(e) => handleChange("descriptions", e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="group_contact" className="form-label">
                  Grupo de Contactos
                </label>
                <Select
                  id="group_contact"
                  options={contactGroup.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  value={formData.group_contact}
                  onChange={(selectedOptions) => {
                    handleChange("group_contact", selectedOptions || []);
                  }}
                  placeholder="Seleccione uno o más grupos de contactos"
                  isMulti
                  isClearable
                />
              </div>
              <div className="mb-3">
                <label htmlFor="contact" className="form-label">
                  Contacto
                </label>
                <Select
                  id="contact"
                  options={contacts.map((item) => ({
                    label: item.get_full_name,
                    value: item.id,
                  }))}
                  value={formData.contact}
                  onChange={(selectedOptions) => {
                    handleChange("contact", selectedOptions || []);
                  }}
                  placeholder="Seleccione uno o más contactos"
                  isMulti
                  isClearable
                />
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  color="secondary"
                  className="me-2 text-dark"
                  onClick={() => toggleModal(null)}
                >
                  Cerrar
                </Button>
                <Button type="submit" color="primary">
                  Guardar
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { project, loading } = state.Project;
  return { project, loading };
};

export default withRouter(
  connect(mapStateToProps, { projectList })(ProjectList)
);
