import React, {  useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect} from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

import { contactsCreate } from "../../store/contacts/actions";
//import { getAgent } from "../../helpers/backend";


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader";
import { getContactsFullList } from "../../helpers/backend";


const CompanyCreate = (props) => {
  const history = useHistory();


  const optionGroupTipoIdentificacion = [
    {
      options: [
        { label: "NIT", value: "NIT" },
        { label: "Otro", value: "OTRO" },
      ],
    },
  ];
  const  [personaContacto, setPersonaContacto] = useState([])
  const  [contacts, setContacts] = useState ({});
  const getContactsData = async () => {
    const response = await getContactsFullList();
    const data = response.data;
    setContacts(data)
  };
    const [formData, setFormData] = useState({
      razon_social:"",
      actividad: "",
      web_site:"",
      persona_contacto:[],
      documentNumber:"",
      contactType: "EMPRESA",
      documentType:"",
      email: "",
      phone: "",
      address: "",
 });

 const {  razon_social , actividad, web_site,documentNumber, documentType, contactType, email, phone, address } =
   formData;
   const persona_contacto = personaContacto && personaContacto.map( item => item.value );
 const onChange = (e) => {
   setFormData({ ...formData, [e.target.name]: e.target.value });
 };

 const onSubmit = (e) => {
   e.preventDefault();
   props.contactsCreate({
    razon_social,
    actividad,
    web_site,
    contactType,
    documentType,
    documentNumber,
    persona_contacto,
    email,
    phone,
    address
   });
  };

useEffect(() => {
  getContactsData();
}, [])

  const redirect = () => {
    history.goBack();
  };

   return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact| Contactos</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <div style={{ width:"100%", paddingBottom:"1.5rem"}}>
            <span style={{fontSize:"1.2rem", fontWeight:"600"}}>Agregar contacto</span>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
            <Card className= "col-lg-8 mx-auto  mt-3">
              {props.contactsCreated.id ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha agregado el Contacto con éxito
                </SweetAlert>
              ) : null}
              <CardBody>
                <h4 className="card-title">Crear Nuevo Contacto de Empresa </h4>
                <Form className="form-control needs-validation"  onSubmit={onSubmit}>

                <> {/* Datos Persona Natural*/}
        
                  <Row>
                    <Col lg={6} className="mb-3">                    
                      <Label
                        for="first_name"
                        className="form-label"
                      >
                        Razón Social
                      </Label>
                      <Input
                        id="razon_social"
                        name="razon_social"
                        type="text"
                        onChange={onChange}
                        value={razon_social}
                        valid={props.error.razon_social
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.razon_social
                              ? true
                              : false
                            : false
                        }
                        required
                      />
                      <FormFeedback>{props.error.razon_social}</FormFeedback>
                
                    </Col>                  
                    <Col lg={6}  className="mb-3">                    
                      <Label
                        for="actividad"
                        className="form-label"
                      >
                        Actividad
                      </Label>
                      <Input
                        id="actividad"
                        name="actividad"
                        type="text"
                        onChange={onChange}
                        value={actividad}
                        valid={props.error.actividad
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.actividad
                              ? true
                              : false
                            : false
                        }
                        required
                      />
                      <FormFeedback>{props.error.actividad}</FormFeedback>
                     
                    </Col>
                  </Row>
                 
                  <Row>
                 
                    <Col lg={6} className="mb-3">
                      <Label
                        for="contactType"
                        className="form-label"
                      >
                      Tipo de identificación
                      </Label>
                    <Select
                            id="documentType"
                            name="documentType"
                            onChange={(item)=> setFormData({ ...formData, documentType: item.value })}
                            options={optionGroupTipoIdentificacion}
                            placeholder ={'Seleccione'}
                          />
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="documento"
                        className="form-label"
                      >
                        Documento
                      </Label>
                      <Input
                        id="documentNumber"
                        name="documentNumber"
                        type="number"
                        onChange={onChange}
                        value={documentNumber}
                        valid={props.error.documentNumber
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.documentNumber
                              ? true
                              : false
                            : false
                        }
                        required
                      />
                      <FormFeedback>{props.error.documentNumber}</FormFeedback>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="email"
                        className="form-label"
                      >
                      Correo Electrónico
                      </Label>
                      <Input
                        id="email"
                        name="email"
                        type="text"
                        placeholder="example@xxxx.com"
                        onChange={onChange}
                        value={email}
                        valid={props.error.email
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.email
                              ? true
                              : false
                            : false
                        }
                        required
                      />
                      <FormFeedback>{props.error.email}</FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="phone"
                        className="form-label"
                      >
                        Teléfono
                      </Label>
                      <PhoneInput
                        id="phone"
                        name="phone"                                             
                        country={'co'}
                        value={phone}                        
                        onChange={(item) => setFormData({...formData, phone: item}) }
                        required
                      />
                      <FormFeedback>{props.error.phone}</FormFeedback>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="web_site"
                        className="form-label"
                      >
                        Sitio Web
                      </Label>
                      <Input
                        id="web_site"
                        name="web_site"
                        type="text"
                        placeholder=""
                        onChange={onChange}
                        value={web_site}
                        valid={props.error.web_site
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.web_site
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.birthdate}</FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="address"
                        className="form-label"
                      >
                        Dirección
                      </Label>
                      <Input
                        id="address"
                        name="address"
                        type="text"
                        onChange={onChange}
                        value={address}
                        valid={props.error.address
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.address
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.address}</FormFeedback>
                    </Col>
                  </Row>
                  <Row>
                  <Col lg={6} className="mb-3">
                      <Label
                        for="persona_contacto"
                        className="form-label"
                      >
                        Persona Contacto
                      </Label>
                      <Select
                            id="persona_contacto"
                            name="persona_contacto"
                            placeholder ="Seleccione"
                            isMulti={true}
                            onChange={(item)=> setPersonaContacto(item)}
                            options={Array.isArray(contacts) ? 
                              contacts.map(item=> ({label: item.documentNumber+" - " + (item.first_name ?  
                                item.get_full_name : item.razon_social), value: item.id})): []} 
                            menuPosition="fixed"    
                          />
                    </Col>
                  </Row>
                  <Row className="col-lg-12 justify-content-center mt-3">
                    <Col md={4} className= 'ms-5'>
                      <Link className="btn btn-secondary text-dark" to="/contacts">
                        Volver
                      </Link>
                      <Button color="primary" type="submit" className="ms-1">
                        Enviar
                      </Button>
                    </Col>
                  </Row>
                </>
        
                </Form>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, contactsCreated } = state.Contacts;
  return { error, loading, contactsCreated};
};

contactsCreate.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  contactsCreated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { contactsCreate })(CompanyCreate));
