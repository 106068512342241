import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';
import { getGestionesContactoRead, getReportsForHourList } from "../../../helpers/backend";
import { Card, Modal, ModalBody, Table } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ModalManagementDetails from "../../Whatsapp/components/ModalManagementDetails";
import { useSelector } from "react-redux";

const ReportForHour = () => {
  const [selectedRange, setSelectedRange] = useState('today');
  const [data, setData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGestiones, setSelectedGestiones] = useState([]);
  const [detallesModal, setDetallesModal] = useState(false);
  const [GestionDetallada, setGestionDetallada] = useState(false);

  const theme = useSelector((state) => state.theme.theme) || 'light'


  const getReportData = async (range) => {
    const today = new Date();
    let fechaInicio;
    let fechaFin;

    if (range === 'lastWeek') {
      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7);
      fechaInicio = lastWeek.toISOString().split('T')[0];
      fechaFin = today.toISOString().split('T')[0];
    } else {
      fechaInicio = today.toISOString().split('T')[0];
      fechaFin = today.toISOString().split('T')[0];
    }

    const response = await getReportsForHourList(`?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=&agents=&campaign=`);
    const data = response.data; // Supongamos que la respuesta es el objeto de datos esperado  
    setData(data);
  };

  useEffect(() => {
    getReportData(selectedRange);
    // eslint-disable-next-line
  }, [selectedRange]);

  const handleBarClick = (data) => {
    setSelectedGestiones(data.payload.Details); // Guarda las gestiones de la barra clickeada 
    setIsModalOpen(true) 
  };
  const handleGestionDetallada = async (id) => {
    const response = await getGestionesContactoRead(id)
    setGestionDetallada(response.data)
    setDetallesModal(true)
  }
  const transformData = (data) => {
    return Object.entries(data)
      .filter(([key]) => key !== "total_gestiones")
      .map(([timeSlot, { contador, gestiones }]) => ({
        timeSlot,
        Gestiones: contador,
        Details: gestiones, // Incluye gestiones en los datos transformados  
      }));
    }
  const chartData = transformData(data);

  return (
    <Card
      className="py-3 px-4"
      style={{
        backgroundColor: theme === "light" && "#f6f8fc",
        borderColor: theme === "light" && "#f6f8fc",
        boxShadow:
          "0 4px 10px -1px rgb(0 0 0 / 0.1), 0 2px 7px -2px rgb(0 0 0 / 0.1)",
      }}
    >
      <div className="d-flex justify-content-between ">
        <p
          className={`fw-semibold font-size-18 ${
            theme === "dark" && "text-dark"
          }`}
        >
          Por hora
        </p>
        <select
          style={{
            maxWidth: "170px",
            maxHeight: "34px",
            backgroundColor: theme === "light" && "#f6f8fc",
          }}
          className="form-select font-size-13"
          value={selectedRange}
          onChange={(e) => setSelectedRange(e.target.value)}
        >
          <option value="lastWeek">Última Semana</option>
          <option value="today">Hoy</option>
        </select>
      </div>
      <ResponsiveContainer width="100%" height={280}>
        <BarChart
          className="p-1"
          data={chartData}
          margin={{ top: 10, right: 35, bottom: 0, left: -20 }}
        >
          <XAxis dataKey="timeSlot" />
          <YAxis
            domain={[0, "dataMax + 1"]}
            tickCount={Math.max(...chartData.map((item) => item.Gestiones)) + 1}
          />
          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="Gestiones" fill="#8884d8" onClick={handleBarClick} />
        </BarChart>
      </ResponsiveContainer>
      <div className="p-1 d-flex justify-content-end">
        <Link to="report_for_hour" className="btn btn-success btn-sm">
          Consultar reportes
        </Link>
      </div>
      {detallesModal ? (
        <ModalManagementDetails
          isOpen={detallesModal}
          onCloseClick={() => setDetallesModal(false)}
          GestionDetallada={GestionDetallada}
        ></ModalManagementDetails>
      ) : null}
      {/* Modal para mostrar detalles de gestiones */}
      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        size="lg"
      >
        <h5 className="p-3">Detalles de las Gestiones</h5>
        <ModalBody>
          <Table className="table text-center mb-3 table-sm font-size-15" hover>
            <thead className="table-dark align-middle">
              <tr>
                <th>Contacto</th>
                <th>Estado</th>
                <th>Fecha</th>
                <th>Canal de Comunicación</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {selectedGestiones &&
                selectedGestiones.map((gestion) => (
                  <tr key={gestion.id}>
                    <td>{gestion.contacto}</td>
                    <td>{gestion.estado}</td>
                    <td>{gestion.created_date}</td>
                    <td>{gestion.type}</td>
                    <td>
                      <Link
                        to="#"
                        onClick={() => handleGestionDetallada(gestion.id)}
                      >
                        <span className="fas fa-eye fa-lg"></span>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </Card>
  );
};

export default ReportForHour;