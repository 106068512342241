import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Input,
  Button,
  Label,
} from "reactstrap";
import excelLogo from "../../assets/images/excel.png";
import ModalManagementDetails from "../Whatsapp/components/ModalManagementDetails";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, connect, useSelector } from "react-redux";
import {
  gestionesContactoFilter,
  gestionesContactoList,
} from "../../store/actions";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getAgent, getCampaignFullList } from "../../helpers/backend";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/l10n/es.js"; // Importa el idioma español
import axios from 'axios'; // Asegúrate de importar axios

function ManagementList(props) {
  const dispatch = useDispatch();
  const URL = process.env.REACT_APP_API_URL;
  const [detallesModal, setDetallesModal] = useState(false);
  const [GestionDetallada, setGestionDetallada] = useState(false);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [estado, setEstado] = useState("");
  const [agente, setAgente] = useState("");
  const [campana, setCampana] = useState("");
  const [agent, setAgent] = useState({});
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [isLead, setIsLead] = useState(false);
  const [type, setType] = useState("");

  const handleSelectChange = (selectedOptions) => {
    const selectedContactData = selectedOptions
      ? selectedOptions.map((option) => option.value).join(",")
      : "";
    setSelectedAgents(selectedOptions || []);
    setAgente(selectedContactData);
  };
  const handleSelectCampaignChange = (selectedOptions) => {
    setSelectedCampaign(selectedOptions || []);
    setCampana(selectedOptions.value);
  };
  const handleSelectEstadoChange = (selectedOptions) => {
    setSelectedEstado(selectedOptions || []);
    setEstado(selectedOptions.value); // Asumo que setAgente está definido en otro lugar y actualiza el estado correspondiente
  };

  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data);
  };
  const [campaignData, setCampaignData] = useState({});
  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    const data = response.data;
    setCampaignData(data);
  };
  const handleSelectTypeChange = (selectedOptions) => {
    setSelectedType(selectedOptions || []);
    setType(selectedOptions.value); // Asumo que setAgente está definido en otro lugar y actualiza el estado correspondiente
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      gestionesContactoFilter(
        `?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campana}&type=${type}&is_leads=${isLead}`
      )
    );
  };
  const exportExcel = async () => {
    try {
      const url = `${URL}/contact/gestionContacto/exportar-excel/?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campana}&type=${type}&is_leads=${isLead}`;
      const response = await axios.get(url, { responseType: 'blob' });
      if (response && response.data) {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const urlBlob = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', 'Gestiones.xlsx'); // Nombre del archivo
        document.body.appendChild(link);
        link.click();
  
        // Limpiar el URL del blob
        window.URL.revokeObjectURL(urlBlob);
        document.body.removeChild(link);
      } else {
        console.error('No se recibieron datos para exportar.');
      }
    } catch (error) {
      console.error('Error al exportar el archivo:', error);
    }
  };
  const resetSearch = () => {
    dispatch(gestionesContactoFilter(`?search=`));
    setFechaFin("");
    setFechaInicio("");
    setSelectedDate("");
    setSelectedAgents("");
    setSelectedCampaign("");
    setSelectedEstado("");
    setSelectedType("");
    setIsLead(false);
    setType("");
  };

  const handleDetallesModal = () => {
    setDetallesModal(false);
  };

  useEffect(() => {
    dispatch(gestionesContactoList());
    getAgentData();
    getCampaignData();
  }, [dispatch]);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [start, end] = selectedDates;
      setFechaInicio(start.toISOString().split("T")[0]); // Formato YYYY-MM-DD
      setFechaFin(end.toISOString().split("T")[0]); // Formato YYYY-MM-DD
      setSelectedDate(selectedDates);
    }
  };
  const params = {
    count: props.gestionesContacto.count,
    next: props.gestionesContacto.next,
    previous: props.gestionesContacto.previous,
    results: props.gestionesContacto.results,
  };
  const theme = useSelector((state) => state.theme.theme) || 'light'

  return (
    <div className="page-content">
      {detallesModal ? (
        <ModalManagementDetails
          isOpen={detallesModal}
          onCloseClick={handleDetallesModal}
          GestionDetallada={GestionDetallada}
        ></ModalManagementDetails>
      ) : null}

      <MetaTags>
        <title>TrueContact | Gestiones del Contacto </title>
      </MetaTags>
      <Container fluid>
        <div style={{ width: "100%", paddingBottom: "1.5rem" }}>
          <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>
            Gestión del contacto
          </span>
        </div>
        {props.loading ? (
          <Loader />
        ) : (
          <div>
            <Card>
              <CardBody>
                <Row className="d-flex justify-content-center">
                  <Col lg={12}>
                    <Row>
                      <Col>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Fecha"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            locale: "es",
                          }}
                          onChange={handleDateChange}
                          value={selectedDate}
                        />
                      </Col>
                      <Col>
                        <Select
                          onChange={handleSelectEstadoChange}
                          options={[
                            { value: "", label: "---------" },
                            { value: "EN_GESTION", label: "En Gestión" },
                            { value: "VENCIDO", label: "Vencido" },
                            { value: "FINALIZADO", label: "Finalizado" },
                            { value: "PENDIENTE", label: "Pendiente" },
                          ]}
                          placeholder="Estado"
                          value={selectedEstado}
                        />
                      </Col>
                      <Col>
                        <Select
                          className="me-1"
                          options={
                            Array.isArray(agent)
                              ? [
                                  { label: "Todos", value: "" },
                                  ...agent.map((item) => ({
                                    label: item.get_full_name,
                                    value: item.id,
                                  })),
                                ]
                              : []
                          }
                          value={selectedAgents}
                          onChange={handleSelectChange}
                          isMulti={true}
                          placeholder="Agentes"
                          menuPosition="fixed"
                        />
                      </Col>
                      <Col>
                        <Select
                          className="me-1"
                          options={
                            Array.isArray(campaignData)
                              ? [
                                  { label: "----------", value: "" },
                                  ...campaignData.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                  })),
                                ]
                              : []
                          }
                          onChange={handleSelectCampaignChange}
                          value={selectedCampaign}
                          menuPosition="fixed"
                          placeholder="Campañas"
                        />
                      </Col>
                      <Col>
                        <Select
                          className="me-1"
                          options={[
                            { value: "", label: "----------" },
                            { value: "WhatsApp", label: "WhatsApp" },
                            {
                              value: "Telefonia - Manual",
                              label: "Telefonía - Manual",
                            },
                          ]}
                          onChange={handleSelectTypeChange}
                          value={selectedType}
                          menuPosition="fixed"
                          placeholder="Tipo"
                        />
                      </Col>
                      <Col>
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="isLeadCheck"
                            checked={isLead}
                            onChange={(e) => setIsLead(e.target.checked)}
                          />
                          <Label className="form-check-label" for="isLeadCheck">
                            ¿Lead?
                          </Label>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <Button
                          color="warning"
                          className="btn-sm me-1 mt-1"
                          onClick={onSubmit}
                        >
                          <b>Consultar</b>
                        </Button>
                        <Button
                          color="danger"
                          className="btn-sm me-1 mt-1"
                          onClick={resetSearch}
                        >
                          <b>Borrar</b>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="col-lg-12 mx-auto  mt-3">
              {params.count === 0 ? (
                <CardBody>
                  <h4 className="card-title">No se encontraron resultados</h4>
                </CardBody>
              ) : (
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="font-size-20">Historial</h2>
                    <button
                      className="btn btn-secondary  text-dark"
                      onClick={exportExcel}
                    >
                      <img
                        className="me-1"
                        alt=""
                        src={excelLogo}
                        height="20px"
                      />{" "}
                      <b>Exportar</b>
                    </button>
                  </div>
                  <p>
                    <b>Total de Gestiones: {params.count} </b>
                  </p>
                  <div className="table-responsive">
                    <Table
                      responsive
                      hover
                      className={`font-size-13 text-center ${
                        theme === "dark" ? "table-dark" : "table-light"
                      }`}
                    >
                      <thead>
                        <tr>
                          <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                            #
                          </th>
                          <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                            Nombre
                          </th>
                          <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                            Fecha
                          </th>
                          <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                            Campaña
                          </th>
                          <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                            Teléfono
                          </th>
                          <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                            Canal de Comunicación
                          </th>
                          <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                            Agente
                          </th>
                          <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                            Estado
                          </th>
                          <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                            Acción
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        className={` ${theme === "dark" ? "" : "table-light"}`}
                      >
                        {Array.isArray(params.results)
                          ? params.results.map((gestion, idx) => (
                              <tr key={idx}>
                                <th
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                    height: "60px",
                                  }}
                                  scope="row"
                                >
                                  {idx + 1}
                                </th>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {gestion.contacto}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {gestion.created_date}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {gestion.campaign[0] &&
                                    gestion.campaign.map((item) => item[0])}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {gestion.conversation}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {gestion.type}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {gestion.agent
                                    ? gestion.agent.map((item) => item[0])
                                    : null}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {gestion.estado === "FINALIZADO" ? (
                                    <span className="badge bg-success">
                                      Finalizado
                                    </span>
                                  ) : gestion.estado === "PENDIENTE" ? (
                                    <span className="badge bg-warning">
                                      Pendiente
                                    </span>
                                  ) : gestion.estado === "VENCIDO" ? (
                                    <span className="badge bg-danger">
                                      Vencido
                                    </span>
                                  ) : gestion.estado === "EN_GESTION" ? (
                                    <span
                                      className="badge"
                                      style={{
                                        background: "rgb(111, 66, 193)",
                                      }}
                                    >
                                      En Gestión
                                    </span>
                                  ) : null}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {gestion.estado === "FINALIZADO" ||
                                  gestion.estado === "VENCIDO" ? (
                                    <Link to="#">
                                      <span
                                        className="fas fa-eye fa-lg"
                                        onClick={() => {
                                          setDetallesModal(true);
                                          setGestionDetallada(gestion);
                                        }}
                                      ></span>
                                    </Link>
                                  ) : null}
                                  {gestion.estado === "EN_GESTION" ? (
                                    <Link to="#">
                                      <span
                                        className="fas fa-pen fa-lg"
                                        onClick={() => {
                                          setDetallesModal(true);
                                          setGestionDetallada(gestion);
                                        }}
                                      ></span>
                                    </Link>
                                  ) : null}
                                </td>
                              </tr>
                            ))
                          : []}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    count={params.count}
                    next={params.next}
                    previous={params.previous}
                    limit={30}
                    baseUrl="contact/gestionContacto/"
                    filterFunction={gestionesContactoFilter}
                  />
                  <Row className="col-lg-12 justify-content-center">
                    <Col md={2}>
                      <Link
                        className="btn btn-secondary text-dark ms-5"
                        to={`/dashboard/`}
                      >
                        Volver
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </div>
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { error, loading, gestionesContacto } = state.GestionesContacto;
  return { error, loading, gestionesContacto };
};

export default withRouter(connect(mapStateToProps)(ManagementList));
