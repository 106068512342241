import React, { useState } from "react";
import { Modal, ModalBody, Button, Input, Label } from "reactstrap";

const AssignContactModal = ({
  isOpen,
  toggle,
  contacts,
  groupContacts,
  onAssignContact,
}) => {
  const [selectedContacts, setSelectedContacts] = useState([]);

  // Combinar contactos individuales y contactos dentro de grupos
  const combinedContacts = [
    ...(contacts || []).map((contact) => ({
      id: contact.id,
      name: contact.get_full_name,
    })),
    ...(groupContacts || []).flatMap((group) =>
      group.contacts.map((contact) => ({
        id: contact[1], // El ID del contacto está en la segunda posición del array
        name: contact[0], // El nombre del contacto está en la primera posición del array
      }))
    ),
  ];

  // Manejar cambios en la selección
  const handleSelectChange = (e) => {
    const options = e.target.options;
    const selectedIds = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedIds.push(options[i].value);
      }
    }
    setSelectedContacts(selectedIds);
  };

  // Asignar contactos seleccionados
  const handleAssignContact = () => {
    if (selectedContacts.length > 0) {
      onAssignContact(selectedContacts); // Enviar el array de IDs seleccionados
      setSelectedContacts([]); // Limpiar la selección
      toggle(); // Cerrar el modal
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <Label for="contact-select">Selecciona uno o más contactos</Label>
        <Input
          type="select"
          id="contact-select"
          multiple // Hacer el select múltiple
          value={selectedContacts}
          onChange={handleSelectChange}
          style={{ height: "150px" }} // Ajustar altura para mejor visualización
        >
          {combinedContacts.map((contact) => (
            <option key={contact.id} value={contact.id}>
              {contact.name}
            </option>
          ))}
        </Input>
        <Button color="primary" onClick={handleAssignContact} className="mt-3">
          Asignar
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default AssignContactModal;