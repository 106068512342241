import React from 'react'
import { TbArrowBarToLeft } from "react-icons/tb";
import './VerticalLayout/index.css'
import { useSelector } from 'react-redux';

const ButtonVolver = () => {

  const theme = useSelector((state) => state.theme.theme) || "light";
  

  const handleBack = () => {
    if (typeof window !== 'undefined' && window.history) {
      window.history.back();
    } else {
      console.error("No se pudo acceder a window.history");
    }
  };
  
  return (
    <div style={{ position: "relative" }}>
      <button onClick={handleBack} className={`${theme === 'dark' ? 'button_regresar_dark' : "button_regresar"}`}>
        <TbArrowBarToLeft size={21} />
      </button>
    </div>
  );
}

export default ButtonVolver