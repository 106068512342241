/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./styles/ChatInterno.css";
import ChatInterno from "./components/ChatInterno";
import BandejaChats from "./components/BandejaChats";
import Chat from "./components/Chat";
import { getBandejaDeEntrada } from "../../helpers/backend";
import { TbMessage } from "react-icons/tb";
import { useSelector } from "react-redux";

const ButtonChat = () => {
  const [openChat, setOpenChat] = useState(false);
  const [activeView, setActiveView] = useState("bandeja");
  const [selectedChat, setSelectedChat] = useState(null);
  const [chats, setChats] = useState([]);
  const [contactConnect, setContactConnect] = useState([]);
  const chatContainerRef = useRef(null);
  const buttonRef = useRef(null);

  const handleToogleChat = () => {
    setOpenChat(!openChat);

    if (openChat) {
      setActiveView("bandeja");
    }
  };

  // const handleChatSelect = (chat) => {
  //   const existingChat = chats.find(c => c.id_contact === chat.id_contact);
  
  //   if (!existingChat) {
  //     // Agregar el nuevo chat a la lista de chats
  //     const newChat = {
  //       id_contact: chat.id_contact,
  //       name: chat.name,
  //       last_message: '',
  //       status: 'DESCONECTADO',
  //       hora: new Date().toLocaleTimeString()
  //     };
  
  //     setChats(prevChats => [...prevChats, newChat]);
  //     setSelectedChat(newChat);
  //   } else {
  //     setSelectedChat(existingChat);
  //   }
  
  //   setActiveView("chat");
  // };

  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    setActiveView("chat");
  };

  const getBandejaEntrada = async () => {
    const response = await getBandejaDeEntrada();
    setChats(response.data.conversations);
    setContactConnect(response.data.user_on);
  };

  useEffect(() => {
    getBandejaEntrada();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        chatContainerRef.current &&
        !chatContainerRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpenChat(false); // Cierra el chat
      }
    };

    if (openChat) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openChat]);

  const theme = useSelector((state) => state.theme.theme) || 'light';

  return (
    <main className="contain_chat">
      <div className="chat_interno" onClick={handleToogleChat} ref={buttonRef}>
        <div style={{color: theme === 'dark' ? '#fff' : '#545a6d'}} className="content_button_open_chat">
        <TbMessage size={27}/>
        </div>
      </div>

      {openChat && (
        <div ref={chatContainerRef} className={`chat_container ${activeView}`}>
          <ChatInterno>
            <div
              className={`bandeja_container ${
                activeView === "chat" ? "hidden_chat" : ""
              }`}
            >
              <BandejaChats
                onChatSelect={handleChatSelect}
                chats={chats}
                contactConnect={contactConnect}
                onBack={() => setActiveView("bandeja")}
              />
            </div>
            <div
              className={`chat_content_container ${
                activeView === "bandeja" ? "hidden_chat" : ""
              }`}
            >
              {selectedChat && (
                <Chat
                  chat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  onBack={() => setActiveView("bandeja")}
                />
              )}
            </div>
          </ChatInterno>
        </div>
      )}
    </main>
  );
};

export default ButtonChat;
