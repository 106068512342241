import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TaskColumn from "./TaskColumn";
import StepPanel from "./StepPanel";
import SweetAlert from "react-bootstrap-sweetalert";
import "./TaskFlow.css";
import { MetaTags } from "react-meta-tags";
import { CardBody, Col, Container, Row } from "reactstrap";
import {
  delFlow,
  delTask,
  getTask,
  patchTask,
  postFlow,
  postTask,
} from "../../helpers/backend";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import TaskForContact from "./TaskForContact";
import AssignContactModal from "./AssignContactModal";
import TaskList from "./TaskList";
import { TbUser, TbLayoutList, TbCards } from "react-icons/tb";
import ButtonVolver from "../../components/ButtonVolver";
import "./TaskFlow.css";

const TaskFlow = () => {
  const id = useParams().id;
  const scrollContainerRef = useRef(null);
  let isDragging = false;
  let startX, scrollLeft;
  const [dataProject, setDataProject] = useState({});
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskFlows, setTaskFlows] = useState([]);
  const [alert, setAlert] = useState(null);
  const [stepOpen, setStepOpen] = useState(false);
  const [viewMode, setViewMode] = useState("flow");
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [filter, setFilter] = useState("all"); // Nuevo estado para el filtro

  // Función para parsear el dueDate
  const parseDueDate = (dueDate) => {
    if (!dueDate) return null;

    // Convertir "26/03/2025 06:00 AM" a un objeto Date
    const [datePart, timePart] = dueDate.split(" ");
    const [day, month, year] = datePart.split("/");
    const [time, modifier] = timePart.split(" ");
    let [hours, minutes] = time.split(":");

    if (modifier === "PM" && hours !== "12") {
      hours = parseInt(hours, 10) + 12;
    }
    if (modifier === "AM" && hours === "12") {
      hours = "00";
    }

    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);
  };

  // Función para clasificar tareas por fecha
  const classifyTasksByDueDate = (tasks) => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const endOfWeek = new Date();
    endOfWeek.setDate(today.getDate() + (7 - today.getDay()));

    const formatDate = (date) => (date ? date.toISOString().split("T")[0] : null);

    const tasksDueToday = tasks.filter((task) => {
      const dueDate = parseDueDate(task.dueDate);
      return dueDate && formatDate(dueDate) === formatDate(today);
    });

    const tasksDueTomorrow = tasks.filter((task) => {
      const dueDate = parseDueDate(task.dueDate);
      return dueDate && formatDate(dueDate) === formatDate(tomorrow);
    });

    const tasksDueThisWeek = tasks.filter((task) => {
      const dueDate = parseDueDate(task.dueDate);
      return (
        dueDate &&
        dueDate >= today &&
        dueDate <= endOfWeek &&
        !tasksDueToday.includes(task) &&
        !tasksDueTomorrow.includes(task)
      );
    });

    return {
      today: tasksDueToday,
      tomorrow: tasksDueTomorrow,
      thisWeek: tasksDueThisWeek,
    };
  };

  // Obtener la lista de tareas
  const getTaskList = async () => {
    const response = await getTask(id);
    setDataProject(response.data);
    const sortedFlows = response.data.flow.sort((a, b) => a.order - b.order);
    sortedFlows.forEach((flow) => {
      if (flow.tasks) {
        flow.tasks.sort((a, b) => a.order - b.order);
      }
    });
    setTaskFlows(sortedFlows);
  };

  // Filtrar tareas según el estado
  const getFilteredTasks = () => {
    const allTasks = taskFlows.flatMap((flow) => flow.tasks);
    const { today, tomorrow, thisWeek } = classifyTasksByDueDate(allTasks);

    if (filter === "today") return today;
    if (filter === "tomorrow") return tomorrow;
    if (filter === "thisWeek") return thisWeek;
    return allTasks; // Mostrar todas las tareas si no hay filtro
  };

  useEffect(() => {
    getTaskList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const refreshTasks = () => {
    getTaskList();
  }
  const openAssignModal = (taskId) => {
    setSelectedTaskId(taskId);
    setAssignModalOpen(true);
  };

  const assignContactToTask = async (contactId) => {
    try {
      const response = await patchTask(selectedTaskId, {
        contact: contactId,
      });
      if (response.status === 200) {
        getTaskList();
      }
    } catch (error) {
      console.error("Error asignando contacto a la tarea:", error);
    }
  };

  const handleCompleteTask = async (taskId, isComplete) => {
    try {
      const response = await patchTask(taskId, {
        complete: isComplete,
      });
      if (response.status === 200) {
        getTaskList();
      }
    } catch (error) {
      console.error("Error al completar la tarea:", error);
    }
  };

  const handleMouseDown = (e) => {
    isDragging = true;
    scrollContainerRef.current.classList.add("dragging");
    startX = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft = scrollContainerRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1; // Ajusta la sensibilidad del desplazamiento
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    isDragging = false;
    scrollContainerRef.current.classList.remove("dragging");
  };

  const moveTask = (taskIndex, fromColumnIndex, toColumnIndex, hoverIndex) => {
    const newTaskFlows = [...taskFlows];
    if (
      newTaskFlows[fromColumnIndex]?.tasks &&
      newTaskFlows[toColumnIndex]?.tasks &&
      taskIndex >= 0 &&
      taskIndex < newTaskFlows[fromColumnIndex].tasks.length
    ) {
      // Elimina la tarea desde la columna de origen
      const [movedTask] = newTaskFlows[fromColumnIndex].tasks.splice(
        taskIndex,
        1
      );

      // Inserta la tarea en la nueva posición de la columna de destino
      newTaskFlows[toColumnIndex].tasks.splice(hoverIndex, 0, movedTask);

      // Actualiza el atributo `order` para ambas columnas
      newTaskFlows[fromColumnIndex].tasks.forEach((task, index) => {
        if (task) task.order = index + 1;
      });

      newTaskFlows[toColumnIndex].tasks.forEach((task, index) => {
        if (task) task.order = index + 1;
      });

      // Si se mueve a una nueva columna, actualiza el atributo `flow`
      if (fromColumnIndex !== toColumnIndex) {
        movedTask.flow = newTaskFlows[toColumnIndex].id;
      } else {
        delete movedTask.flow;
      }

      // Actualiza el estado de las tareas
      setTaskFlows(newTaskFlows);

      // Enviar actualizaciones a la API
      updateTaskOrder(newTaskFlows.flatMap((flow) => flow.tasks));
    } else {
      console.error("Índices inválidos en el movimiento de tareas");
    }
  };

  const updateTaskOrder = async (updatedTasks) => {
    try {
      for (let task of updatedTasks) {
        const updateData = { order: task.order };
        if (task.hasOwnProperty("flow") && typeof task.flow === "number") {
          updateData.flow = task.flow;
        }
        await patchTask(task.id, updateData);
      }
    } catch (error) {
      console.error("Error actualizando el orden de las tareas:", error);
    }
  };


  const addTask = async (flowId, task, dueDate) => {
    console.log(task)
    try {
      const response = await postTask({
        flow: flowId,
        title: task,
        dueDate: dueDate,
      });
      console.log(response);
      getTaskList();
    } catch (error) {
      console.error("Error al agregar la tarea:", error);
    }
  };

  const hideAlert = () => setAlert(null);

  const deleteTask = async (taskId) => {
    try {
      const response = await delTask(taskId); // Elimina la tarea en el backend
      console.log(response);

      const newTaskFlows = taskFlows.map((flow) => ({
        ...flow,
        tasks: flow.tasks.filter((task) => task.id !== taskId),
      }));
      setTaskFlows(newTaskFlows); // Actualiza el estado
      setAlert(
        <SweetAlert
          success
          title="¡Eliminada!"
          onConfirm={hideAlert}
          timeout={2000}
          showConfirm={false}
        >
          La tarea ha sido eliminada con éxito.
        </SweetAlert>
      );
    } catch (error) {
      console.error("Error eliminando la tarea:", error);
      setAlert(
        <SweetAlert
          danger
          title="Error"
          onConfirm={hideAlert}
          timeout={2000}
          showConfirm={false}
        >
          No se pudo eliminar la tarea. Intenta nuevamente.
        </SweetAlert>
      );
    }
  };

  const confirmDeleteTask = (taskId) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Eliminar"
        confirmBtnBsStyle="danger"
        cancelBtnText="Cancelar"
        cancelBtnBsStyle="dark"
        title="¿Estás seguro?"
        onConfirm={() => deleteTask(taskId)}
        onCancel={hideAlert}
        focusCancelBtn
      >
        Esta acción no se puede deshacer.
      </SweetAlert>
    );
  };

  const addColumn = async (columnTitle) => {
    try {
      const response = await postFlow({
        project: id,
        title: columnTitle,
      });

      const newFlow = { ...response.data, tasks: [] }; // Aseguramos que el flujo tenga una lista de tareas vacía
      setTaskFlows([...taskFlows, newFlow]); // Actualizamos el estado

      console.log("Flujo agregado con éxito:", newFlow);
    } catch (error) {
      console.error("Error al agregar el flujo:", error);
    }
  };

  const deleteFlow = async (columnIndex, flowId) => {
    try {
      await delFlow(flowId); // Realiza la petición para eliminar el flow en el backend
      const newTaskFlows = [...taskFlows];
      newTaskFlows.splice(columnIndex, 1); // Elimina la columna del estado
      setTaskFlows(newTaskFlows);
      setAlert(
        <SweetAlert
          success
          title="¡Eliminado!"
          onConfirm={hideAlert}
          timeout={2000}
          showConfirm={false}
        >
          El flujo ha sido eliminado con éxito.
        </SweetAlert>
      );
    } catch (error) {
      console.error("Error eliminando el flujo:", error);
      setAlert(
        <SweetAlert
          danger
          title="Error"
          onConfirm={hideAlert}
          timeout={2000}
          showConfirm={false}
        >
          No se pudo eliminar el flujo. Intenta nuevamente.
        </SweetAlert>
      );
    }
  };

  const confirmDeleteFlow = (columnIndex, flowId) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Eliminar"
        confirmBtnBsStyle="danger"
        cancelBtnText="Cancelar"
        cancelBtnBsStyle="dark"
        title="¿Estás seguro?"
        onConfirm={() => deleteFlow(columnIndex, flowId)}
        onCancel={hideAlert}
        focusCancelBtn
      >
        Esta acción no se puede deshacer.
      </SweetAlert>
    );
  };


  const openStepPanel = (task) => {
    setSelectedTask(task);
    setStepOpen(true);
  };

  const closeStepPanel = () => {
    setSelectedTask(null);
    setStepOpen(false);
  };

  return (
    <React.Fragment>
      {alert}
      <MetaTags>
        <title>TrueContact | Tareas</title>
      </MetaTags>
      <Container fluid>
        <div className="page-content">
          <CardBody>
            <Row className="" style={{ height: "32px" }}>
              <Col className="d-flex gap-4 align-items-center">
                <ButtonVolver />
                <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                  {taskFlows && taskFlows[0] ? taskFlows[0].project : null}
                </span>
              </Col>
              <Col className="d-flex gap-2 justify-content-end">
                {/* Selector de filtro */}
                <select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="filter-select"
                >
                  <option value="all">Todas las tareas</option>
                  <option value="today">Vencen hoy</option>
                  <option value="tomorrow">Vencen mañana</option>
                  <option value="thisWeek">Vencen esta semana</option>
                </select>

                {/* Botones de vista */}
                <button
                  className={`button_view ${viewMode === "flow" ? "border-primary" : ""}`}
                  onClick={() => setViewMode("flow")}
                >
                  <TbCards size={20} strokeWidth={1.9} />
                </button>
                <button
                  className={`button_view ${viewMode === "contact" ? "border-primary" : ""}`}
                  onClick={() => setViewMode("contact")}
                >
                  <TbUser size={20} strokeWidth={1.9} />
                </button>
                <button
                  className={`button_view ${viewMode === "list" ? "border-primary" : ""}`}
                  onClick={() => setViewMode("list")}
                >
                  <TbLayoutList size={20} strokeWidth={1.9} />
                </button>
              </Col>
            </Row>
          </CardBody>

          {viewMode === "flow" ? (
            <DndProvider backend={HTML5Backend}>
              <div
                className={`page-content-task ${selectedTask ? "panel-open" : ""}`}
                ref={scrollContainerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
              >
                {Array.isArray(taskFlows)
                  ? taskFlows.map((flow, index) => (
                      <TaskColumn
                        key={flow.id}
                        title={flow.title}
                        tasks={flow.tasks.filter((task) =>
                          getFilteredTasks().includes(task)
                        )}
                        flowId={flow.id}
                        moveTask={moveTask}
                        addTask={addTask}
                        deleteTask={confirmDeleteTask}
                        columnIndex={index}
                        deleteColumn={() => confirmDeleteFlow(index, flow.id)}
                        openStepPanel={openStepPanel}
                        assignContactToTask={openAssignModal}
                        contacts={dataProject.contacts}
                        groupContacts={dataProject.group_contact}
                        onCompleteTask={handleCompleteTask}
                      />
                    ))
                  : []}
                <div
                  className="task-column new-flow-column"
                  onClick={() => addColumn("Nuevo Flujo")}
                >
                  <h5>
                    <i className="fas fa-plus-circle"></i> Agregar Flujo
                  </h5>
                </div>
              </div>
            </DndProvider>
          ) : viewMode === "contact" ? (
            <TaskForContact
              tasks={getFilteredTasks()} // Pasar las tareas filtradas
              openStepPanel={openStepPanel}
              assignContactToTask={openAssignModal}
              contacts={dataProject.contacts}
              groupContacts={dataProject.group_contact}
              onCompleteTask={handleCompleteTask}
            />
          ) : (
            <TaskList tasks={getFilteredTasks()} openStepPanel={openStepPanel} refreshTasks={refreshTasks} />
          )}
        </div>
      </Container>
      {stepOpen && (
        <div className="step-panel open">
          <StepPanel
            taskNumber={selectedTask}
            isOpen={stepOpen}
            toggle={closeStepPanel}
          />
        </div>
      )}
      <AssignContactModal
        isOpen={assignModalOpen}
        toggle={() => setAssignModalOpen(false)}
        onAssignContact={assignContactToTask}
        contacts={dataProject.contacts}
        groupContacts={dataProject.group_contact}
      />
    </React.Fragment>
  );
};

export default TaskFlow;