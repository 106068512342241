import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Table,
  Input,
  Button,
  InputGroup,
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import excelLogo from "../../assets/images/excel.png";
import { IoEllipsisVertical } from "react-icons/io5";
import styles from "./Contacts.module.scss";
import empresa from "../../assets/images/empresa.jpg";
import contacto from "../../assets/images/contacto.jpg";
import avatarHombre from "../../assets/images/avatarHombre.jpg";
import avatarMujer from "../../assets/images/avatarMujer.jpg";
import companyAvatar from "../../assets/images/companyAvatar.jpg";
import imgDefault from "../../assets/images/avatardefault.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";
import { contactsList } from "../../store/contacts/actions";
import { contactsFilter } from "../../store/contacts/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getCampaignFullList,
  getContactsOfCampaign,
  postContactsOfCampaign,
  postContactsUpload,
} from "../../helpers/backend";
import GroupAssign from "./components/GroupAssign";
import "./components/Contacts.css";
import { TbLayoutList, TbLayoutGrid, TbEdit } from "react-icons/tb";
import axios from 'axios'; 
// Componente reutilizable para SweetAlert
const Alert = ({ title, message, type, onConfirm }) => {
  return (
    <SweetAlert
      title={title}
      onConfirm={onConfirm}
      type={type}
      timeout={2000}
      showConfirm={false}
    >
      {message}
    </SweetAlert>
  );
};

const ContactsList = (props) => {
  const URL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const groups = String(user.groups);

  const [search, setSearch] = useState("");
  const [active, setActive] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [campaignAssign, setCampaignAssign] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [dropdownOpenChat, setDropdownOpenChat] = useState(false);
  const [activeIndexChat, setActiveIndexChat] = useState(null);
  const [dropdownOpenTel, setDropdownOpenTel] = useState(false);
  const [activeIndexTel, setActiveIndexTel] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [campaign, setCampaign] = useState("");
  const [contactId, setContactId] = useState(null);
  const [groupAssign, setGroupAssign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // Estados para manejar alertas reutilizables
  const [alert, setAlert] = useState(null);

  const theme = useSelector((state) => state.theme.theme) || "light";

  const [campaignData, setCampaignData] = useState([]);
  const [contactsOfCampaign, setContactsOfCampaign] = useState([]);

  // Función para mostrar una alerta de éxito
  const showSuccessAlert = (message) => {
    setAlert({
      title: "Hecho!",
      message: message,
      type: "success",
    });
  };

  // Función para mostrar una alerta de error
  const showErrorAlert = (message) => {
    setAlert({
      title: "Lo sentimos!",
      message: message,
      type: "error",
    });
  };

  // Función para cerrar la alerta
  const closeAlert = () => {
    setAlert(null);
  };

  // Función para manejar errores de peticiones
  const handleError = (error) => {
    console.log(error)
    if (error && error.status === 400) {
      showErrorAlert(error.detail || "Error en la solicitud.");
    } else {
      showErrorAlert("Ocurrió un error inesperado.");
    }
  };

  // Obtener datos de campañas
  const getCampaignData = async () => {
    try {
      const response = await getCampaignFullList();
      const data = response.data;
      setCampaignData(data);
    } catch (error) {
      handleError(error);
    }
  };

  // Obtener contactos de una campaña
  const getContactsData = async () => {
    try {
      const response = await getContactsOfCampaign(campaign);
      const data = response.data;
      setContactsOfCampaign(data);
    } catch (error) {
      handleError(error);
    }
  };

  // Cambiar estado del dropdown
  const toggleDropdown = (idx) => {
    setDropdownOpen(!dropdownOpen);
    setActiveIndex(idx);
  };

  const toggleDropdownChat = (idx) => {
    setDropdownOpenChat(!dropdownOpenChat);
    setActiveIndexChat(idx);
  };

  const toggleDropdownTel = (idx) => {
    setDropdownOpenTel(!dropdownOpenTel);
    setActiveIndexTel(idx);
  };

  // Manejar cambios en el buscador
  const onChange = (e) => {
    setSearch(e.target.value);
  };

  // Enviar búsqueda
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(contactsFilter(`?search=${search}`));
  };

  // Reiniciar búsqueda
  const resetSearch = () => {
    dispatch(contactsFilter(`?search=`));
    setSearch("");
  };

  // Efecto para cargar datos de campañas y contactos
  useEffect(() => {
    if (campaign) {
      getContactsData();
    } else {
      dispatch(contactsList());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign, dispatch]);

  useEffect(() => {
    getCampaignData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Navegar al perfil del contacto
  const handleShow = (id) => {
    history.push(`/contact/${id}/contact_profile`);
  };

  // Navegar al chat
  const handleItemClick = (selectedChatId, contactId, type, template) => {
    history.push({
      pathname: "/chat",
      state: { selectedChatId, contactId, type, template },
    });
  };

  // Asignar campaña a un contacto
  const assignCampaign = async () => {
    try {
      const response = await postContactsOfCampaign(campaignId, {
        contact: [contactId],
      });
      if (response.status === 200) {
        showSuccessAlert("Se ha asignado al contacto en la Campaña.");
        setCampaignAssign(false);
      }
    } catch (error) {
      handleError(error);
      setCampaignAssign(false);
    }
  };

  // Importar archivo Excel
  const postExcel = async () => {
    if (!selectedFile) {
      showErrorAlert("Por favor, selecciona un archivo.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    setLoading(true);
    try {
      const response = await postContactsUpload(formData);
      if (response.status === 200) {
        setLoading(false);
        showSuccessAlert("Archivo importado correctamente.");
      }
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  // Exportar archivo Excel
  const handleExport = async () => {
    try {
      const url = `${URL}/contact/contacto/exportar-excel/`;
      const response = await axios.get(url, { responseType: 'blob' });
      if (response && response.data) {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const urlBlob = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', 'Contactos.xlsx'); // Nombre del archivo
        document.body.appendChild(link);
        link.click();
  
        // Limpiar el URL del blob
        window.URL.revokeObjectURL(urlBlob);
        document.body.removeChild(link);
      } else {
        console.error('No se recibieron datos para exportar.');
      }
    } catch (error) {
      console.error('Error al exportar el archivo:', error);
    }
  };

  // Parámetros para la paginación
  const params = !campaign
    ? {
        count: props.contacts.count,
        next: props.contacts.next,
        previous: props.contacts.previous,
        results: props.contacts.results,
      }
    : {
        results: contactsOfCampaign,
      };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Contactos</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <div style={{ width: "100%", paddingBottom: "1.5rem" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>
              Contactos
            </span>
          </div>
          {props.loading || loading ? (
            <Loader />
          ) : (
            <div>
              {groupAssign ? (
                <GroupAssign
                  isOpen={groupAssign}
                  onClose={() => setGroupAssign(false)}
                  contactId={contactId}
                />
              ) : null}

              <Modal
                isOpen={contactOpen}
                centered={true}
                toggle={() => setContactOpen(false)}
                size="lg"
              >
                <h2 className="mt-4 ms-4 text-dark">
                  <b>Agregar</b>{" "}
                </h2>
                <Row className="justify-content-center mt-2 mx-5 mb-5">
                  <Col>
                    <Button
                      onClick={() => history.push("/contact/add_company")}
                    >
                      <img style={{ height: "300px" }} src={empresa} alt="" />
                      <h5 className="text-dark mt-2">
                        <b>Empresas</b>{" "}
                      </h5>
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => history.push("/contact/add_contact")}
                    >
                      <img style={{ height: "300px" }} src={contacto} alt="" />
                      <h5 className="text-dark mt-2">
                        <b>Personas</b>{" "}
                      </h5>
                    </Button>
                  </Col>
                </Row>
                <Row className="mb-3 mx-3">
                  <Col className="text-end">
                    <Button
                      className="text-dark"
                      onClick={() => setContactOpen(false)}
                    >
                      Cerrar
                    </Button>
                  </Col>
                </Row>
              </Modal>

              <Modal
                isOpen={campaignAssign}
                toggle={() => setCampaignAssign(false)}
                centered={true}
              >
                <div className="modal-header">
                  <h3 className="mt-2">Asignar a Campaña</h3>
                  <button
                    type="button"
                    onClick={() => setCampaignAssign(false)}
                    className="btn-close btn-sm"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="p-3 mt-2">
                  <Select
                    id="agent"
                    name="agent"
                    options={
                      Array.isArray(campaignData)
                        ? campaignData.map((item) => ({
                            label: item.name,
                            value: item.id,
                          }))
                        : []
                    }
                    onChange={(e) => setCampaignId(e.value)}
                    placeholder={"Seleccione"}
                    isMulti={false}
                    menuPosition="fixed"
                  />
                </div>

                <Row className="mt-3 p-2">
                  <Col className="d-flex justify-content-end">
                    <Button
                      className="text-dark btn btn-secondary"
                      onClick={() => setCampaignAssign(false)}
                    >
                      Cerrar
                    </Button>
                    <Button
                      color="primary"
                      className="btn  ms-1"
                      onClick={() => assignCampaign()}
                    >
                      Guardar{" "}
                    </Button>
                  </Col>
                </Row>
              </Modal>

              <Card
                style={{
                  backgroundColor: theme === "dark" ? "#0e1824" : "",
                  border: "none",
                }}
              >
                <CardBody>
                  <Row>
                    <Col md={7}>
                      {groups.includes("SuperAdmin") ||
                      groups.includes("Administrador") ||
                      groups.includes("Supervisor") ||
                      groups.includes("Operador") ||
                      groups.includes("Crear y modificar Cliente") ? (
                        <>
                          <Link
                            className="btn btn-primary waves-effect waves-light me-2"
                            to="#"
                            onClick={() => setContactOpen(true)}
                          >
                            Agregar{" "}
                            <span className="bx bx-user-plus fa-lg align-middle"></span>
                          </Link>
                          <Link
                            className="btn btn-warning waves-effect waves-light ms-1"
                            to="/contactsGroups"
                          >
                            Grupos{" "}
                          </Link>
                        </>
                      ) : null}
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Buscar"
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {params.count !== 0 ? (
                <CardBody className="pb-0">
                  <Row className="mx-2">
                    <Col>
                      <h2 className="mb-3 font-size-20">Lista de Contactos</h2>
                    </Col>
                    <Col className="d-flex gap-2 justify-content-end mb-1">
                      <button
                        className={` ${
                          theme === "dark"
                            ? "button_view_dark"
                            : "button_view_contant"
                        } ${!active ? "border-primary" : ""}`}
                        onClick={handleExport}
                      >
                        <img
                          className="me-1"
                          alt=""
                          src={excelLogo}
                          height="20px"
                        />{" "}
                        <b>Exportar</b>
                      </button>
                      <input
                        type="file"
                        id="excelFile"
                        accept=".xls, .xlsx"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file) {
                            setSelectedFile(file);
                          }
                        }}
                      />
                      <button
                        className={` ${
                          theme === "dark"
                            ? "button_view_dark"
                            : "button_view_contant"
                        } ${!active ? "border-primary" : ""}`}
                        onClick={postExcel}
                      >
                        <img
                          className="me-1"
                          alt=""
                          src={excelLogo}
                          height="20px"
                        />{" "}
                        <b>Importar</b>
                      </button>
                      <button
                        className={` ${
                          theme === "dark"
                            ? "button_view_dark"
                            : "button_view_contant"
                        } ${!active ? "border-primary" : ""}`}
                        onClick={() => setActive(false)}
                      >
                        <TbLayoutGrid
                          className={` ${
                            active ? "text-dark" : "text-primary"
                          } `}
                          size={20}
                          strokeWidth={1.9}
                        />
                      </button>
                      <button
                        className={` ${
                          theme === "dark"
                            ? "button_view_dark"
                            : "button_view_contant"
                        } ${active ? "border-primary" : ""}`}
                        onClick={() => setActive(true)}
                      >
                        <TbLayoutList
                          className={` ${
                            active ? "text-primary" : "text-dark"
                          }`}
                          size={20}
                          strokeWidth={1.9}
                        />
                      </button>{" "}
                    </Col>
                  </Row>

                  {groups.includes("SuperAdmin") ||
                  groups.includes("Administrador") ||
                  groups.includes("Supervisor") ? (
                    <Row>
                      <Col
                        style={{ maxWidth: "150px" }}
                        className="font-size-13 mb-4 mx-3"
                      >
                        <Select
                          id="campaign"
                          name="campaign"
                          options={[
                            { label: "Todos", value: null },
                            ...(Array.isArray(campaignData)
                              ? campaignData.map((item) => ({
                                  label: item.name,
                                  value: item.id,
                                }))
                              : []),
                          ]}
                          onChange={(e) => setCampaign(e.value)}
                          placeholder={"Campañas"}
                          isMulti={false}
                          menuPosition="fixed"
                          styles={{
                            control: (base) => ({
                              ...base,
                              backgroundColor:
                                theme === "dark" ? "#121e2d" : "#fff",
                              color:
                                theme === "dark" ? "text-dark" : "text-light",
                              borderColor:
                                theme === "dark" ? "#162436" : "#d2d2d2",
                            }),
                            menu: (base) => ({
                              ...base,
                              backgroundColor:
                                theme === "dark" ? "#121e2d" : "#fff",
                            }),
                            option: (base, { isFocused, isSelected }) => ({
                              ...base,
                              backgroundColor: isSelected
                                ? theme === "dark"
                                  ? "#121e2d"
                                  : ""
                                : isFocused
                                ? theme === "dark"
                                  ? "#0e1824"
                                  : "#ddd"
                                : "transparent",
                              color:
                                theme === "dark" ? "text-dark" : "text-light",
                            }),
                            singleValue: (base) => ({
                              ...base,
                              color:
                                theme === "dark" ? "text-dark" : "text-light",
                            }),
                          }}
                        />
                      </Col>
                    </Row>
                  ) : null}

                  {active ? (
                    <div
                      style={{ padding: "0 2rem" }}
                      className="table-responsive"
                    >
                      <Table
                        className={`font-size-13 text-center ${
                          theme === "dark" ? "table-dark" : "table-light"
                        }`}
                        hover
                        responsive
                      >
                        <thead className="">
                          <tr>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              #
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Nombre
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Documento
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Email
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Teléfono
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Acción
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          className={`${theme === "dark" ? "" : "table-light"}`}
                        >
                          {params.results &&
                            params.results.map((contacts, idx) => (
                              <tr key={idx}>
                                <th
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                  scope="row"
                                >
                                  {idx + 1}
                                </th>
                                {contacts.get_full_name ? (
                                  <td
                                    style={{
                                      backgroundColor:
                                        theme === "dark" ? "#121e2d" : "#fff",
                                      height: "60px",
                                    }}
                                  >
                                    {contacts.get_full_name}
                                  </td>
                                ) : (
                                  <td
                                    style={{
                                      backgroundColor:
                                        theme === "dark" ? "#121e2d" : "#fff",
                                      height: "60px",
                                      color:
                                        theme === "dark"
                                          ? "#c3cbdc66"
                                          : "#00000066",
                                    }}
                                  >
                                    Sin nombre
                                  </td>
                                )}
                                {contacts.documentNumber ? (
                                  <td
                                    style={{
                                      backgroundColor:
                                        theme === "dark" ? "#121e2d" : "#fff",
                                    }}
                                  >
                                    {contacts.documentNumber}
                                  </td>
                                ) : (
                                  <td
                                    style={{
                                      backgroundColor:
                                        theme === "dark" ? "#121e2d" : "#fff",
                                      color:
                                        theme === "dark"
                                          ? "#c3cbdc66"
                                          : "#00000066",
                                    }}
                                  >
                                    Sin documento
                                  </td>
                                )}

                                {contacts.email.length > 0 ? (
                                  contacts.email.map((eml) => (
                                    <td
                                      style={{
                                        backgroundColor:
                                          theme === "dark" ? "#121e2d" : "#fff",
                                      }}
                                      key={eml.id}
                                    >
                                      {eml.email}
                                    </td>
                                  ))
                                ) : (
                                  <td
                                    style={{
                                      backgroundColor:
                                        theme === "dark" ? "#121e2d" : "#fff",
                                      color:
                                        theme === "dark"
                                          ? "#c3cbdc66"
                                          : "#00000066",
                                    }}
                                  >
                                    Sin email
                                  </td>
                                )}
                                {contacts.phone.length > 0 ? (
                                  contacts.phone.map((telefono) => (
                                    <td
                                      style={{
                                        backgroundColor:
                                          theme === "dark" ? "#121e2d" : "#fff",
                                      }}
                                      key={telefono.id}
                                    >
                                      +{telefono.telefono}
                                    </td>
                                  ))
                                ) : (
                                  <td
                                    style={{
                                      backgroundColor:
                                        theme === "dark" ? "#121e2d" : "#fff",
                                      color:
                                        theme === "dark"
                                          ? "#c3cbdc66"
                                          : "#00000066",
                                    }}
                                  >
                                    Sin Teléfono
                                  </td>
                                )}
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  <Link to="#">
                                    <TbEdit
                                      onClick={() => handleShow(contacts.id)}
                                      size={20}
                                    />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div className={`text-center ${styles["card-container"]}`}>
                      {params.results &&
                        params.results.map((contacts, idx) => (
                          <Card
                            item
                            lg={4}
                            key={idx}
                            className={styles.card}
                            style={{
                              borderTop:
                                contacts.status === "DESCONECTADO"
                                  ? "7px solid #f35454"
                                  : "7px solid #46f092",
                            }}
                          >
                            <div className="position-absolute top-0 end-0">
                              <Dropdown
                                isOpen={dropdownOpen && activeIndex === idx}
                                toggle={() => toggleDropdown(idx)}
                              >
                                <DropdownToggle
                                  tag="span"
                                  onClick={() => toggleDropdown(idx)}
                                  className={`${styles.menu}`}
                                >
                                  <IoEllipsisVertical size={15} />
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem>Asignar Encuesta</DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setCampaignAssign(true);
                                      setContactId(contacts.id);
                                    }}
                                  >
                                    Asignar a Campaña
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setGroupAssign(true);
                                      setContactId(contacts.id);
                                    }}
                                  >
                                    Asignar Grupos
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                            <div onClick={() => handleShow(contacts.id)}>
                              <div className="text-center">
                                {contacts.image ? (
                                  <img
                                    src={contacts.image}
                                    alt=""
                                    className={styles["card-avatar"]}
                                  />
                                ) : contacts.contactType &&
                                  contacts.gender === "Masculino" ? (
                                  <img
                                    src={avatarHombre}
                                    alt=""
                                    className={styles["card-avatar"]}
                                  />
                                ) : contacts.contactType &&
                                  contacts.gender === "Femenino" ? (
                                  <img
                                    src={avatarMujer}
                                    alt=""
                                    className={styles["card-avatar"]}
                                  />
                                ) : contacts.contactType === "EMPRESA" ? (
                                  <img
                                    src={companyAvatar}
                                    alt=""
                                    className={styles["card-avatar"]}
                                  />
                                ) : (
                                  <img
                                    src={imgDefault}
                                    alt=""
                                    className={styles["card-avatar"]}
                                  />
                                )}
                              </div>
                              <div className={styles["card-title"]}>
                                <b>{contacts.get_full_name}</b>
                              </div>
                              <br />
                            </div>
                            <div className={styles["fixed-button"]}>
                              <Dropdown
                                isOpen={
                                  dropdownOpenChat && activeIndexChat === idx
                                }
                                toggle={() => toggleDropdownChat(idx)}
                              >
                                <DropdownToggle
                                  tag="span"
                                  onClick={() => {
                                    toggleDropdownChat();
                                    setContactId(contacts.id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faWhatsapp}
                                    size="2x"
                                    style={{ color: "#25d366" }}
                                    className={styles["social-icon"]}
                                  />
                                </DropdownToggle>
                                <DropdownMenu right>
                                  {Array.isArray(contacts.phone) &&
                                  contacts.phone.length > 0 ? (
                                    contacts.phone.map((item, idx) => (
                                      <DropdownItem
                                        key={idx}
                                        onClick={() =>
                                          handleItemClick(
                                            item.telefono,
                                            contactId,
                                            "WhatsApp",
                                            true
                                          )
                                        }
                                      >
                                        {item.telefono}
                                      </DropdownItem>
                                    ))
                                  ) : (
                                    <DropdownItem>
                                      No tiene número registrado
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                              <FontAwesomeIcon
                                icon={faFacebook}
                                size="2x"
                                style={{ color: "#1877f2" }}
                                className={styles["social-icon"]}
                              />
                              <FontAwesomeIcon
                                icon={faInstagram}
                                size="2x"
                                style={{ color: "#e4405f " }}
                                className={styles["social-icon"]}
                              />
                              <Dropdown
                                isOpen={
                                  dropdownOpenTel && activeIndexTel === idx
                                }
                                toggle={() => toggleDropdownTel(idx)}
                              >
                                <DropdownToggle
                                  tag="span"
                                  onClick={() => {
                                    toggleDropdownTel();
                                    setContactId(contacts.id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTelegram}
                                    size="2x"
                                    style={{ color: "#0088cc" }}
                                    className={styles["social-icon"]}
                                  />
                                </DropdownToggle>
                                <DropdownMenu right>
                                  {Array.isArray(contacts.telegram) &&
                                  contacts.telegram.length > 0 ? (
                                    contacts.telegram.map((item, idx) => (
                                      <DropdownItem
                                        key={idx}
                                        onClick={() =>
                                          handleItemClick(
                                            item.telegram,
                                            contactId,
                                            "Telegram"
                                          )
                                        }
                                      >
                                        {item.telegram}
                                      </DropdownItem>
                                    ))
                                  ) : (
                                    <DropdownItem>
                                      No tiene número registrado
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                            <div className={styles["fixed-button-left"]}>
                              <Col lg={3}>
                                <Link
                                  className="btn btn-warning btn-sm"
                                  to={`contact/calendar/${contacts.id}`}
                                >
                                  <i className="fas fa-calendar-alt"></i>
                                </Link>
                                <Link
                                  className="btn btn-info btn-sm mt-2"
                                  to={`/gestionContacto/${contacts.id}`}
                                >
                                  <i className="fas fa-tasks "></i>
                                </Link>
                              </Col>
                            </div>
                          </Card>
                        ))}
                    </div>
                  )}
                </CardBody>
              ) : (
                <CardBody>
                  <h4 className="card-title">No se encontraron resultados</h4>
                </CardBody>
              )}

              <Pagination
                count={params.count}
                next={params.next}
                previous={params.previous}
                limit={30}
                baseUrl="contact/contacto/"
                filterFunction={contactsFilter}
              />
            </div>
          )}
        </Container>

        {/* Mostrar la alerta si existe */}
        {alert && (
          <Alert
            title={alert.title}
            message={alert.message}
            type={alert.type}
            onConfirm={closeAlert}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, contacts } = state.Contacts;
  return { error, loading, contacts };
};

export default withRouter(connect(mapStateToProps)(ContactsList));