import React, { useState, useCallback } from "react";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link, useHistory } from "react-router-dom";

import Breadcrumb from "../../components/Common/Breadcrumb";

import {
  postContactsCreate,
  postContactsSame,
  postContactsVincular
} from "../../helpers/backend";

// Constantes
const IDENTIFICATION_TYPES = [
  { label: "Cédula Ciudadanía / INE", value: "CEDULA" },
  { label: "Pasaporte", value: "PASAPORTE" },
  { label: "Cedula de Extranjería", value: "CEDULA_EXTRANJERIA" },
  { label: "Otro", value: "OTRO" }
];

const GENDER_OPTIONS = [
  { label: "Masculino", value: "Masculino" },
  { label: "Femenino", value: "Femenino" },
  { label: "Otro", value: "Otro" }
];

const ContactsCreate = () => {
  const history = useHistory();
  const [errorPhone, setErrorPhone] = useState(false);
  const [showReplaceAlert, setShowReplaceAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [existingContact, setExistingContact] = useState(null);
  const [showGenericError, setShowGenericError] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    documentNumber: "",
    birthdate: "2000-01-01",
    city: "",
    gender: "Otro",
    contactType: "PERSONA NATURAL",
    documentType: "",
    phone: "",
    address: "",
    contacts: "",
    parentesco: "",
    createUser: false,
    password: "",
    confirm_password: ""
  });

  const {
    username,
    first_name,
    last_name,
    documentNumber,
    birthdate,
    gender,
    documentType,
    phone,
    address,
    password,
    confirm_password
  } = formData;

  const onChange = useCallback(
    (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
  
      if (!formData.phone) {
        setErrorPhone(true);
        return;
      }
  
      // Filtrar datos antes de enviar
      const dataToSend = { ...formData };
      if (!dataToSend.createUser) {
        delete dataToSend.password;
        delete dataToSend.confirm_password;
      }
  
      const response = await postContactsCreate(dataToSend);
      console.log(response);
  
      if (response.status === 200) {
        setShowSuccessAlert(true); // Mostrar SweetAlert de éxito
      } else if (response?.status === 400) {
        setExistingContact(response.data); // Corregido: usar `response` en lugar de `error`
        setShowReplaceAlert(true);
      } else {
        console.error("Error inesperado:", response);
        setShowGenericError(true);
      }
    },
    [formData]
  );

  const onCheckboxChange = useCallback(
    (e) => {
      setFormData({ ...formData, createUser: e.target.checked });
    },
    [formData]
  );

  const redirect = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleReplaceContact = useCallback(async () => {
    
    const { documentNumber, ...restFormData } = formData; // Excluir documentNumber
    try {
      const response= await postContactsVincular({
        ...restFormData,
        contact_id: existingContact.contact_id
      });
      console.log(response)
      setShowReplaceAlert(false);
      setShowSuccessAlert(true); // Mostrar SweetAlert de éxito después de reemplazar
    } catch (error) {
      console.error("Error al reemplazar contacto:", error);
    }
  }, [formData, existingContact]);

  const handleCreateNewContact = useCallback(async () => {
    const { documentNumber, ...restFormData } = formData; // Excluir documentNumber
    const response = await postContactsSame(restFormData);
    console.log(response);

    if (response.status === 200) {
      setShowReplaceAlert(false);
      setShowSuccessAlert(true);
    } else if (response?.status === 400) {
      setExistingContact(response.detail); // Corregido: usar `response` en lugar de `error`
    } else {
      console.error("Error inesperado:", response);
      setShowGenericError(true);
    }
  }, [formData]);

  const renderInputField = useCallback(
    (id, label, type, value, error, required = true) => (
      <Col lg={6} className="mb-3">
        <Label for={id} className="form-label">
          {label}
        </Label>
        <Input
          id={id}
          name={id}
          type={type}
          onChange={onChange}
          value={value}
          valid={error ? true : false}
          invalid={error ? true : false}
          required={required}
        />
        <FormFeedback>{error}</FormFeedback>
      </Col>
    ),
    [onChange]
  );

  const renderSelectField = useCallback(
    (id, label, options, value, onChange) => (
      <Col lg={6} className="mb-3">
        <Label for={id} className="form-label">
          {label}
        </Label>
        <Select
          id={id}
          name={id}
          onChange={(item) => onChange(item.value)}
          options={options}
          placeholder={"Seleccione"}
          value={options.find((option) => option.value === value)}
        />
      </Col>
    ),
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Contactos</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            title={"TrueContact"}
            breadcrumbItem={"Agregar Contactos"}
          />
          <Card className="col-lg-8 mx-auto mt-3">
            {showSuccessAlert && (
              <SweetAlert
                success
                title="¡Hecho!"
                onConfirm={() => {
                  setShowSuccessAlert(false);
                  redirect();
                }}
              >
                Se ha agregado el contacto con éxito.
              </SweetAlert>
            )}
            {showReplaceAlert && (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Reemplazar"
                cancelBtnText="Crear uno nuevo"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="primary"
                title="El Contacto ya existe"
                onConfirm={handleReplaceContact}
                onCancel={handleCreateNewContact}
              >
                ¿Deseas reemplazar el contacto existente o crear uno nuevo?
              </SweetAlert>
            )}
            {showGenericError && (
              <SweetAlert
                danger
                title="Error"
                onConfirm={() => setShowGenericError(false)}
              >
                Ocurrió un error al crear el contacto. Verifica los datos
                ingresados.
              </SweetAlert>
            )}
            <CardBody>
              <h4 className="card-title">Crear Nuevo Contacto</h4>
              <Form
                className="form-control needs-validation"
                onSubmit={onSubmit}
              >
                <Row>
                  {renderInputField(
                    "first_name",
                    "Nombres",
                    "text",
                    first_name
                  )}
                  {renderInputField(
                    "last_name",
                    "Apellidos",
                    "text",
                    last_name
                  )}
                </Row>
                <Row>
                  {renderInputField(
                    "username",
                    "Correo Electrónico",
                    "text",
                    username
                  )}
                  {renderSelectField(
                    "documentType",
                    "Tipo de identificación",
                    IDENTIFICATION_TYPES,
                    documentType,
                    (value) => setFormData({ ...formData, documentType: value })
                  )}
                </Row>
                <Row>
                  {renderInputField(
                    "documentNumber",
                    "Documento",
                    "number",
                    documentNumber
                  )}
                  {renderSelectField(
                    "gender",
                    "Género",
                    GENDER_OPTIONS,
                    gender,
                    (value) => setFormData({ ...formData, gender: value })
                  )}
                </Row>
                <Row>
                  {renderInputField(
                    "birthdate",
                    "Fecha de Nacimiento",
                    "date",
                    birthdate,
                    false
                  )}
                  {renderInputField(
                    "address",
                    "Dirección",
                    "text",
                    address,
                    false
                  )}
                </Row>
                <Row>
                  <Col lg={6} className="mb-3">
                    <Label for="phone" className="form-label">
                      Teléfono
                    </Label>
                    <PhoneInput
                      id="phone"
                      name="phone"
                      inputProps={{ name: "phone", required: true }}
                      country={"co"}
                      value={phone}
                      isValid={(value) => {
                        if (!value) return "Este campo es requerido";
                        return true;
                      }}
                      onChange={(item) => {
                        setFormData({ ...formData, phone: item });
                        setErrorPhone(false);
                      }}
                    />
                    {errorPhone && (
                      <p className="font-size-12 text-danger">
                        Este campo es requerido
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="mb-3">
                    <Label for="createUser" className="form-label">
                      <Input
                        id="createUser"
                        name="createUser"
                        type="checkbox"
                        onChange={onCheckboxChange}
                        checked={formData.createUser}
                      />{" "}
                      Crear Usuario
                    </Label>
                  </Col>
                </Row>
                {formData.createUser && (
                  <>
                    {renderInputField(
                      "password",
                      "Contraseña",
                      "password",
                      password,
                      password !== confirm_password
                        ? "Las contraseñas no coinciden"
                        : ""
                    )}
                    {renderInputField(
                      "confirm_password",
                      "Confirmar Contraseña",
                      "password",
                      confirm_password,
                      password !== confirm_password
                        ? "Las contraseñas no coinciden"
                        : ""
                    )}
                  </>
                )}
                <Row className="col-lg-12 justify-content-center mt-3">
                  <Col md={4} className="ms-5">
                    <Link
                      className="btn btn-secondary text-dark"
                      to="/contacts"
                    >
                      Volver
                    </Link>
                    <Button color="primary" type="submit" className="ms-1">
                      Enviar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactsCreate;
