import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { TbChevronRight ,TbChevronLeft, TbPlus, TbSearch } from "react-icons/tb";

const SidebarCalendar = ({ selectedDate, setSelectedDate }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isVisibleMiCalendario, setIsVisibleMiCalendario] = useState(false)
  const [isVisibleOtroCalendario, setIsVisibleOtroCalendario] = useState(false)
  
  const handleVisibleMiCalendario = () => {
    setIsVisibleMiCalendario(!isVisibleMiCalendario)
  }

  const handleVisibleOtroCalendario = () => {
    setIsVisibleOtroCalendario(!isVisibleOtroCalendario)
  }

  const theme = useSelector((state) => state.theme.theme) || 'light';

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const generateCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);

    const firstDay = new Date(year, month, 1).getDay();
    const lastDay = new Date(year, month, daysInMonth).getDay();
    
    const prevMonthDays = firstDay === 0 ? 6 : firstDay - 1;
    const nextMonthDays = lastDay === 0 ? 0 : 7 - lastDay;

    const daysArray = [];

    // Días del mes anterior
    for (let i = prevMonthDays; i > 0; i--) {
      const date = new Date(year, month, -i + 1);
      daysArray.push({ day: date.getDate(), outside: true, date });
    }

    // Días del mes actual
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      daysArray.push({ day, outside: false, date });
    }  

    // Días del mes siguiente
    for (let i = 1; i <= nextMonthDays; i++) {
      const date = new Date(year, month + 1, i);
      daysArray.push({ day: date.getDate(), outside: true, date });
    }

    return daysArray;
  };

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const selectDay = (day, outside) => {
    if (!outside) { // Solo permite seleccionar días del mes actual
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), day));
      const selected = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      setSelectedDate(selected);
    }
  };

  return (
    <section className="content_calendar_sidebar">
      <div className="content_crear_button">
        <button className="button_crear_caledar">
          <TbPlus size={24} />
          Crear
        </button>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <span style={{ fontWeight: "600" }}>
            {currentDate
              .toLocaleDateString("es-ES", { month: "long" })
              .replace(/^\w/, (c) => c.toUpperCase())}{" "}
            {currentDate.getFullYear()}
          </span>
          <div>
            <button
              onClick={prevMonth}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                color: theme === "dark" ? "#c3cbc4" : "#000",
              }}
            >
              <TbChevronLeft size={19} strokeWidth={1.5} />
            </button>
            <button
              onClick={nextMonth}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                color: theme === "dark" ? "#c3cbc4" : "#000",
              }}
            >
              <TbChevronRight size={19} strokeWidth={1.5} />
            </button>
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)",
            textAlign: "center",
            fontWeight: "600",
            gap: "0",
            fontSize: ".7rem",
            color: theme === "dark" ? "#c3cbe4" : "#979797",
          }}
        >
          {["Lun.", "Mar.", "Mié.", "Jue.", "Vie.", "Sáb.", "Dom."].map(
            (day, index) => (
              <div
                key={day}
                style={{
                  padding: "3px 0",
                }}
              >
                {day}
              </div>
            )
          )}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)",
            gap: "10px 0",
            textAlign: "center",
            fontSize: ".75rem",
          }}
        >
          {generateCalendar().map((item, index) => {
            // Verificar si item.date está definido y obtener el día de la semana
            const isSunday = item.date ? item.date.getDay() === 0 : false;
            const isSelected =
              item.day === currentDate.getDate() && !item.outside;

            return (
              <div
                key={index}
                onClick={() => selectDay(item.day, item.outside)}
                style={{
                  padding: "5px",
                  borderRadius: "100%",
                  background: isSelected
                    ? theme === "dark"
                      ? "#c3cbe4"
                      : "#545a6d"
                    : "transparent",
                  color: isSelected
                    ? theme === "dark"
                      ? "#000"
                      : "#fff"
                    : item.outside
                    ? "#c1c1c1"
                    : isSunday
                    ? "#c1c1c1"
                    : item.day === currentDate.getDate()
                    ? "#fff"
                    : theme === "dark"
                    ? "#c3cbe4"
                    : "#000",
                  cursor: item.outside ? "default" : "pointer",
                  opacity: item.outside || isSunday ? 0.7 : 1,
                }}
              >
                {item.day}
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ padding: ".5rem 0" }}>
        <div>
          <div
            className="content_mi_calendario"
            onClick={handleVisibleMiCalendario}
          >
            <div>
              <span style={{ fontWeight: "600", fontSize:".9rem" }}>Mis calendarios</span>
            </div>
            <div
              className={` ${
                isVisibleMiCalendario ? "rotate" : "icon_chevron"
              }`}
            >
              <TbChevronRight size={18} />
            </div>
          </div>
          <div
            className={`${
              isVisibleMiCalendario
                ? "active_opcions_mi_calendario"
                : "content_opcions_mi_calendario"
            }`}
          >
            <div className="opcion_mi_calendario">
              <span>Tareas</span>
            </div>
            <div className="opcion_mi_calendario">
              <span>Eventos</span>
            </div>
          </div>
        </div>
        <div style={{padding:"1rem 0"}}>
          <div
            className="content_mi_calendario"
            onClick={handleVisibleOtroCalendario}
          >
            <div>
              <span style={{ fontWeight: "600", fontSize:".9rem" }}>Otros calendarios</span>
            </div>
            <div className='button_add_calendario'>
              <TbPlus size={20} />
            </div>
            <div
              className={` ${
                isVisibleOtroCalendario ? "rotate" : "icon_chevron"
              }`}
            >
              <TbChevronRight size={18} />
            </div>
          </div>
          <div
            className={`${
              isVisibleOtroCalendario
                ? "active_opcions_mi_calendario"
                : "content_opcions_mi_calendario"
            }`}
          >
            <div className="opcion_mi_calendario">
              <span>Gmail</span>
            </div>
            <div className="opcion_mi_calendario">
              <span>Outlook</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SidebarCalendar;