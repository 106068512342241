import React, { useState, useRef, useEffect } from 'react';
import '../App.css';

const ChatWindow = ({ messages, onSendMessage, isWaiting, isTyping, onStopTyping }) => {
  const [inputValue, setInputValue] = useState('');
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      onSendMessage(inputValue);
      setInputValue('');
    }
  };

  return (
    <div className="ds-chat-window">
      {/* Botón de "Stop" */}
      {isTyping && (
        <button className="ds-stop-button" onClick={onStopTyping}>
          Stop
        </button>
      )}

      {/* Mensajes del chat */}
      <div className="ds-chat-messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`ds-message ${msg.sender === 'user' ? 'ds-message--user' : 'ds-message--ai'}`}
          >
            <div className="ds-message__content">
              {msg.text}
              {msg.isTyping && <span className="ds-typing-cursor">|</span>}
            </div>
          </div>
        ))}
        {isWaiting && (
          <div className="ds-message ds-message--ai">
            <div className="ds-message__content">
              <div className="ds-loading-animation"></div>
              <div className="ds-loading-animation"></div>
              <div className="ds-loading-animation"></div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      {/* Input del chat */}
      <div className="ds-chat-input">
        <input
          type="text"
          className="ds-chat-input__field"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <button className="ds-chat-input__button" onClick={handleSendMessage}>
          Enviar
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;