
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory, useParams} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Form,
  Button,
  CardTitle,
  Input,
  FormFeedback,
  Alert,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"

import { getCampaignRead } from "../../helpers/backend";

import {  campaignUpdate, } from "../../store/campaign/actions";

const CampaignUpdate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const id = useParams().id


  const getCampaignData = async () => {
    const response = await getCampaignRead(id);
    const data = response.data;
    console.log(data);
    setFormData({
      name: data.name,
      account:data.account,
      description:data.description,
      encargado: data.encargado,

    })
  };
useEffect(() => {
  getCampaignData();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])
  const [formData, setFormData] = useState({
    name: "",
    description:"",
    encargado: [],
  });
  
  const {
    name,   
    description, 

  } = formData;
  
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  
  
  const onSubmit = (e) => {
    e.preventDefault();
    const selectedContactValues = Array.isArray(formData.encargado) ? formData.encargado.map((option) => option[1]):[];
    const formDataToSend = {
      ...formData,
      encargado: selectedContactValues,
    };
    dispatch(campaignUpdate( id, formDataToSend));

  };
  
  const redirect = () => {
    history.push("/campaign");
  };
console.log(formData);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Campañas  </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div style={{ width:"100%", paddingBottom:"1.5rem"}}>
            <span style={{fontSize:"1.2rem", fontWeight:"600"}}>Campañas</span>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
          <div className="d-flex justify-content-center">
              {props.campaignUpdated.id ? (
                <SweetAlert
                  title="¡Hecho!"
                  success
                  showConfirm={true}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha
                  actualizado <b>{props.campaignUpdated.nombre}</b> correctamente.
                </SweetAlert>
              ) : null}

              <Card
                style={{
                  width: "60rem",
                }}
              >
                <CardTitle className="ps-3 pt-3">
                  <h2 className="mb-3 font-size-20">Actualizar Campañas</h2>
                </CardTitle>
                <CardBody>
                <Form onSubmit={onSubmit}>
                  <Row className="d-flex justify-content-md-center" >
                    <Row className="col-sm-8">
                      <Col>
                        <Label
                          for="name"                      
                        >
                          Nombre
                        </Label>
                        <Input                      
                          id="name"
                          name="name"
                          type="name"
                          onChange={onChange}
                          value= {name}
                          autoFocus
                          required
          
                        />
                        {props.error && props.error.name ? (
                          <Alert color = 'danger' className="mt-2">{props.error.name}</Alert>
                        ):null}
                      </Col> 
                      <Col>  
                        <Label
                          for="description"                      
                        >
                          Descripcion
                        </Label>
                        <Input
                        
                          id="description"
                          name="description"
                          type="text"
                          onChange={onChange}
                          value={description}

                          required 
                        />
                      
                        <FormFeedback>{props.error.description}</FormFeedback> 
                      </Col>                 
                  </Row>
                    <Row >
                      <Col className="d-flex justify-content-center mt-4">
                        <Link className="btn btn-secondary text-dark me-1" to="/campaign">
                          Cancelar
                        </Link>
                        <Button color="primary" type="submit">
                          Actualizar
                        </Button>
                      </Col>
                    </Row>

                  </Row>
                  </Form>
                </CardBody>
              </Card>

            
          </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { error, loading, campaignUpdated } = state.Campaign;
  return { error, loading, campaignUpdated };
};

export default withRouter(connect(mapStateToProps)(CampaignUpdate));

