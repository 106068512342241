import React, { useState } from "react";
import { Button, Col, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { format, addDays, startOfWeek, isSameDay, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import "./AvailabilityStyles.css";
import {
  postAvailability,
  postAvailabilityException,
} from "../../../helpers/backend";

const WeeklyCalendar = ({
  onAddAvailability,
  selectedDate,
  setSelectedDate,
  isRecurrent,
  selectedDay,
  setSelectedDay,
  initialAvailability,
  initialExceptions,
  normalizeDayOfWeek,
  campaignId,
  activeTab,
  getData,
  setAlert
}) => {
  const [maxCitas, setMaxCitas] = useState(1);
  const [selectedRange, setSelectedRange] = useState(null);
  const [startOfCurrentWeek, setStartOfCurrentWeek] = useState(
    startOfWeek(new Date(), { locale: es })
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [motivo, setMotivo] = useState("");
  const [horarioEspecial, setHorarioEspecial] = useState(false);
  const [viewMode, setViewMode] = useState("week");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const safeFormatDate = (dateStr, formatStr) => {
    if (!dateStr) return 'Fecha no especificada';
    try {
      const date = new Date(dateStr);
      if (isNaN(date.getTime())) return 'Fecha inválida';
      return format(date, formatStr, { locale: es });
    } catch (error) {
      console.error('Error al formatear fecha:', error);
      return 'Fecha inválida';
    }
  };

  const formatTo12HourFormat = (time24hr) => {
    if (!time24hr) return '';
    const [hours, minutes] = time24hr.split(':');
    const parsedHours = parseInt(hours, 10);
    const period = parsedHours >= 12 ? 'PM' : 'AM';
    const formattedHours = parsedHours % 12 || 12;
    return `${formattedHours}:${minutes} ${period}`;
  };

  const daysOfWeek = Array.from({ length: 7 }).map((_, i) =>
    addDays(startOfCurrentWeek, i)
  );

  const daysOfMonth = eachDayOfInterval({
    start: startOfMonth(startOfCurrentWeek),
    end: endOfMonth(startOfCurrentWeek)
  });

  const hours = Array.from({ length: 17 }).map((_, i) => i + 6);

  const formatHourTo24 = (hour) => {
    return `${hour.toString().padStart(2, "0")}:00`;
  };

  const formatHourTo12 = (hour) => {
    const period = hour >= 12 ? "PM" : "AM";
    const displayHour = hour % 12 || 12;
    return `${displayHour}:00 ${period}`;
  };

  const handleMouseDown = (day, hour) => {
    setIsDragging(true);
    setSelectedRange({ day, start: hour, end: hour + 1 });
  };

  const handleMouseOver = (day, hour) => {
    if (isDragging && selectedRange && isSameDay(day, selectedRange.day)) {
      setSelectedRange((prev) => ({
        ...prev,
        end: hour + 1,
      }));
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    if (selectedRange && selectedRange.start !== selectedRange.end) {
      setIsModalOpen(true);
    } else {
      setSelectedRange(null);
    }
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseEventModal = () => {
    setSelectedEvent(null);
  };

  const handleConfirmAvailability = async () => {
    if (selectedRange) {
      const { day, start, end } = selectedRange;
      
      // Corregir problema de zona horaria - usar fecha local sin conversión
      const date = format(day, "yyyy-MM-dd");
      const startTime24 = formatHourTo24(start);
      const endTime24 = formatHourTo24(end);
      
      const dayOfWeek = normalizeDayOfWeek(format(day, "EEEE", { locale: es }));

      const availabilityData = {
        date: date, // Ya está en formato correcto YYYY-MM-DD
        startTime: startTime24,
        endTime: endTime24,
        maxCitas: maxCitas,
        ...(isRecurrent ? { 
          dayOfWeek: dayOfWeek
        } : { 
          motivo: motivo || "Horario especial",
          horario_especial: horarioEspecial
        })
      };

      try {
        setIsSubmitting(true);
        
        if (activeTab === "1") {
          if (isRecurrent) {
            await postAvailability([{
              campaign: campaignId,
              dia_semana: dayOfWeek,
              hora_inicio: startTime24,
              hora_fin: endTime24,
              max_citas: maxCitas
            }]);
          } else {
            await postAvailabilityException([{
              campaign: campaignId,
              fecha: date, // Usar la fecha ya formateada
              motivo: motivo || "Horario especial",
              hora_inicio: startTime24,
              hora_fin: endTime24,
              max_citas: maxCitas,
              horario_especial: horarioEspecial
            }]);
          }
          
          setAlert({
            type: "success",
            message: "Horario guardado exitosamente"
          });
          
          getData();
        } else {
          onAddAvailability(availabilityData);
        }
        
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error al guardar:", error);
        setAlert({
          type: "error",
          message: "Hubo un error al guardar el horario"
        });
      } finally {
        setIsSubmitting(false);
        resetSelection();
      }
    }
  };

  const isCellSelectedOrLoaded = (day, hour) => {
    const currentDay = normalizeDayOfWeek(format(day, "EEEE", { locale: es }));
    const entries = isRecurrent ? initialAvailability : initialExceptions;
    
    return entries.some((entry) => {
      try {
        const entryStart = parseInt(
          (entry.hora_inicio || entry.startTime).split(":")[0],
          10
        );
        const entryEnd = parseInt(
          (entry.hora_fin || entry.endTime).split(":")[0],
          10
        );

        if (isRecurrent) {
          const entryDay = normalizeDayOfWeek(entry.dia_semana || entry.dayOfWeek);
          return (
            entryDay === currentDay &&
            hour >= entryStart &&
            hour < entryEnd
          );
        } else {
          // Para excepciones, comparar las fechas sin problemas de zona horaria
          const entryDateStr = entry.fecha || entry.date;
          const entryDate = entryDateStr ? parseISO(entryDateStr) : null;
          
          if (!entryDate) return false;
          
          // Comparar solo día, mes y año (ignorar hora)
          return (
            day.getDate() === entryDate.getDate() &&
            day.getMonth() === entryDate.getMonth() &&
            day.getFullYear() === entryDate.getFullYear() &&
            hour >= entryStart &&
            hour < entryEnd
          );
        }
      } catch (error) {
        console.error("Error al procesar entrada:", error);
        return false;
      }
    });
  };
  const resetSelection = () => {
    setSelectedRange(null);
    setMaxCitas(1);
    setMotivo("");
    setHorarioEspecial(false);
  };


  const handlePreviousPeriod = () => {
    if (viewMode === "week") {
      setStartOfCurrentWeek((prev) => addDays(prev, -7));
    } else {
      setStartOfCurrentWeek((prev) => addDays(prev, -28));
    }
  };

  const handleNextPeriod = () => {
    if (viewMode === "week") {
      setStartOfCurrentWeek((prev) => addDays(prev, 7));
    } else {
      setStartOfCurrentWeek((prev) => addDays(prev, 28));
    }
  };

  const handleDayClick = (day) => {
    const selectedDayName = format(day, "EEEE", { locale: es });
    setSelectedDate(format(day, "yyyy-MM-dd"));
    if (isRecurrent) {
      setSelectedDay(selectedDayName);
    }
  };

  const renderWeekView = () => (
    <>
      <Row>
        <Col>
          <div className="calendar-header d-flex justify-content-between mb-3">
            {daysOfWeek.map((day) => (
              <div
                key={day}
                className={`calendar-day-header text-center ${isSameDay(day, new Date(selectedDate)) ? "selected" : ""}`}
                style={{ width: "14%" }}
                onClick={() => handleDayClick(day)}
              >
                <div>{format(day, "EEE", { locale: es })}</div>
                <div>{format(day, "d", { locale: es })}</div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className="calendar-hours-container"
            style={{ height: "500px", overflowY: "auto" }}
            onMouseUp={handleMouseUp}
          >
            {hours.map((hour) => (
              <div key={hour} className="calendar-hour-row d-flex align-items-center mb-2">
                <div className="calendar-hour-label me-2" style={{ width: "70px" }}>
                  {formatHourTo12(hour)}
                </div>
                {daysOfWeek.map((day) => {
                  const event = isCellSelectedOrLoaded(day, hour);
                  const isInSelectedRange =
                    selectedRange &&
                    isSameDay(day, selectedRange.day) &&
                    hour >= selectedRange.start &&
                    hour < selectedRange.end;

                  return (
                    <div
                      key={day}
                      className={`calendar-cell ${event ? "booked" : ""} ${isInSelectedRange ? "selected" : ""}`}
                      style={{
                        width: "14%",
                        height: "40px",
                      }}
                      onMouseDown={() => handleMouseDown(day, hour)}
                      onMouseOver={() => handleMouseOver(day, hour)}
                      onClick={() => event && handleEventClick(event)}
                      title={event ? (
                        isRecurrent 
                          ? `Horario recurrente: ${formatHourTo12(hour)} a ${formatHourTo12(hour + 1)}`
                          : `Excepción: ${event.motivo || "Sin motivo"} - ${formatHourTo12(hour)} a ${formatHourTo12(hour + 1)}`
                      ) : `${safeFormatDate(day, "EEEE d 'de' MMMM")}, ${formatHourTo12(hour)} a ${formatHourTo12(hour + 1)}`}
                    >
                      {event && !isInSelectedRange && (
                        <div className="calendar-event-indicator"></div>
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );

  const renderMonthView = () => (
    <div className="month-view-container">
      <div className="month-grid">
        {daysOfMonth.map((day) => {
          const hasException = initialExceptions.some(exception => {
            try {
              if (!exception.fecha && !exception.date) return false;
              const exceptionDate = new Date(exception.fecha || exception.date);
              return !isNaN(exceptionDate.getTime()) && isSameDay(exceptionDate, day);
            } catch {
              return false;
            }
          });
          
          return (
            <div
              key={day}
              className={`month-day ${isSameMonth(day, startOfCurrentWeek) ? "" : "other-month"} ${hasException ? "has-exception" : ""}`}
              onClick={() => {
                setSelectedDate(format(day, "yyyy-MM-dd"));
                if (hasException) {
                  const exception = initialExceptions.find(ex => {
                    try {
                      if (!ex.fecha && !ex.date) return false;
                      const exDate = new Date(ex.fecha || ex.date);
                      return !isNaN(exDate.getTime()) && isSameDay(exDate, day);
                    } catch {
                      return false;
                    }
                  });
                  exception && handleEventClick(exception);
                }
              }}
            >
              <div className="day-number">{format(day, "d")}</div>
              {hasException && <div className="event-dot"></div>}
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <Container fluid className="calendar-container">
      <Row>
        <Col className="d-flex justify-content-between mb-3 align-items-center">
          <Button 
            className="calendar-nav-btn" 
            color="light"
            onClick={handlePreviousPeriod}
          >
            <i className="fas fa-chevron-left me-2"></i>
            {viewMode === "week" ? "Semana anterior" : "Mes anterior"}
          </Button>
          
          <div className="d-flex align-items-center">
            <div className="btn-group me-3">
              <Button 
                color={viewMode === "week" ? "primary" : "light"}
                onClick={() => setViewMode("week")}
                size="sm"
              >
                Semana
              </Button>
              {!isRecurrent && (
                <Button 
                  color={viewMode === "month" ? "primary" : "light"}
                  onClick={() => setViewMode("month")}
                  size="sm"
                >
                  Mes
                </Button>
              )}
            </div>
            
            <span className="fw-bold text-primary">
              {viewMode === "week" 
                ? `Semana del ${safeFormatDate(startOfCurrentWeek, "d 'de' MMMM")}`
                : safeFormatDate(startOfCurrentWeek, "MMMM yyyy")}
            </span>
          </div>
          
          <Button 
            className="calendar-nav-btn" 
            color="light"
            onClick={handleNextPeriod}
          >
            {viewMode === "week" ? "Semana siguiente" : "Mes siguiente"}
            <i className="fas fa-chevron-right ms-2"></i>
          </Button>
        </Col>
      </Row>

      {viewMode === "week" ? renderWeekView() : renderMonthView()}

      <Modal isOpen={isModalOpen} toggle={() => {
        setIsModalOpen(false);
        resetSelection();
      }} className="calendar-modal">
        <ModalHeader toggle={() => {
          setIsModalOpen(false);
          resetSelection();
        }}>
          <i className="fas fa-calendar-plus me-2"></i>
          {isRecurrent ? "Agregar Horario Semanal" : "Agregar Excepción"}
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label>Horario seleccionado:</label>
            <div className="form-control bg-light">
              {selectedRange && 
                `${formatHourTo12(selectedRange.start)} - ${formatHourTo12(selectedRange.end)}`}
              <div className="text-muted small mt-1">
                {selectedRange && safeFormatDate(selectedRange.day, "EEEE d 'de' MMMM")}
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label className="required-field">Máximo de citas:</label>
            <input
              type="number"
              className="form-control"
              value={maxCitas}
              onChange={(e) => setMaxCitas(parseInt(e.target.value, 10))}
              min="1"
            />
          </div>
          
          {!isRecurrent && (
            <>
              <div className="mb-3">
                <label>Motivo (opcional):</label>
                <input
                  type="text"
                  className="form-control"
                  value={motivo}
                  onChange={(e) => setMotivo(e.target.value)}
                  placeholder="Ej: Día festivo, mantenimiento, etc."
                />
              </div>
              <div className="mb-3 form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="horarioEspecial"
                  checked={horarioEspecial}
                  onChange={(e) => setHorarioEspecial(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="horarioEspecial">
                  Horario especial
                </label>
                <small className="text-muted d-block">
                  (Marcar si es un horario fuera del horario normal de atención)
                </small>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button 
            color="primary" 
            onClick={handleConfirmAvailability}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>Guardar
              </>
            )}
          </Button>
          <Button color="light" onClick={() => {
            setIsModalOpen(false);
            resetSelection();
          }}>
            <i className="fas fa-times me-2"></i>Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={!!selectedEvent} toggle={handleCloseEventModal} className="event-modal">
        <ModalHeader toggle={handleCloseEventModal}>
          <i className="fas fa-calendar-alt me-2"></i>
          Detalles del Horario
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label>Fecha/Día:</label>
            <div className="form-control bg-light">
              {isRecurrent 
                ? selectedEvent?.dia_semana || selectedEvent?.dayOfWeek || 'No especificado'
                : safeFormatDate(selectedEvent?.fecha || selectedEvent?.date, "EEEE d 'de' MMMM yyyy")}
            </div>
          </div>
          
          <div className="mb-3">
            <label>Horario:</label>
            <div className="form-control bg-light">
              {formatTo12HourFormat(selectedEvent?.hora_inicio || selectedEvent?.startTime)} - {formatTo12HourFormat(selectedEvent?.hora_fin || selectedEvent?.endTime)}
            </div>
          </div>
          
          <div className="mb-3">
            <label>Máximo de citas:</label>
            <div className="form-control bg-light">
              {selectedEvent?.max_citas || selectedEvent?.maxCitas || 'No especificado'}
            </div>
          </div>
          
          {!isRecurrent && (
            <>
              <div className="mb-3">
                <label>Motivo:</label>
                <div className="form-control bg-light">
                  {selectedEvent?.motivo || "No especificado"}
                </div>
              </div>
              
              <div className="mb-3">
                <label>Horario especial:</label>
                <div className="form-control bg-light">
                  {selectedEvent?.horario_especial ? "Sí" : "No"}
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseEventModal}>
            <i className="fas fa-times me-2"></i>Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default WeeklyCalendar;