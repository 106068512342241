import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./constants";
import "./TaskFlow.css";

const TaskCard = ({
  task,
  index,
  columnIndex,
  deleteTask,
  openStepPanel,
  moveTask,
  assignContactToTask,
  onCompleteTask,
}) => {
  // Función para obtener iniciales de un nombre
  const getInitials = (name) => {
    if (!name) return "??";
    const initials = name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
    return initials.slice(0, 2);
  };

  // Función para generar un color aleatorio basado en un nombre
  const getRandomColor = (name) => {
    if (!name) return "#ccc";
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 70%, 60%)`;
    return color;
  };

  // Función para parsear el formato "DD/MM/YYYY hh:mm AM/PM" a un objeto Date
  const parseCustomDate = (dateString) => {
    if (!dateString) return null;

    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [time, modifier] = timePart.split(" ");

    let [hours, minutes] = time.split(":").map(Number);

    // Convertir a formato de 24 horas si es PM
    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0; // Medianoche
    }

    return new Date(year, month - 1, day, hours, minutes);
  };

  // Función para determinar el estado de la tarea basado en la fecha límite
  const getTaskStatus = (dueDate) => {
    if (!dueDate) return "neutral"; // Sin fecha límite

    const today = new Date(); // Fecha actual
    const due = parseCustomDate(dueDate); // Parsear la fecha límite
    if (!due) return "neutral"; // Si la fecha no es válida, considerarla neutral

    const timeDiff = due - today; // Diferencia en milisegundos
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Diferencia en días (redondeada hacia arriba)

    if (daysDiff < 0) return "overdue"; // Vencida
    if (daysDiff <= 1) return "urgent"; // Muy cercana (rojo)
    if (daysDiff <= 3) return "warning"; // Próxima a vencerse (amarillo)
    return "on-time"; // En tiempo (verde)
  };

  const status = getTaskStatus(task.dueDate);

  // Drag and Drop
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TASK,
    item: { task, index, columnIndex },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.TASK,
    hover: (draggedItem) => {
      if (
        draggedItem.index !== index ||
        draggedItem.columnIndex !== columnIndex
      ) {
        moveTask(
          draggedItem.index,
          draggedItem.columnIndex,
          columnIndex,
          index
        );
        draggedItem.index = index;
        draggedItem.columnIndex = columnIndex;
      }
    },
  });

  // Asegurarnos de que task.contact sea un array
  const contacts = Array.isArray(task.contact) ? task.contact : [task.contact].filter(Boolean);

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={`task-card ${status}`} // Aplicamos la clase de estado
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={(e) => {
        if (!e.target.classList.contains("task-checkbox")) {
          openStepPanel(task.id);
        }
      }}
    >
      {/* Franja lateral para el semáforo */}
      <div className="status-indicator"></div>

      {/* Título con checkbox */}
      <div className="task-title">
        <input
          type="checkbox"
          className="task-checkbox"
          checked={task.complete}
          onChange={(e) => {
            e.stopPropagation();
            onCompleteTask(task.id, e.target.checked);
          }}
        />
        <p style={{ textDecoration: task.complete ? "line-through" : "none" }}>
          {task.title}
        </p>
      </div>

      <div className="details">
        <span>
          <i className="fas fa-calendar-alt"></i> {task.dueDate || "Sin fecha"}
        </span>
        <span>
          <i className="fas fa-tag"></i> {task.label || "Sin etiqueta"}
        </span>
      </div>

      <div className="user-section">
        {/* Avatares de contactos */}
        {contacts.slice(0, 5).map((contact, idx) => (
          <div
            key={idx}
            className="user-avatar"
            style={{
              backgroundColor: getRandomColor(contact?.get_full_name || "Usuario"),
              color: "#fff",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              cursor: "pointer",
              marginRight: "4px",
            }}
            title={contact?.get_full_name || "Usuario"} // Tooltip con nombre completo
          >
            {getInitials(contact?.get_full_name || "Usuario")}
          </div>
        ))}

        {/* Contador de contactos adicionales */}
        {contacts.length > 5 && (
          <div
            className="user-avatar"
            style={{
              backgroundColor: "#666",
              color: "#fff",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              cursor: "default",
            }}
            title={`${contacts.length - 5} contacto(s) más`}
          >
            +{contacts.length - 5}
          </div>
        )}

        {/* Botón para agregar usuario */}
        <button
          className="add-user-btn"
          onClick={(e) => {
            e.stopPropagation();
            assignContactToTask(task.id);
          }}
        >
          <i className="fas fa-user-plus"></i>
        </button>
      </div>

      {/* Botón para eliminar tarea */}
      <span
        className="delete-btn"
        onClick={(e) => {
          e.stopPropagation();
          deleteTask(task.id);
        }}
      >
        ×
      </span>
    </div>
  );
};

export default TaskCard;