import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  Button,
  Input,
  FormGroup,
  Label,
  ListGroup,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./TaskFlow.css";
import {
  patchStep,
  postComments,
  postStepComment,
  postStep,
  readTask,
} from "../../helpers/backend";

const StepPanel = ({ taskNumber, isOpen, toggle, refresh, refreshOn }) => {
  const getTask = async () => {
    const response = await readTask(taskNumber);
    setTask(response.data);

    // Inicializar completedSteps con los pasos que ya están completados
    const completedSteps = response.data.steps.filter((step) => step.complete);
    setCompletedSteps(completedSteps);
  };

  const [task, setTask] = useState([]);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [inputFocused, setInputFocused] = useState({});
  const [newComment, setNewComment] = useState("");
  const [stepComments, setStepComments] = useState({});
  const [attachments, setAttachments] = useState(task.file ? [task.file] : []);
  const [newAttachment, setNewAttachment] = useState(null);
  const [editDueDate, setEditDueDate] = useState(null);
  const [newStepTitle, setNewStepTitle] = useState("");
  const [priority, setPriority] = useState("Normal");
  const [tags, setTags] = useState(task.tags || []);
  const [status, setStatus] = useState("Pendiente");
  const [dropdownOpen, setDropdownOpen] = useState({
    priority: false,
    tags: false,
    status: false,
  });

  const allStepsCompleted = task.steps
    ? task.steps.every((step) => step.complete)
    : false;

  // Función para alternar el estado de un dropdown
  const toggleDropdown = (dropdown) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  // Manejar el clic en un step (marcar/desmarcar)
  const handleStepClick = async (step) => {
    const isCompleted = completedSteps.some(
      (completedStep) => completedStep.id === step.id
    );
    let updatedSteps;

    if (isCompleted) {
      updatedSteps = completedSteps.filter(
        (completedStep) => completedStep.id !== step.id
      );
    } else {
      updatedSteps = [...completedSteps, { ...step, complete: true }];
    }

    setCompletedSteps(updatedSteps);

    try {
      const response = await patchStep(step.id, {
        complete: !isCompleted,
      });
      getTask();
      if (response.status !== 200)
        throw new Error("Error al actualizar el step");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Manejar la actualización de la fecha de vencimiento de un step
  const handleDueDateChange = async (step, newDueDate) => {
    try {
      const response = await patchStep(step.id, {
        dueDate: newDueDate,
      });
      getTask();
      if (response.status !== 200)
        throw new Error("Error al actualizar la fecha");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Manejar la adición de un nuevo comentario al task
  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    try {
      const response = await postComments({
        task: task.id,
        text: newComment,
      });
      getTask();
      if (response.status !== 200)
        throw new Error("Error al agregar el comentario");

      setNewComment("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Manejar la adición de un nuevo comentario a un step
  const handleAddStepComment = async (stepId, commentText) => {
    if (!commentText.trim()) return;

    try {
      const response = await postStepComment({
        step: stepId,
        text: commentText,
      });
      getTask();
      if (response.status !== 200)
        throw new Error("Error al agregar el comentario");

      setStepComments((prev) => ({ ...prev, [stepId]: "" }));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Manejar la subida de un archivo
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewAttachment(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Manejar la confirmación de subida del archivo
  const handleConfirmUpload = async () => {
    if (!newAttachment) return;

    try {
      console.log("Archivo en base64:", newAttachment);
      setAttachments([...attachments, newAttachment]);
      setNewAttachment(null);
      getTask();
    } catch (error) {
      console.error("Error al subir el archivo:", error);
    }
  };

  // Manejar la creación de un nuevo paso
  const handleAddNewStep = async () => {
    if (!newStepTitle.trim()) return;

    try {
      const response = await postStep({
        task: task.id,
        title: newStepTitle,
        order: task.steps.length + 1,
      });
      getTask();
      if (response.status !== 200) throw new Error("Error al agregar el paso");

      setNewStepTitle("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Manejar el cambio de etiquetas
  const handleTagsChange = async (newTags) => {
    try {
      const response = await patchStep(task.id, {
        labels: newTags, // Usamos la llave "labels" para actualizar las etiquetas
      });
      refresh();
      if (response.status !== 200)
        throw new Error("Error al actualizar las etiquetas");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // En StepPanel
  useEffect(() => {
    getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="custom-modal-step"
      size="xl"
    >
      <ModalBody>
        {/* Task Info */}
        <div className="task-info">
          <h5
            className="task-title"
            style={{
              textDecoration: allStepsCompleted ? "line-through" : "none",
              fontSize: "1.25rem", // Tamaño de fuente consistente
            }}
          >
            {task.title}
          </h5>
          <Button
            color="link"
            className="icon-button"
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <i className="fas fa-user-plus"></i>
          </Button>
        </div>

        {/* Tags Section */}
        <div className="tags-container mb-3">
          {tags.length > 0 ? (
            tags.map((tag, index) => (
              <span key={index} className="tag-item">
                {tag}
              </span>
            ))
          ) : (
            <p className="text-muted">Sin etiquetas</p>
          )}
        </div>

        {/* Priority, Tags, Status Selects */}
        <div className="d-flex gap-2 mb-3">
          {/* Prioridad */}
          <Dropdown
            isOpen={dropdownOpen.priority}
            toggle={() => toggleDropdown("priority")}
          >
            <DropdownToggle caret color="light" className="d-flex align-items-center">
              <i
                className="fas fa-flag me-2"
                style={{
                  color:
                    priority === "Alta"
                      ? "red"
                      : priority === "Media"
                      ? "orange"
                      : "green",
                }}
              ></i>
              {priority}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setPriority("Alta")}>
                <i className="fas fa-flag me-2" style={{ color: "red" }}></i> Alta
              </DropdownItem>
              <DropdownItem onClick={() => setPriority("Media")}>
                <i className="fas fa-flag me-2" style={{ color: "orange" }}></i> Media
              </DropdownItem>
              <DropdownItem onClick={() => setPriority("Normal")}>
                <i className="fas fa-flag me-2" style={{ color: "green" }}></i> Normal
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          {/* Etiquetas */}
          <Dropdown
            isOpen={dropdownOpen.tags}
            toggle={() => toggleDropdown("tags")}
          >
            <DropdownToggle caret color="light" className="d-flex align-items-center">
              <i className="fas fa-tags me-2"></i> Etiquetas
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleTagsChange([...tags, "Urgente"])}>
                <i className="fas fa-tag me-2" style={{ color: "red" }}></i> Urgente
              </DropdownItem>
              <DropdownItem onClick={() => handleTagsChange([...tags, "Importante"])}>
                <i className="fas fa-tag me-2" style={{ color: "blue" }}></i> Importante
              </DropdownItem>
              <DropdownItem onClick={() => handleTagsChange([...tags, "Opcional"])}>
                <i className="fas fa-tag me-2" style={{ color: "gray" }}></i> Opcional
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          {/* Estado */}
          <Dropdown
            isOpen={dropdownOpen.status}
            toggle={() => toggleDropdown("status")}
          >
            <DropdownToggle caret color="light" className="d-flex align-items-center">
              <i
                className="fas fa-tasks me-2"
                style={{
                  color:
                    status === "Completado"
                      ? "green"
                      : status === "En Progreso"
                      ? "orange"
                      : "red",
                }}
              ></i>
              {status}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setStatus("Pendiente")}>
                <i className="fas fa-tasks me-2" style={{ color: "red" }}></i> Pendiente
              </DropdownItem>
              <DropdownItem onClick={() => setStatus("En Progreso")}>
                <i className="fas fa-tasks me-2" style={{ color: "orange" }}></i> En Progreso
              </DropdownItem>
              <DropdownItem onClick={() => setStatus("Completado")}>
                <i className="fas fa-tasks me-2" style={{ color: "green" }}></i> Completado
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        {/* Steps Section */}
        <h6 className="mb-2">Pasos</h6>
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          <ListGroup>
            {task.steps ? (
              Array.isArray(task.steps) ? (
                task.steps.map((step, index) => (
                  <Card key={index} className="mb-2">
                    <CardBody className="d-flex p-2">
                      {/* Columna izquierda: Título, checkbox y textarea */}
                      <div style={{ width: "40%" }}>
                        <div className="d-flex align-items-center">
                          <Input
                            type="checkbox"
                            checked={completedSteps.some(
                              (completedStep) => completedStep.id === step.id
                            )}
                            onChange={() => handleStepClick(step)}
                            className="me-2"
                          />
                          <div
                            onClick={() => handleStepClick(step)}
                            style={{ cursor: "pointer" }}
                          >
                            <strong
                              style={{
                                textDecoration: completedSteps.some(
                                  (completedStep) => completedStep.id === step.id
                                )
                                  ? "line-through"
                                  : "none",
                                fontSize: "0.9rem", // Tamaño de fuente más pequeño
                              }}
                            >
                              {step.title}
                            </strong>
                            <div className="mt-1">
                              {editDueDate === step.id ? (
                                <Input
                                  type="date"
                                  id={`dueDate-${step.id}`}
                                  value={step.dueDate || ""}
                                  onChange={(e) =>
                                    handleDueDateChange(step, e.target.value)
                                  }
                                  onBlur={() => setEditDueDate(null)}
                                  autoFocus
                                  className="form-control-sm"
                                />
                              ) : (
                                <Label
                                  for={`dueDate-${step.id}`}
                                  onClick={() => setEditDueDate(step.id)}
                                  className="text-muted"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  Fecha de vencimiento:{" "}
                                  {step.dueDate || "Sin fecha"}
                                </Label>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* Textarea para agregar comentarios */}
                        <FormGroup className="mt-2">
                          <Input
                            type="textarea"
                            className="font-size-12"
                            style={{
                              border: "none",
                              boxShadow: "none",
                              height: "60px",
                              maxHeight: "60px",
                              overflowY: "auto",
                              fontSize: "0.8rem", // Tamaño de fuente más pequeño
                            }}
                            placeholder="Agrega un comentario"
                            onFocus={() => {
                              setInputFocused((prev) => ({ ...prev, [step.id]: true }));
                            }}
                            onChange={(e) =>
                              setStepComments((prev) => ({
                                ...prev,
                                [step.id]: e.target.value,
                              }))
                            }
                            value={stepComments[step.id] || ""}
                          />
                          {inputFocused[step.id] && (
                            <span
                              onClick={() =>
                                handleAddStepComment(step.id, stepComments[step.id])
                              }
                              className="fas fa-check fa-lg m-1"
                              style={{
                                fontSize: "12px",
                                cursor: "pointer",
                                color: "gray",
                                transition: "color 0.3s",
                              }}
                              onMouseOver={(e) => (e.currentTarget.style.color = "blue")}
                              onMouseOut={(e) => (e.currentTarget.style.color = "black")}
                            />
                          )}
                        </FormGroup>
                      </div>

                      {/* Columna derecha: Lista de comentarios */}
                      <div style={{ width: "60%", marginLeft: "10px" }}>
                        <h6 className="mb-1" style={{ fontSize: "0.9rem" }}>Comentarios</h6>
                        <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                          {step.comments?.length > 0 ? (
                            step.comments.map((comment, idx) => (
                              <div key={idx} className="comment-item mb-1">
                                <strong style={{ fontSize: "0.8rem" }}>{comment.created_by}:</strong>
                                <p style={{ fontSize: "0.8rem" }}>{comment.text}</p>
                              </div>
                            ))
                          ) : (
                            <p className="text-muted" style={{ fontSize: "0.8rem" }}>No hay comentarios</p>
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))
              ) : (
                []
              )
            ) : (
              <p className="text-muted" style={{ fontSize: "0.9rem" }}>No hay pasos disponibles</p>
            )}
          </ListGroup>
        </div>

        {/* Formulario para agregar un nuevo paso */}
        <FormGroup className="mt-3">
          <Label for="nuevo-paso" style={{ fontSize: "0.9rem" }}>Agregar nuevo paso</Label>
          <Input
            type="text"
            id="nuevo-paso"
            value={newStepTitle}
            onChange={(e) => setNewStepTitle(e.target.value)}
            placeholder="Escribe el título del nuevo paso..."
            className="form-control-sm"
          />
          <Button color="primary" onClick={handleAddNewStep} className="mt-2 btn-sm">
            Agregar paso
          </Button>
        </FormGroup>

        {/* Attachments Section */}
        <div className="attachments-section mt-3">
          <h6 style={{ fontSize: "0.9rem" }}>Documentos adjuntos</h6>
          {attachments.length > 0 ? (
            attachments.map((attachment, index) => (
              <div key={index} className="attachment-item mb-1">
                <a
                  href={attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontSize: "0.8rem" }}
                >
                  Documento {index + 1}
                </a>
              </div>
            ))
          ) : (
            <p className="text-muted" style={{ fontSize: "0.8rem" }}>No hay documentos adjuntos</p>
          )}
          {attachments.length === 0 && (
            <div className="mt-2">
              <Input type="file" onChange={handleFileUpload} className="form-control-sm" />
              {newAttachment && (
                <Button
                  color="primary"
                  onClick={handleConfirmUpload}
                  className="mt-2 btn-sm"
                >
                  Subir archivo
                </Button>
              )}
            </div>
          )}
        </div>

        {/* Comments Section */}
        <div className="comments-section mt-3">
          <h6 style={{ fontSize: "0.9rem" }}>Comentarios</h6>
          {task.comments && task.comments.length > 0 ? (
            task.comments.map((comment, index) => (
              <div key={index} className="comment-item mb-1">
                <strong style={{ fontSize: "0.8rem" }}>{comment.created_by}:</strong>
                <p style={{ fontSize: "0.8rem" }}>{comment.text}</p>
              </div>
            ))
          ) : (
            <p className="text-muted" style={{ fontSize: "0.8rem" }}>No hay comentarios</p>
          )}
        </div>

        {/* Add New Comment Section */}
        <FormGroup className="mt-3">
          <Label for="nuevo-comentario" style={{ fontSize: "0.9rem" }}>Agregar comentario</Label>
          <Input
            type="textarea"
            className="font-size-12"
            style={{
              border: "none",
              boxShadow: "none",
              fontSize: "0.8rem", // Tamaño de fuente más pequeño
            }}
            placeholder="Agrega un comentario"
            onFocus={() => {
              setInputFocused((prev) => ({ ...prev, task: true }));
            }}
            onChange={(e) => setNewComment(e.target.value)}
            value={newComment}
          />
          {inputFocused.task && (
            <span
              onClick={handleAddComment}
              className="fas fa-check fa-lg m-1"
              style={{
                fontSize: "12px",
                cursor: "pointer",
                color: "gray",
                transition: "color 0.3s",
              }}
              onMouseOver={(e) => (e.currentTarget.style.color = "blue")}
              onMouseOut={(e) => (e.currentTarget.style.color = "black")}
            />
          )}
        </FormGroup>
      </ModalBody>
    </Modal>
  );
};

export default StepPanel;