import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";

//import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

import { tipoGestionContactoAdd } from "../../store/tipoGestionContacto/actions";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader";

const TipoGestionContactoAdd = (props) => {
  const history = useHistory();

  const [formData, setFormData] = useState({
     name:"",
  });

  const { name } =
    formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.tipoGestionContactoAdd({
     name,
    });
  };

  const redirect = () => {
    history.push("/tipoGestionContacto");
  };

console.log(formData.name);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact| Tipo de Gestion de Contacto </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <div style={{ width: "100%", paddingBottom: "1.5rem" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>
              Tipo de gestión de contacto
            </span>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
            <Card className="col-lg-8 mx-auto  mt-3">
              {props.tipoGestionContactoAdded.id ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha agregado el tipo de Gestion del Contacto con éxito
                </SweetAlert>
              ) : null}
              <CardBody>
                <h4 className="card-title">Agregar Tipo de Gestion</h4>
                <Form className="needs-validation" onSubmit={onSubmit}>
                  <Row className="col-lg-12 justify-content-center mt-3">
                    <Col md={6} className="mb-3">
                      <Label
                        for="name"
                        className="col-form-label col-sm-4 d-flex"
                      >
                        <strong>Nombre</strong>
                      </Label>

                      <Input
                        id="name"
                        name="name"
                        type="text"
                        onChange={onChange}
                        value={name}
                        valid={
                          props.error.name
                            ? false
                            : props.tipoGestionContactoAdded.id && true
                        }
                        invalid={
                          props.error
                            ? props.error.name
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.name}</FormFeedback>
                    </Col>
                  </Row>

                  <Row className=" mx-2 col-lg-12 justify-content-center">
                    <Col md={4} className="m-1">
                      <Link
                        className="btn btn-secondary text-dark"
                        to="/tipoGestionContacto"
                      >
                        Volver
                      </Link>

                      <Button color="primary" type="submit" className="ms-1">
                        Agregar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, tipoGestionContactoAdded } = state.TipoGestionContacto;
  return { error, loading, tipoGestionContactoAdded };
};

TipoGestionContactoAdd.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  tipoGestionContactoAdded: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { tipoGestionContactoAdd })(TipoGestionContactoAdd));
