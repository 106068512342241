import React, { useEffect, useState } from "react";
import { Card, Table } from "reactstrap";
import { getReportStatus, postLeadsStatusChange } from "../../../helpers/backend";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ManagementManual from "../../Whatsapp/components/ManagementManual";
import whatsapp from "../../../assets/images/whatsapp.png";
import ModalManagementDetails from "../../Whatsapp/components/ModalManagementDetails";
import "./DashboardStyles.css";
import { useSelector } from "react-redux";

const ContactsInManagement = () => {
  const history = useHistory();
  const [dataList, setDataList] = useState([]);
  const [manualOpen, setManualOpen] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [detallesModal, setDetallesModal] = useState(false);
  const [GestionDetallada, setGestionDetallada] = useState(false);

  // Usamos el estado global del tema desde Redux.
  const theme = useSelector((state) => state.theme.theme) || "light";

  // Función para obtener los datos.
  const getData = async () => {
    const response = await getReportStatus();
    console.log(response);
    setDataList(response.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleStatusChange = async (lead) => {
    const response = await postLeadsStatusChange(lead, {
      accion: "INICIAR",
    });
    console.log(response);
  };

  const handleDetallesModal = () => {
    setDetallesModal(false);
  };

  // Componente para mostrar el estado como badge.
  const StatusBadge = ({ estado }) => {
    const estadoConfig = {
      FINALIZADO: { text: "Finalizado", className: "bg-success" },
      PENDIENTE: { text: "Pendiente", className: "bg-danger" },
      SIN_ASIGNAR: { text: "Sin Asignar", className: "bg-warning" },
      "EN GESTION": { text: "En Gestión", className: "", style: { background: "rgb(111, 66, 193)" } },
    };
    const config = estadoConfig[estado] || {};
    return (
      <span className={`badge ${config.className}`} style={config.style}>
        {config.text}
      </span>
    );
  };

  return (
    <Card
      className="px-4 py-3"
      style={{
        borderColor: theme === "dark" ? "transparent" : "#f6f8fc",
        boxShadow:
          "0 4px 10px -1px rgb(0 0 0 / 0.1), 0 2px 7px -2px rgb(0 0 0 / 0.1)",
      }}
    >
      {manualOpen && (
        <ManagementManual
          isLead={true}
          isOpen={manualOpen}
          onClose={() => setManualOpen(false)}
          contactId={contactId}
        />
      )}
      {detallesModal && (
        <ModalManagementDetails
          isOpen={detallesModal}
          onCloseClick={handleDetallesModal}
          GestionDetallada={GestionDetallada}
        />
      )}
      <div>
        <p
          className={`fw-semibold font-size-18 ${
            theme === "dark" ? "text-dark" : ""
          }`}
        >
          Contactos por finalizar
        </p>
        <div
          className="table-wrapper custom-scroll"
          style={{ maxHeight: "400px", overflowY: "auto" }}
        >
          <Table
            responsive
            hover
            className={`font-size-13 text-center ${
              theme === "dark" ? "table-dark" : "table-light"
            }`}
          >
            <thead>
              <tr>
                <th style={{ color: theme === "dark" && "#c3cbe4" }}>#</th>
                <th style={{ color: theme === "dark" && "#c3cbe4" }}>Nombre</th>
                <th style={{ color: theme === "dark" && "#c3cbe4" }}>Estado</th>
                <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                  Teléfono
                </th>
                <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                  Última Gestión
                </th>
                <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                  Campaña
                </th>
                <th style={{ color: theme === "dark" && "#c3cbe4" }}>Agente</th>
                <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody className={` ${theme === "dark" ? "" : "table-light"}`}>
              {Array.isArray(dataList)
                ? dataList.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#121e2d" : "#fff",
                          height: "60px",
                        }}
                      >
                        <b>{index + 1}</b>
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#121e2d" : "#fff",
                        }}
                      >
                        {item.get_full_name}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#121e2d" : "#fff",
                        }}
                      >
                        <StatusBadge estado={item.statusDescripcion} />
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#121e2d" : "#fff",
                        }}
                      >
                        {item.phone && item.phone[0]?.telefono}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#121e2d" : "#fff",
                        }}
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            setDetallesModal(true);
                            setGestionDetallada(item.ultima_gestion);
                          }}
                        >
                          {item.ultima_gestion.created_date}
                        </Link>
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#121e2d" : "#fff",
                        }}
                      >
                        {item.campaign
                          ? item.campaign
                              .map((subItem) => subItem[0])
                              .join(", ")
                          : null}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#121e2d" : "#fff",
                        }}
                      >
                        {item.agent && item.agent[0] ? item.agent[0][0] : null}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#121e2d" : "#fff",
                        }}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.focus();
                            history.push({
                              pathname: "/chat",
                              state: {
                                selectedChatId:
                                  item.phone && item.phone[0]?.telefono,
                                contactId: item.id && item.id,
                                type: "WhatsApp",
                              },
                            });
                            handleStatusChange(item.id);
                          }}
                        >
                          <img
                            className="chat-item__img"
                            src={whatsapp}
                            alt="Avatar"
                          />
                        </span>{" "}
                        <Link
                          to="#"
                          onClick={() => {
                            setManualOpen(true);
                            setContactId(item.id);
                          }}
                        >
                          <span
                            className="fa fa-phone fa-md align-middle"
                            style={{ fontSize: "20px" }}
                          ></span>
                        </Link>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </div>
      </div>
    </Card>
  );
};

export default ContactsInManagement;
