import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { 
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Table,
  Input,
  Button,
  InputGroup,
 } from "reactstrap"
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import Pagination from "../../components/Common/Pagination"

import { auditsList, auditsFilter } from "../../store/audits/actions"

const Audits = (props) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");

  const onChange = (e) => {
    setSearch(e.target.value);
    setDate(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (search){
      dispatch(auditsFilter(`?search=${search}`));
    }
    else {
      dispatch(auditsFilter(`?search=${date}`));
    }
    

  };

  const resetSearch = () => {
    dispatch(auditsFilter(`?search=`));
    setSearch("");
    setDate("");
  };


  useEffect(() => {

    dispatch(auditsList())

  }, [dispatch])

  const params = {
    count: props.audits.count,
    next: props.audits.next,
    previous: props.audits.previous,
    results: props.audits.results,
  };

  const theme = useSelector((state) => state.theme.theme) || 'light'

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Auditoría </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div style={{ width: "100%", paddingBottom: "1.5rem" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>
              Auditoría
            </span>
          </div>

          {props.loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="date"
                            className="form-control"
                            name="date"
                            value={date}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                    <Col md={6}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Usuario, Estado ..."
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                {params.results ? (
                  <CardBody className="ps-5 pe-5">
                    <h2 className="mb-3 font-size-20">Historial</h2>
                    <div className="table-responsive">
                      <Table
                        responsive
                        hover
                        className={`font-size-13 text-center ${
                          theme === "dark" ? "table-dark" : "table-light"
                        }`}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              #
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Fecha y Hora
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Tipo
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Gestión
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Estado
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Descripción
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Usuario
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          className={` ${
                            theme === "dark" ? "" : "table-light"
                          }`}
                        >
                          {params.results.map((audits, idx) => (
                            <tr key={idx}>
                              <th
                                style={{
                                  backgroundColor:
                                    theme === "dark" ? "#121e2d" : "#fff",
                                  height: "60px",
                                }}
                                scope="row"
                              >
                                {idx + 1}
                              </th>
                              <td
                                style={{
                                  backgroundColor:
                                    theme === "dark" ? "#121e2d" : "#fff",
                                }}
                              >
                                {audits.created_date}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    theme === "dark" ? "#121e2d" : "#fff",
                                }}
                              >
                                {audits.content_type.toUpperCase()}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    theme === "dark" ? "#121e2d" : "#fff",
                                }}
                              >
                                {audits.tipo_gestion}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    theme === "dark" ? "#121e2d" : "#fff",
                                }}
                              >
                                {audits.estado}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    theme === "dark" ? "#121e2d" : "#fff",
                                }}
                              >
                                {audits.descripcion}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    theme === "dark" ? "#121e2d" : "#fff",
                                }}
                              >
                                {audits.user}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="audits/audits/"
                      filterFunction={auditsFilter}
                    />
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => {
  const { error, loading, audits } = state.Audits;
  return { error, loading, audits };
};

export default withRouter(connect(mapStateToProps)(Audits));
