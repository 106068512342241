import React from 'react'
import { MetaTags } from 'react-meta-tags'
import InboxEmail from './components/InboxEmail';
import BandejaEmail from './components/BandejaEmail';
import './styles/Email.css'
import { useSelector } from 'react-redux';
import { TbSearch } from "react-icons/tb";


const Email = () => {
  const theme = useSelector((state) => state.theme.theme) || 'light'

  return (
    <main
      className="page-content"
      style={{
        backgroundColor: theme === "light" && "#f6f8fc",
        height: "100vh",
        padding: "5rem 1rem 0 0",
      }}
    >
      <MetaTags>
        <title>TrueContact | Emails</title>
      </MetaTags>
      <div className="content_title_search">
        <h5 style={{ padding: "0 1rem" }}>Email</h5>
        <div className="content_input_search_email">
          <input
            type="text"
            className="input_search_email"
            placeholder="Buscar correo"
            style={{ backgroundColor: theme === "light" ? "#fff" : '#121e2d', color: theme === 'dark' && '#c3cbe4' }}
          />
          <button className="button_search_email">
            <TbSearch size={22} />
          </button>
        </div>
      </div>
      <div className="contain_emails">
        <InboxEmail />
        <BandejaEmail />
      </div>
    </main>
  );
}

export default Email