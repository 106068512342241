import React, { useState } from "react";
import { 
  Table, 
  Button, 
  Modal, 
  ModalBody, 
  ModalFooter, 
  Input, 
  FormGroup, 
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import { useSelector } from "react-redux";
import { postTask, patchTask } from "../../helpers/backend";
import classnames from 'classnames';
import "./TaskFlow.css"; // Archivo CSS específico para este componente

const TaskList = ({ tasks, openStepPanel, refreshTasks }) => {
  const theme = useSelector((state) => state.theme.theme) || 'light';
  const [modalOpen, setModalOpen] = useState(false);
  const [newTask, setNewTask] = useState({
    title: "",
    daysToComplete: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState('active');

  // Filtrar tareas
  const activeTasks = tasks.filter(task => !task.complete);
  const completedTasks = tasks.filter(task => task.complete);

  // Formatear fecha para el backend
  const formatDueDate = (days) => {
    const daysNum = parseInt(days) || 1;
    const date = new Date();
    date.setDate(date.getDate() + daysNum);
    date.setHours(8, 0, 0, 0);
    return date.toISOString().split('.')[0] + 'Z';
  };

  // Mostrar fecha legible
  const displayDueDate = (days) => {
    const daysNum = parseInt(days) || 1;
    const date = new Date();
    date.setDate(date.getDate() + daysNum);
    date.setHours(8, 0, 0, 0);
    
    return date.toLocaleString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  // Manejar cambio en días
  const handleDaysChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^[1-9]\d*$/.test(value)) {
      setNewTask({...newTask, daysToComplete: value});
    }
  };

  // Agregar nueva tarea
  const handleAddTask = async () => {
    if (!newTask.title.trim()) return;
    
    setIsSubmitting(true);
    try {
      const days = newTask.daysToComplete === "" ? "1" : newTask.daysToComplete;
      await postTask({
        title: newTask.title,
        dueDate: formatDueDate(days),
        flow: 1,
        project: 1
      });
      
      setModalOpen(false);
      setNewTask({ title: "", daysToComplete: "1" });
      refreshTasks && await refreshTasks();
    } catch (error) {
      console.error("Error al agregar tarea:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Manejar completado/restauración de tarea
  const handleTaskStatus = async (taskId, isComplete) => {
    try {
      await patchTask(taskId, {
        complete: isComplete,
        completedDate: isComplete ? new Date().toISOString() : null
      });
      refreshTasks && await refreshTasks();
    } catch (error) {
      console.error("Error al cambiar estado:", error);
    }
  };

  return (
    <div className="task-list-container">
      {/* Header con pestañas y botón */}
      <div className="task-list-header">
        <Nav tabs className="task-list-tabs">
          <NavItem>
            <NavLink
              className={classnames({ 
                'active': activeTab === 'active',
                'dark-tab': theme === 'dark'
              })}
              onClick={() => setActiveTab('active')}
            >
              <i className="fas fa-tasks me-2"></i>
              Activas
              <span className="task-count-badge">{activeTasks.length}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ 
                'active': activeTab === 'completed',
                'dark-tab': theme === 'dark'
              })}
              onClick={() => setActiveTab('completed')}
            >
              <i className="fas fa-check-circle me-2"></i>
              Completadas
              <span className="task-count-badge">{completedTasks.length}</span>
            </NavLink>
          </NavItem>
        </Nav>
        
        <Button 
          color="primary" 
          onClick={() => setModalOpen(true)}
          className="task-list-add-btn"
        >
          <i className="fas fa-plus me-2"></i> Nueva Tarea
        </Button>
      </div>

      {/* Contenido de pestañas */}
      <TabContent activeTab={activeTab} className="task-list-tab-content">
        {/* Pestaña de tareas activas */}
        <TabPane tabId="active">
          <Table className="task-list-table">
            <thead>
              <tr>
                <th>Título</th>
                <th>Fecha De creación</th>
                <th>Fecha límite</th>
                <th>Estado</th>
                <th>Flujo</th>
                <th>Asignado a</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {activeTasks.map((task) => (
                <tr key={`active-${task.id}`} onClick={() => openStepPanel(task.id)}>
                  <td>{task.title}</td>
                  <td>{task.created_date ? task.created_date : "Sin fecha"}</td>
                  <td>{task.dueDate ? task.dueDate : "Sin fecha"}</td>
                  <td>
                    <span className="task-status-badge pending">Pendiente</span>
                  </td>
                  <td>{task?.flow || "Sin flujo"}</td>
                  <td style={{ 
                    backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                    verticalAlign: "middle"
                  }}>
                    {task.contact?.length > 0 ? (
                      <div className="contact-list">
                        {task.contact.map(contact => (
                          <div key={contact.id} className="user-avatar-table"><span>{contact.get_full_name}</span>
                          </div>
                        ))}
                      </div>
                    ) : "Sin asignar"}
                  </td>
                  <td>
                    <Button 
                      size="sm" 
                      color="success"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTaskStatus(task.id, true);
                      }}
                      className="task-action-btn"
                    >
                      <i className="fas fa-check"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TabPane>

        {/* Pestaña de tareas completadas */}
        <TabPane tabId="completed">
          <Table className="task-list-table">
            <thead>
              <tr>
                <th>Título</th>
                <th>Completada el</th>
                <th>Flujo</th>
                <th>Asignado a</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {completedTasks.map((task) => (
                <tr key={`completed-${task.id}`} onClick={() => openStepPanel(task.id)}>
                  <td>{task.title}</td>
                  <td>
                    {task.completedDate ? new Date(task.completedDate).toLocaleDateString() : "Fecha no registrada"}
                  </td>
                  <td>{task?.flow || "Sin flujo"}</td>
                  <td style={{ 
                      backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                      verticalAlign: "middle"
                    }}>
                      {task.contact?.length > 0 ? (
                        <div className="contact-list">
                          {task.contact.map(contact => (
                            <div key={contact.id} className="user-avatar-table">
                              <span className="contact-name">{contact.get_full_name}</span>
                            </div>
                          ))}
                        </div>
                      ) : "Sin asignar"}
                    </td>
                  <td>
                    <Button 
                      size="sm" 
                      color="warning"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTaskStatus(task.id, false);
                      }}
                      className="task-action-btn"
                    >
                      <i className="fas fa-undo"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TabPane>
      </TabContent>

      {/* Modal para nueva tarea */}
      <Modal 
        isOpen={modalOpen} 
        toggle={() => !isSubmitting && setModalOpen(false)}
        className="task-list-modal"
      >
        <ModalBody>
          <FormGroup>
            <h5 className="text-dark mt-2 mb-2">           
              <i className="fas fa-plus-circle me-2"></i>
                  Nueva tarea
            </h5>
            <Label for="taskTitle">Título</Label>
            <Input
              id="taskTitle"
              type="text"
              value={newTask.title}
              onChange={(e) => setNewTask({...newTask, title: e.target.value})}
              placeholder="Describe la tarea..."
              className="task-list-input"
            />
          </FormGroup>
          <FormGroup>
            <Label for="daysToComplete">Días para culminación</Label>
            <Input
              id="daysToComplete"
              type="text"
              value={newTask.daysToComplete}
              onChange={handleDaysChange}
              placeholder="Ej: 3"
              className="task-list-input"
            />
            <div className="task-list-date-preview">
              Fecha límite: {displayDueDate(newTask.daysToComplete || "1")}
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="task-list-modal-footer">
          <Button 
            color="secondary" 
            onClick={() => setModalOpen(false)}
            disabled={isSubmitting}
            className="task-list-modal-btn text-dark"
          >
            Cancelar
          </Button>
          <Button 
            color="primary" 
            onClick={handleAddTask}
            disabled={!newTask.title.trim() || isSubmitting}
            className="task-list-modal-btn"
          >
            {isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm me-2"></span>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar Tarea
              </>
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TaskList;