import React, { useEffect, useState, useRef } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TaskColumn from "./TaskColumn";
import SweetAlert from "react-bootstrap-sweetalert";
import "./TaskFlow.css";
import { taskForContact, patchTask, delTask, getTask } from "../../helpers/backend";
import StepPanel from "./StepPanel";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AssignContactModal from "./AssignContactModal";

const TaskForContact = () => {
  const id = useParams().id;
  const scrollContainerRef = useRef(null);
  let isDragging = false;
  let startX, scrollLeft;
  const [dataProject, setDataProject] = useState({});
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskContacts, setTaskContacts] = useState([]);
  const [alert, setAlert] = useState(null);
  const [stepOpen, setStepOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);

  // Obtener la lista de tareas por contacto
  const getTaskList = async () => {
    try {
      const response = await taskForContact(id);
      setDataProject(response.data);
      const sortedContacts = response.data.sort(
        (a, b) => a.contact_id - b.contact_id
      );
      setTaskContacts(sortedContacts);
    } catch (error) {
      console.error("Error fetching task data by contact:", error);
    }
  };
    const getTaskListFlow = async () => {
      await getTask(id);
    };
  const openAssignModal = (taskId) => {
    setSelectedTaskId(taskId);
    setAssignModalOpen(true);
  };
  const assignContactToTask = async (contactId) => {
    console.log(contactId);
    try {
      const response = await patchTask(selectedTaskId, {
        contact: contactId
      }); // Función del backend
      console.log(response);
      if (response.status === 200) {
        getTaskList(); // Actualizar la lista de tareas
      }
    } catch (error) {
      console.error("Error asignando contacto a la tarea:", error);
    }
  };
  useEffect(() => {
    getTaskList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Funcionalidad de arrastrar y soltar
  const handleMouseDown = (e) => {
    isDragging = true;
    scrollContainerRef.current.classList.add("dragging");
    startX = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft = scrollContainerRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    isDragging = false;
    scrollContainerRef.current.classList.remove("dragging");
  };

  const moveTask = async (
    taskIndex,
    fromContactIndex,
    toContactIndex,
    hoverIndex
  ) => {
    const newTaskContacts = [...taskContacts];

    // Verificar que los índices sean válidos
    if (
      newTaskContacts[fromContactIndex]?.tasks_by_project &&
      newTaskContacts[toContactIndex]?.tasks_by_project &&
      taskIndex >= 0
    ) {
      let movedTask = null;
      for (const project of newTaskContacts[fromContactIndex]
        .tasks_by_project) {
        if (taskIndex < project.tasks.length) {
          // Extraer la tarea de la columna de origen
          [movedTask] = project.tasks.splice(taskIndex, 1);
          break;
        } else {
          taskIndex -= project.tasks.length; // Ajustar el índice para el siguiente proyecto
        }
      }

      if (!movedTask) {
        console.error("No se encontró la tarea en el índice especificado");
        return;
      }

      // Insertar la tarea en la columna de destino (primer proyecto por simplicidad)
      newTaskContacts[toContactIndex].tasks_by_project[0].tasks.splice(
        hoverIndex,
        0,
        movedTask
      );

      // Actualizar el contacto asignado a la tarea
      const newContactId = newTaskContacts[toContactIndex].contact_id;
      movedTask.contact = newContactId;

      // Actualizar el estado local (optimistic update)
      setTaskContacts(newTaskContacts);

      // Actualizar el contacto en el backend
      try {
        await patchTask(movedTask.id, {
          contact: [newContactId]
        });
        console.log("Contacto actualizado con éxito",);
      } catch (error) {
        console.error("Error actualizando el contacto:", error);
        // Revertir el cambio en caso de error
        newTaskContacts[toContactIndex].tasks_by_project[0].tasks.splice(
          hoverIndex,
          1
        );
        for (const project of newTaskContacts[fromContactIndex]
          .tasks_by_project) {
          if (taskIndex <= project.tasks.length) {
            project.tasks.splice(taskIndex, 0, movedTask);
            break;
          }
        }
        setTaskContacts(newTaskContacts);
      }
    } else {
      console.error("Índices inválidos en el movimiento de tareas");
    }
  };

  // Eliminar una tarea
  const confirmDeleteTask = async (taskId) => {
    try {
      await delTask(taskId);
      const newTaskContacts = taskContacts.map((contact) => ({
        ...contact,
        tasks: contact.tasks.filter((task) => task.id !== taskId)
      }));
      setTaskContacts(newTaskContacts);
      setAlert(
        <SweetAlert
          success
          title="¡Eliminada!"
          onConfirm={hideAlert}
          timeout={2000}
          showConfirm={false}
        >
          La tarea ha sido eliminada con éxito.
        </SweetAlert>
      );
    } catch (error) {
      console.error("Error eliminando la tarea:", error);
      setAlert(
        <SweetAlert
          danger
          title="Error"
          onConfirm={hideAlert}
          timeout={2000}
          showConfirm={false}
        >
          No se pudo eliminar la tarea. Intenta nuevamente.
        </SweetAlert>
      );
    }
  };

  const hideAlert = () => setAlert(null);

  // Abrir el panel de detalles de la tarea
  const openStepPanel = (task) => {
    setSelectedTask(task);
    setStepOpen(true);
  };

  const closeStepPanel = () => {
    setSelectedTask(null);
    setStepOpen(false);
  };

  // Aplanar la estructura de tasks_by_project
  const flattenTasks = (contact) => {
    return contact.tasks_by_project.flatMap((project) => project.tasks);
  };
  const handleCompleteTask = async (taskId, isComplete) => {
    
    console.log( isComplete   );
  
    try {
      const response = await patchTask(taskId, {
        complete: isComplete, 
      });
      if (response.status === 200) {
        getTaskList(); 
        getTaskListFlow()
      }
    } catch (error) {
      console.error("Error al completar la tarea:", error);
    }
  };
  return (
    <React.Fragment>
      {alert}
      <DndProvider backend={HTML5Backend}>
        <div
          className={`page-content-task ${selectedTask ? "panel-open" : ""}`}
          ref={scrollContainerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {Array.isArray(taskContacts)
            ? taskContacts.map((contact, index) => (
                <TaskColumn
                  key={contact.contact_id}
                  title={contact.contact_name}
                  tasks={flattenTasks(contact)}
                  moveTask={moveTask}
                  deleteTask={confirmDeleteTask}
                  columnIndex={index}
                  openStepPanel={openStepPanel}
                  assignContactToTask={openAssignModal}
                  contacts={dataProject.contacts} 
                  groupContacts={dataProject.group_contact}
                  onCompleteTask={handleCompleteTask}
                />
              ))
            : []}
        </div>
      </DndProvider>

      {/* StepPanel */}
      {stepOpen && (
        <StepPanel
          taskNumber={selectedTask}
          isOpen={stepOpen}
          toggle={closeStepPanel}
        />
      )}
      <AssignContactModal
        isOpen={assignModalOpen}
        toggle={() => setAssignModalOpen(false)}
        onAssignContact={assignContactToTask} // Función para asignar contactos
        contacts={dataProject.contacts} // Pasar la lista de contactos
        groupContacts={dataProject.group_contact}
      />
    </React.Fragment>
  );
};

export default TaskForContact;
