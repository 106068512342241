import React, { useState, useEffect, useRef } from 'react';
import ChatWindow from './components/ChatWindow';
import ChatHistory from './components/ChatHistory';
import './App.css';
import { Container } from 'reactstrap';
import { postCreateChat, postSendMessage } from '../../helpers/backend';

const App = () => {
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [isTyping, setIsTyping] = useState(false); // Estado para controlar el efecto de "escribiendo"
  const ws = useRef(null);
  const typingInterval = useRef(null); // Referencia para el intervalo de "escribiendo"

  // Crear un nuevo chat
  const createNewChat = async () => {
    const response = await postCreateChat();
    console.log(response);
    setChatId(response.data.id); // Actualiza el chatId
    return response;
  };

  // Conectar WebSocket
  const connectWebSocket = (chatId, onMessageReceived) => {
    console.log(`Intentando conectar WebSocket con chatId: ${chatId}`); // Depuración
    const ws = new WebSocket(`wss://basetcback.grupoda2.com/ws/chatai/${chatId}/`);

    ws.onopen = () => {
      console.log('Conexión WebSocket establecida'); // Depuración
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      onMessageReceived(message);
    };

    ws.onerror = (error) => {
      console.error('Error en WebSocket:', error); // Depuración
    };

    ws.onclose = () => {
      console.log('Conexión WebSocket cerrada'); // Depuración
    };

    return ws;
  };

  // Simular efecto de "escribiendo"
  const simulateTypingEffect = (text, onUpdate) => {
    let index = 0;
    typingInterval.current = setInterval(() => {
      if (index < text.length) {
        onUpdate(text.slice(0, index + 1)); // Actualizar el texto letra por letra
        index++;
      } else {
        clearInterval(typingInterval.current); // Detener el efecto cuando se complete
        setIsTyping(false); // Desactivar el estado de "escribiendo"
      }
    }, 30); // Velocidad de escritura más rápida (30ms por letra)
  };

  // Detener el efecto de "escribiendo"
  const stopTypingEffect = () => {
    if (typingInterval.current) {
      clearInterval(typingInterval.current); // Detener el intervalo
      setIsTyping(false); // Desactivar el estado de "escribiendo"

      // Mostrar el texto completo
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];

        if (lastMessage.sender === 'ai' && lastMessage.isTyping) {
          lastMessage.text = lastMessage.fullText; // Mostrar el texto completo
          lastMessage.isTyping = false; // Desactivar el estado de "escribiendo"
        }

        return updatedMessages;
      });
    }
  };

  // Manejar mensajes recibidos
  const handleWebSocketMessage = (message) => {
    console.log('Mensaje recibido:', message); // Depuración

    // Procesar el mensaje del usuario
    if (message.user_message) {
      const userMessage = {
        text: message.user_message.body,
        sender: 'user',
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
    }

    // Procesar la respuesta de la IA
    if (message.ai_message) {
      const aiMessage = {
        text: '', // Inicialmente vacío
        fullText: message.ai_message.body, // Guardar el texto completo
        sender: 'ai',
        isTyping: true, // Indicar que la IA está "escribiendo"
      };

      // Agregar el mensaje de la IA al estado (inicialmente vacío)
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
      setIsTyping(true); // Activar el estado de "escribiendo"

      // Simular el efecto de "escribiendo"
      simulateTypingEffect(message.ai_message.body, (typedText) => {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const lastMessage = updatedMessages[updatedMessages.length - 1];

          // Actualizar el texto del último mensaje (respuesta de la IA)
          if (lastMessage.sender === 'ai') {
            lastMessage.text = typedText;
            lastMessage.isTyping = typedText.length < message.ai_message.body.length; // Dejar de mostrar el cursor cuando termine
          }

          return updatedMessages;
        });
      });

      setIsWaiting(false); // Detener la animación de carga
    }
  };

  // Enviar mensaje
  const sendMessage = async (chatId, messageText) => {
    const response = await postSendMessage({
      chat: chatId,
      body: messageText,
    });
    console.log(response);
    return response;
  };

  // Manejar creación de chat
  const handleCreateChat = async () => {
    const newChat = await createNewChat();
    console.log(newChat);

    if (newChat && newChat.data.id) {
      setChats([...chats, newChat]);
      setCurrentChatId(newChat.data.id);
      setMessages([]);
    }
  };

  // Enviar mensaje
  const handleSendMessage = async (text) => {
    if (!currentChatId) return;

    setIsWaiting(true); // Activar animación de carga

    const sentMessage = await sendMessage(currentChatId, text);
    if (sentMessage) {
      console.log('Mensaje enviado:', sentMessage);
    }
  };

  // Seleccionar chat
  const handleSelectChat = (chatId) => {
    setCurrentChatId(chatId);
    setMessages([]);
  };

  // Conectar WebSocket cuando chatId cambie
  useEffect(() => {
    if (chatId) {
      // Cerrar la conexión WebSocket anterior si existe
      if (ws.current) {
        ws.current.close();
      }

      // Conectar el WebSocket para el nuevo chat
      ws.current = connectWebSocket(chatId, handleWebSocketMessage);
    }

    // Limpiar WebSocket al desmontar
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [chatId]);

  return (
    <Container fluid>
      <div className="page-content">
        <div className="ds-chat-container">
          <ChatHistory
            chats={chats}
            onSelectChat={handleSelectChat}
            onCreateChat={handleCreateChat}
          />
          <ChatWindow
            messages={messages}
            onSendMessage={handleSendMessage}
            isWaiting={isWaiting}
            isTyping={isTyping}
            onStopTyping={stopTypingEffect}
          />
        </div>
      </div>
    </Container>
  );
};

export default App;