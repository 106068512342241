import React, { useState } from "react";
import { Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import styles from "./CasosDetails.module.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MapModal from "../Modales/MapModal";
import "./Casos.css";
import { useSelector } from "react-redux";

function Timeline(props) {
  const gestiones = props.gestiones;
  const [modalOpen, setModalOpen] = useState(false);
  const [coords, setCoords] = useState("");

  const theme = useSelector((state) => state.theme.theme) || "light";

  const [checked, setchecked] = useState(false);
  const changeLista = (e) => {
    setchecked(e.target.checked);
  };

  function Status({ estado }) {
    const estados = {
      ACEPTADO: { className: "bg-warning", text: "Aceptado" },
      ASIGNADO: { className: "bg-primary", text: "Asignado" },
      INICIADO: { className: "bg-primary", text: "Iniciado" },
      FINALIZADO: { background: "#09cc09", text: "Finalizado" },
      PAUSADO: { className: "bg-info", text: "Pausado" },
      REANUDADO: {
        className: "bg-success bg-soft text-muted",
        text: "Reanudado",
      },
    };

    const estadoActual = estados[estado];

    return (
      <div
        className={`badge  ${estadoActual.className} font-size-13`}
        style={{ background: estadoActual.background }}
      >
        <strong>{estadoActual.text}</strong>
      </div>
    );
  }
  
  return (
    <Row className="justify-content-center">
      <div className="form-check form-switch">
        <input
          className="form-check-input  "
          type="checkbox"
          id="flexSwitchCheckChecked"
          onChange={changeLista}
          checked={checked}
        />
        <Label style={{color: theme === 'dark' && '#c3cbe4'}} className="form-check-label font-size-14" for="flexSwitchCheckChecked">
          Ver Linea de Tiempo
        </Label>
      </div>

      <Col xl={12}>
        {!checked ? (
          <div>
            <h5 className=" text-center">Historial de Gestiones </h5>
            <Table
              className={`font-size-13 text-center mt-4 mb-0 ${
                theme === "dark" ? "table-dark" : "table-light"
              }`}
              style={{
                textAlign: "center",
              }}
              responsive
              hover
            >
              <thead className="">
                <tr>
                  <th style={{ color: theme === "dark" && "#c3cbe4" }}>#</th>
                  <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                    Fecha de Acción
                  </th>
                  <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                    Acción
                  </th>
                  <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                    Tiempo entre acciones
                  </th>
                  <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                    Observacion
                  </th>
                  <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                    Ubicación
                  </th>
                  <th style={{ color: theme === "dark" && "#c3cbe4" }}>
                    Usuario
                  </th>
                </tr>
              </thead>
              <tbody
                className={`align-middle ${
                  theme === "dark" ? "" : "table-light"
                }`}
              >
                {Array.isArray(gestiones)
                  ? [...gestiones].reverse().map((item, idx) => (
                      <tr key={idx}>
                        <th
                          style={{
                            backgroundColor:
                              theme === "dark" ? "#121e2d" : "#fff",
                            height: "60px",
                          }}
                        >
                          {idx + 1}
                        </th>
                        <td
                          style={{
                            backgroundColor:
                              theme === "dark" ? "#121e2d" : "#fff",
                          }}
                        >
                          {" "}
                          {item.created_date}{" "}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              theme === "dark" ? "#121e2d" : "#fff",
                          }}
                        >
                          {" "}
                          <Status estado={item.estado} />
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              theme === "dark" ? "#121e2d" : "#fff",
                          }}
                        >
                          {" "}
                          {item.duracion}{" "}
                        </td>
                        {item.observaciones ? (
                          <td
                            style={{
                              backgroundColor:
                                theme === "dark" ? "#121e2d" : "#fff",
                            }}
                          >
                            {" "}
                            {item.observaciones}{" "}
                          </td>
                        ) : (
                          <td
                            style={{
                              backgroundColor:
                                theme === "dark" ? "#121e2d" : "#fff",
                              color:
                                theme === "dark" ? "#c3cbdc66" : "#00000066",
                            }}
                          >
                            {" "}
                            Sin observaciones{" "}
                          </td>
                        )}
                        <td
                          style={{
                            backgroundColor:
                              theme === "dark" ? "#121e2d" : "#fff",
                          }}
                        >
                          {item.coordenadas ? (
                            <Link
                              to="#"
                              onClick={() => {
                                setModalOpen(true);
                                setCoords(item.coordenadas);
                              }}
                            >
                              Ver Ubicación
                            </Link>
                          ) : (
                            <td
                              style={{
                                backgroundColor:
                                  theme === "dark" ? "#121e2d" : "#fff",
                                color:
                                  theme === "dark" ? "#c3cbdc66" : "#00000066",
                              }}
                            >
                              {" "}
                              Sin ubicación{" "}
                            </td>
                          )}
                        </td>
                        {modalOpen ? (
                          <MapModal
                            isOpen={modalOpen}
                            onClose={() => setModalOpen(false)}
                            coords={coords}
                          />
                        ) : null}
                        <td
                          style={{
                            backgroundColor:
                              theme === "dark" ? "#121e2d" : "#fff",
                          }}
                        >
                          {" "}
                          {item.user}{" "}
                        </td>
                      </tr>
                    ))
                  : []}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className={`timeline ${styles.timeLine}`}>
            {Array.isArray(gestiones)
              ? [...gestiones].reverse().map((item, idx) => (
                  <div
                    className={`timeline-item ${
                      idx % 2 === 0 ? "timeline-left" : ""
                    }`}
                    key={idx}
                  >
                    <div className="timeline-block">
                      <Card
                        className="timeline-box"
                        style={{ maHeight: "100px" }}
                      >
                        <CardBody className="" style={{ lineHeight: "0.5rem", color: theme === 'dark' && '#c3cbe4' }}>
                          {item.estado ? (
                            <div style={{ zIndex: "9999" }}>
                              <b
                                className={`position-absolute top-0 ${
                                  idx % 2 === 0 ? "start-0" : "end-0"
                                } m-2`}
                              >
                                {item.estado ? (
                                  <Status estado={item.estado} />
                                ) : null}
                              </b>
                            </div>
                          ) : null}
                          <span className="timeline-icon"></span>
                          <p className="mt-4 font-size-12">
                            <b>Fecha: </b>
                            {item.created_date}{" "}
                          </p>
                          <p className="font-size-12">
                            <b>Tiempo entre acciones: </b>
                            {item.duracion}{" "}
                          </p>
                          <p
                            className="font-size-12"
                            style={{ lineHeight: "1rem" }}
                          >
                            <b>Observaciones: </b>
                            {item.observaciones}
                          </p>
                          <p className="font-size-12">
                            <b> Usuario: </b>
                            {item.user}
                          </p>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                ))
              : null}
          </div>
        )}
      </Col>
    </Row>
  );
}

export default Timeline;
