import React, { useEffect, useRef, useState } from 'react';
import { TbArrowNarrowLeft } from "react-icons/tb";
import PhotoUser from '../../../assets/images/user-chat.svg';
import { TbPaperclip, TbCircleX, TbMicrophone, TbSend } from "react-icons/tb";
import { getDataUser } from '../../../helpers/backend';
import { use } from 'react';

const Chat = ({ chat, onBack, setSelectedChat }) => {
  const [socket, setSocket] = useState(null);
  const [userDataChat, setUserDataChat] = useState([]);
  const [message, setMessage] = useState("");
  const [getChat, setGetChat] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [fileModalVisible, setFileModalVisible] = useState(false);
  const [fileCaption, setFileCaption] = useState("");
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  
  const user = JSON.parse(localStorage.getItem("user"));

  const messageEndRef = useRef(null);

  const formatTime = (timeString) => {
    if (!timeString) return "";
    // Se asume el formato "DD/MM/YYYY hh:mm AM/PM"
    const parts = timeString.split(" ");
    if (parts.length >= 3) {
      let time = parts[1]; // "09:46"
      const period = parts[2].toLowerCase(); // "am" o "pm"
      if (time.startsWith("0")) {
        time = time.substring(1);
      }
      return `${time} ${period}`;
    }
    return timeString;
  };

  const userData = async () => {
    const response = await getDataUser();
    const userId = response.data[0]?.id;
    setUserDataChat(userId);
  };

  useEffect(() => {
    userData();
  }, []);

  useEffect(() => {
    if (messageEndRef.current) {
      setTimeout(() => {
        messageEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [getChat]);

  useEffect(() => {
    if (userDataChat && chat) {
      setLoading(true);

      const chatUrl = `wss://basetcback.grupoda2.com/ws/chat/${user.id}/${chat.id_contact}/`;
      const ws = new WebSocket(chatUrl);

      ws.onopen = () => {
        console.log('Conexión WebSocket abierta');
      };

      ws.onmessage = (event) => {
        try {
          const receivedMessage = JSON.parse(event.data);
          console.log("Mensaje recibido del WebSocket:", receivedMessage);
      
          // Verifica si el objeto recibido es válido
          if (receivedMessage && typeof receivedMessage === 'object') {
            setGetChat((prevMessages) => {
              // Combina los mensajes previos con los nuevos mensajes
              const newMessages = { ...prevMessages, ...receivedMessage };
      
              // Asegúrate de que 'newMessages' sea un array de objetos
              const groupedMessages = Object.entries(newMessages).map(([date, messages]) => ({
                date,
                messages: Array.isArray(messages) ? messages : [],  // Asegúrate de que 'messages' sea un array
              }));
      
              return groupedMessages;  // Devuelve un array
            });
          } else {
            console.error("El objeto recibido no tiene el formato esperado:", receivedMessage);
          }
      
          setLoading(false);
        } catch (error) {
          console.error('Error al procesar el mensaje:', error, event.data);
        }
      };
      
      

      ws.onerror = (error) => {
        console.error('Error en WebSocket:', error);
      };

      ws.onclose = () => {
        console.log('Conexión WebSocket cerrada');
      };

      setSocket(ws);

      return () => {
        ws.close();
      };
    }
  }, [userDataChat, chat]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1];
        
        setFile({
          base64: base64Data,
          name: selectedFile.name,
          caption: ""
        });
        setFileModalVisible(true);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleBack = () => {
    if (socket) {
      socket.close();
    }
    setGetChat([]);
    setSelectedChat(null);
    onBack();
  };

  const sendMessage = () => {
    if (!socket) {
      console.error("No hay conexión WebSocket activa.");
      return;
    }
  
    const currentDate = new Date().toISOString().split("T")[0]; // Formato de fecha (YYYY-MM-DD)
  
    if (file) {
      const fileMessage = {
        file: file.base64,
        filename: file.name,
        caption: fileCaption || "Sin descripción",
        id_contact: userDataChat,
        date: currentDate, // Fecha del mensaje
      };
  
      // Actualiza el estado agrupando por fecha
      setGetChat((prevMessages) => {
        const existingDateGroup = prevMessages.find((group) => group.date === currentDate);
  
        if (existingDateGroup) {
          // Si ya existe un grupo para la fecha, añade el mensaje al array de mensajes
          return prevMessages.map((group) =>
            group.date === currentDate
              ? { ...group, messages: [...group.messages, fileMessage] }
              : group
          );
        } else {
          // Si no existe un grupo para la fecha, crea uno nuevo
          return [...prevMessages, { date: currentDate, messages: [fileMessage] }];
        }
      });
  
      socket.send(JSON.stringify(fileMessage));
    } else if (message.trim() !== "") {
      const textMessage = {
        message: message.trim(),
        id_contact: userDataChat,
        date: currentDate, // Fecha del mensaje
      };
  
      // Actualiza el estado agrupando por fecha
      setGetChat((prevMessages) => {
        const existingDateGroup = prevMessages.find((group) => group.date === currentDate);
  
        if (existingDateGroup) {
          // Si ya existe un grupo para la fecha, añade el mensaje al array de mensajes
          return prevMessages.map((group) =>
            group.date === currentDate
              ? { ...group, messages: [...group.messages, textMessage] }
              : group
          );
        } else {
          // Si no existe un grupo para la fecha, crea uno nuevo
          return [...prevMessages, { date: currentDate, messages: [textMessage] }];
        }
      });
      
      socket.send(JSON.stringify(textMessage));
    } else {
      console.log("No se puede enviar: ni mensaje ni archivo están presentes.");
      return;
    }
  
    // Limpiar campos después de enviar
    setMessage("");
    setFile(null);
    setFileCaption("");
    setFileModalVisible(false);
  };

  const startRecording = async () => {
    if (recording) return; // Evitar iniciar otra grabación si ya se está grabando
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      audioChunksRef.current = [];
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
        // Detener todas las tracks del stream para liberar recursos
        stream.getTracks().forEach((track) => track.stop());
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Audio = reader.result.split(",")[1];
          sendAudioMessage(base64Audio);
        };
        setRecording(false);
      };
      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;
      setRecording(true);
    } catch (error) {
      console.error("Error al iniciar la grabación:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && recording) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
        // Detener las tracks del stream para liberar recursos
        mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
        const reader = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = () => {
          const base64Audio = reader.result.split(",")[1];
          sendAudioMessage(base64Audio);
        };
        setRecording(false);
        mediaRecorderRef.current = null;
      };
    }
  };

  const sendAudioMessage = (audioBase64) => {
    if (!socket) {
      console.error("No hay conexión WebSocket activa.");
      return;
    }
    const currentDate = new Date().toISOString().split("T")[0];
    const audioMessage = {
      file: audioBase64,
      filename: `audio_${Date.now()}.wav`,
      caption: "Audio", // Puedes personalizar el caption o dejarlo vacío
      id_contact: userDataChat,
      date: currentDate,
    };

    setGetChat((prevMessages) => {
      const existingDateGroup = prevMessages.find((group) => group.date === currentDate);
      if (existingDateGroup) {
        return prevMessages.map((group) =>
          group.date === currentDate
            ? { ...group, messages: [...group.messages, audioMessage] }
            : group
        );
      } else {
        return [...prevMessages, { date: currentDate, messages: [audioMessage] }];
      }
    });
    socket.send(JSON.stringify(audioMessage));
  };
  

  const getImageUrl = (file) => {
    if (file.startsWith('data:image')) {
      return file;
    }
    return `https://pruebatcback.grupoda2.com${file}`;
  };

  const openImageModal = (file, caption) => {
    setSelectedImage({ file, caption });
    setImageModalVisible(true);
  };

  const closeImageModal = () => {
    setImageModalVisible(false);
    setSelectedImage(null);
  }

  return (
    <section className="chat_interno_chat">
      <header className="header_chat_" style={{ paddingTop: "6px" }}>
        <div className="button_volver_chat" onClick={handleBack}>
          <TbArrowNarrowLeft size={18} />
        </div>
        <div className="content_name_chat">
          <h4 className="name_chat">{chat.name}</h4>
        </div>
        <div className="content_img_user_chat">
          <img className="img_user_chat" src={PhotoUser} alt="" />
        </div>
      </header>
      <div className="bandeja_del_chat">
        {loading ? (
          <div className="loading_spinner">
            <div className="spinner"></div>
          </div>
        ) : (
          getChat.map(({ date, messages }) => (
            <div key={date}>
              <div className="content_date_header">
                <div className="date_header">{date}</div>
              </div>
              {messages.map((msg, index) => (
                <div
                  className={`${
                    msg.id_contact === userDataChat
                      ? "contain_message_user"
                      : "contain_message_contact"
                  }`}
                  key={index}
                >
                  <div
                    className={`${
                      msg.id_contact === userDataChat
                        ? "content_message_user"
                        : "content_message_contact"
                    }`}
                  >
                    {msg.file ? (
                      <div>
                        {msg.filename.match(/\.(jpg|jpeg|png|gif)$/) ? (
                          <div
                            className="content_massage_image"
                            onClick={() =>
                              openImageModal(msg.file, msg.caption)
                            }
                          >
                            <img
                              src={getImageUrl(msg.file)}
                              alt={msg.filename}
                              className="message_image"
                            />
                          </div>
                        ) : (
                          <a
                            href={getImageUrl(msg.file)}
                            download={msg.filename}
                          >
                            Descargar {msg.filename}
                          </a>
                        )}
                        {msg.caption && (
                          <span className="message_caption">{msg.caption}</span>
                        )}
                      </div>
                    ) : (
                      <div className='content_message_'>
                        <span className="message">{msg.message}</span>
                        <div className='content_fecha_massage'>
                        <span className='message_time'>{formatTime(msg.f_enviar)}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
        <div ref={messageEndRef} />
      </div>

      {/* Modal para mostrar la imagen */}
      {imageModalVisible && selectedImage && (
        <div className="image_modal_overlay">
          <div className="image_modal">
            <div className="image_modal_close">
              <TbArrowNarrowLeft size={24} onClick={closeImageModal} />
            </div>
            <div className="image_preview">
              <img
                src={getImageUrl(selectedImage.file)}
                alt="Vista previa"
                className="image_preview_image"
              />
            </div>
            <div className="content_image_caption">
              <span className="image_caption">{selectedImage.caption}</span>
            </div>
          </div>
        </div>
      )}

      {/* Modal para mostrar el archivo */}
      {fileModalVisible && file && (
        <div className="file_modal_overlay">
          <div className="file_modal">
            <div className="file_preview">
              {file.name && file.name.match(/\.(jpg|jpeg|png|gif)$/) ? (
                <img
                  src={`data:image/png;base64,${file.base64}`}
                  alt="Vista previa"
                  className="file_preview_image"
                />
              ) : (
                <p>Este archivo no se puede previsualizar.</p>
              )}
            </div>
            <div className="content_buttons_actions_modal">
              <div
                className="cancel_file_chat"
                onClick={() => setFileModalVisible(false)}
              >
                <TbCircleX size={23} />
              </div>
              <input
                placeholder="Añadir un mensaje..."
                value={fileCaption}
                onChange={(e) => setFileCaption(e.target.value)}
                className="caption_input"
              />
              <div onClick={sendMessage} className="send_file_chat">
                <TbSend size={19} />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Acciones para enviar el mensaje */}
      <div className="content_actions">
        <label className="button_file_chat" htmlFor="fileInput">
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="fileInput"
          />
          <TbPaperclip size={19} />
        </label>

        <div>
          <input
            className="chat_input"
            placeholder="Añadir un mensaje..."
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                sendMessage();
              }
            }}
          />
        </div>

        <div className="content_micro_send">
          <div
            className={`button_audio_chat ${recording ? "recording" : ""}`}
            onMouseDown={startRecording}
            onMouseUp={stopRecording}
          >
            <TbMicrophone size={19} />
          </div>
          <div className="button_send_message">
            <TbSend size={19} onClick={sendMessage} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Chat;
