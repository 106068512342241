import React, { useState, useEffect } from "react";
import "./NotesApp.css";
import { Container } from "reactstrap";

const initialNotes = [
  {
    id: 1,
    title: "Recordatorio importante",
    content: "Llamar al cliente a las 3pm para confirmar la reunión.",
    color: "#ffeb99",
    date: "2023-05-15"
  },
  {
    id: 2,
    title: "Ideas para el proyecto",
    content:
      "Investigar sobre las nuevas APIs de React y probar el hook useTransition.",
    color: "#c2f0c2",
    date: "2023-05-10"
  },
  {
    id: 3,
    title: "Lista de compras",
    content: "Leche, pan, huevos, frutas, vegetales, carne.",
    color: "#99ccff",
    date: "2023-05-05"
  }
];

const NotesApp = () => {
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [newNote, setNewNote] = useState({
    title: "",
    content: "",
    color: "#ffeb99"
  });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const savedNotes = localStorage.getItem("post-it-notes");
    if (savedNotes) {
      setNotes(JSON.parse(savedNotes));
    } else {
      setNotes(initialNotes);
      localStorage.setItem("post-it-notes", JSON.stringify(initialNotes));
    }
  }, []);

  useEffect(() => {
    if (notes.length > 0) {
      localStorage.setItem("post-it-notes", JSON.stringify(notes));
    }
  }, [notes]);

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setIsEditing(false);
    setShowEditor(false);
  };

  const handleEditClick = (note) => {
    setSelectedNote(note);
    setIsEditing(true);
    setShowEditor(true);
    setNewNote({
      title: note.title,
      content: note.content,
      color: note.color
    });
  };

  const handleSaveNote = () => {
    if (isEditing && selectedNote) {
      const updatedNotes = notes.map((note) =>
        note.id === selectedNote.id
          ? {
              ...note,
              title: newNote.title,
              content: newNote.content,
              color: newNote.color,
              date: new Date().toISOString().split("T")[0]
            }
          : note
      );
      setNotes(updatedNotes);
      setSelectedNote(updatedNotes.find((note) => note.id === selectedNote.id));
    } else {
      const newId =
        notes.length > 0 ? Math.max(...notes.map((n) => n.id)) + 1 : 1;
      const noteToAdd = {
        id: newId,
        title: newNote.title,
        content: newNote.content,
        color: newNote.color,
        date: new Date().toISOString().split("T")[0]
      };
      setNotes([...notes, noteToAdd]);
      setSelectedNote(noteToAdd);
    }
    setShowEditor(false);
    setIsEditing(false);
  };

  const handleDeleteNote = () => {
    const updatedNotes = notes.filter((note) => note.id !== selectedNote.id);
    setNotes(updatedNotes);
    setSelectedNote(null);
    setShowEditor(false);
  };

  const handleNewNoteClick = () => {
    setSelectedNote(null);
    setIsEditing(false);
    setShowEditor(true);
    setNewNote({
      title: "",
      content: "",
      color: "#ffeb99"
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewNote((prev) => ({ ...prev, [name]: value }));
  };

  const handleCloseEditor = () => {
    setShowEditor(false);
    setIsEditing(false);
  };

  const filteredNotes = notes.filter(
    (note) =>
      note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      note.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container fluid>
      <div className="page-content">
        <div className="notes-app">
          <div className="notes-header">
            <h1>Notas Rápidas</h1>
            <div className="notes-controls">
              <input
                type="text"
                placeholder="Buscar notas..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
              <button className="new-note-btn" onClick={handleNewNoteClick}>
                + Nueva Nota
              </button>
            </div>
          </div>

          <div className="notes-grid">
            {filteredNotes.map((note) => (
              <div
                key={note.id}
                className="note-card"
                style={{ backgroundColor: note.color }}
                onClick={() => handleNoteClick(note)}
              >
                <div className="note-card-header">
                  <h3>{note.title}</h3>
                  <button
                    className="edit-note-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick(note);
                    }}
                  >
                    ✏️
                  </button>
                </div>
                <p>
                  {note.content.length > 100
                    ? `${note.content.substring(0, 100)}...`
                    : note.content}
                </p>
                <span className="note-date">{note.date}</span>
              </div>
            ))}
          </div>

          {showEditor && (
            <div className="editor-modal">
              <div
                className="editor-container"
                style={{ backgroundColor: newNote.color }}
              >
                <div className="editor-header">
                  <div className="color-picker">
                    {[
                      "#ffeb99",
                      "#c2f0c2",
                      "#99ccff",
                      "#ffb3b3",
                      "#e6ccff"
                    ].map((color) => (
                      <button
                        key={color}
                        className="color-option"
                        style={{ backgroundColor: color }}
                        onClick={() =>
                          setNewNote((prev) => ({ ...prev, color }))
                        }
                      />
                    ))}
                  </div>
                  <button className="close-editor" onClick={handleCloseEditor}>
                    ×
                  </button>
                </div>
                <input
                  type="text"
                  name="title"
                  placeholder="Título"
                  value={newNote.title}
                  onChange={handleInputChange}
                  className="note-title-input"
                />
                <textarea
                  name="content"
                  placeholder="Escribe tu nota aquí..."
                  value={newNote.content}
                  onChange={handleInputChange}
                  className="note-content-input"
                />
                <div className="editor-actions">
                  {isEditing && (
                    <button className="delete-btn" onClick={handleDeleteNote}>
                      Eliminar
                    </button>
                  )}
                  <button className="save-btn" onClick={handleSaveNote}>
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default NotesApp;
