import React from 'react'
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import '../Casos.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ListaCasos = ({ params }) => {
  const history = useHistory();

  const theme = useSelector((state) => state.theme.theme) || 'light'
  

  return (
    <div style={{ padding: "0rem 2rem" }}>
      <Table
        responsive
        hover
        className={`font-size-13 text-center ${
          theme === "dark" ? "table-dark" : "table-light"
        }`}
      >
        <thead>
          <tr>
            <th style={{ color: theme === "dark" && "#c3cbe4" }}>Caso N°</th>
            <th style={{ color: theme === "dark" && "#c3cbe4" }}>
              Descripción
            </th>
            <th style={{ color: theme === "dark" && "#c3cbe4" }}>Agente(s)</th>
            <th style={{ color: theme === "dark" && "#c3cbe4" }}>Cliente(s)</th>
            <th style={{ color: theme === "dark" && "#c3cbe4" }}>
              Tipo servicio
            </th>
            <th style={{ color: theme === "dark" && "#c3cbe4" }}>Creación</th>
            <th style={{ color: theme === "dark" && "#c3cbe4" }}>Estado</th>
            <th style={{ color: theme === "dark" && "#c3cbe4" }}>
              Finalización
            </th>
          </tr>
        </thead>
        <tbody className={` ${theme === "dark" ? "" : "table-light"}`}>
          {params.results &&
            params.results.map((caso) => (
              <tr
                key={caso.id}
                style={{ cursor: "pointer" }}
                onClick={() => history.push(`/casos/update/${caso.id}`)}
              >
                <td
                  style={{
                    backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                    height: "60px",
                  }}
                >
                  {caso.name}
                </td>
                {caso.descripcion ? (
                  <td
                    style={{
                      backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                    }}
                  >
                    {caso.descripcion}
                  </td>
                ) : (
                  <td
                    className="sindata_"
                    style={{
                      backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                      color: theme === "dark" ? "#c3cbdc66" : "#00000066",
                    }}
                  >
                    Sin descripción
                  </td>
                )}
                {caso.contacto.map((agente) => (
                  <td
                    key={agente[0]}
                    style={{
                      backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                    }}
                  >
                    {agente[0]}
                  </td>
                ))}
                {caso.cliente.length > 0 ? (
                  caso.cliente.map((cliente) => (
                    <td
                      key={cliente[0]}
                      style={{
                        backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                      }}
                    >
                      {cliente[0]}
                    </td>
                  ))
                ) : (
                  <td
                    style={{
                      backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                      color: theme === "dark" ? "#c3cbdc66" : "#00000066",
                    }}
                  >
                    Sin cliente asignado
                  </td>
                )}
                {caso.tipo_servicio ? (
                  <td
                    style={{
                      backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                    }}
                  >
                    {caso.tipo_servicio}
                  </td>
                ) : (
                  <td
                    style={{
                      backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                      color: theme === "dark" ? "#c3cbdc66" : "#00000066",
                    }}
                  >
                    Sin tipo de servicio
                  </td>
                )}
                <td
                  style={{
                    backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                  }}
                >
                  {caso.created_date.split(" ")[0]}
                </td>
                <td
                  style={{
                    backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                    color:
                      caso.estado === "FINALIZADO"
                        ? "#09cc09"
                        : "" || caso.estado === "EN_PROCESO"
                        ? "#915eef"
                        : "" || caso.estado === "ASIGNADO"
                        ? "#49b0ea"
                        : "" || caso.estado === "PENDIENTE"
                        ? "#bf1b21"
                        : "" || caso.estado === "SIN_ASIGNAR"
                        ? "#e16b20"
                        : "" || caso.estado === "EN_PAUSA"
                        ? "#3272b1"
                        : "",
                  }}
                >
                  <span
                    style={{
                      borderRadius: "6px",
                      padding: "2px 6px",
                      fontSize: ".72rem",
                      backgroundColor:
                        caso.estado === "FINALIZADO"
                          ? "#09ce0933"
                          : "" || caso.estado === "EN_PROCESO"
                          ? "#915eef33"
                          : "" || caso.estado === "ASIGNADO"
                          ? "#49b0ea4d"
                          : "" || caso.estado === "PENDIENTE"
                          ? "#bf1b2133"
                          : "" || caso.estado === "SIN_ASIGNAR"
                          ? "#e16b2033"
                          : "" || caso.estado === "EN_PAUSA"
                          ? "#3272b133"
                          : "",
                    }}
                  >
                    {caso.estado}
                  </span>
                </td>
                {caso.estado === "FINALIZADO" ? (
                  <td
                    style={{
                      backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                    }}
                  >
                    {caso.fecha_finalizacion.split(" ")[0]}
                  </td>
                ) : (
                  <td
                    style={{
                      backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                    }}
                  >
                    En proceso
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ListaCasos