import React, { useEffect, useState } from 'react'
import { Card, Row, Nav, NavItem, NavLink } from 'reactstrap'
import "./DashboardStyles.css"
import whatsapp from "../../../assets/images/whatsapp.png"
import classnames from 'classnames'
import { getContactAgain } from '../../../helpers/backend'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ModalManagementDetails from '../../Whatsapp/components/ModalManagementDetails'
import { useSelector } from 'react-redux'

export const SideComponent = () => {
  const history = useHistory()
  const [managementFinished, setManagementFinished] = useState({})
  const [modalFinishedDetails, setModalFinishedDetails] = useState(false)
  const [data, setData] = useState({})
  const [activeTab, setActiveTab] = useState('1')

  // Obtenemos el tema global desde Redux
  const theme = useSelector((state) => state.theme.theme) || 'light'

  const getData = async (selectedOption) => {
    const response = await getContactAgain(selectedOption)
    setData(response.data)
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  useEffect(() => {
    getData("hoy")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log(data)

  return (
    <>
      {modalFinishedDetails && (
        <ModalManagementDetails
          isOpen={modalFinishedDetails}
          onCloseClick={() => setModalFinishedDetails(false)}
          GestionDetallada={managementFinished}
        />
      )}
      <Row>
        <Card style={{ borderTop: '5px solid pink' }}>
          <div>
            <div>
              <p style={{fontWeight:"600"}} className={`font-size-18 mt-1 ${theme === "dark" ? "text-dark" : ""}`}>
                Contactar
              </p>
              <Nav tabs justified role="tablist" className='mt-1'>
                <NavItem>
                  <NavLink
                    to="#"
                    className={`p-2 ${classnames({ active: activeTab === '1' })}`}
                    onClick={() => { toggle('1'); getData("hoy") }}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="d-sm-block font-size-13">Hoy</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={`p-2 ${classnames({ active: activeTab === '2' })}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => { toggle('2'); getData("manana") }}
                  >
                    <span className="d-sm-block font-size-13">Mañana</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={`p-2 ${classnames({ active: activeTab === '3' })}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => { toggle('3'); getData("semana") }}
                  >
                    <span className="d-sm-block font-size-13">Semana</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div style={{backgroundColor:theme === 'dark' && 'transparent'}} className='management-containerD'>
              {activeTab === '1' && Array.isArray(data) && data.map((item, index) => (
                <div key={index} className='card-conversacionD mt-1'>
                  <div className="chat-itemD" onClick={() => { setManagementFinished(item); setModalFinishedDetails(true) }}>
                    <div className="chat-itemD__info">
                      <div className="chat-itemD__date mt-1 me-2">
                        Fecha: {item.fecha_proximo_contacto}
                      </div>
                      <div className="chat-itemD__name mt-1">{item.contacto}</div>
                      <div className="chat-itemD__description">
                        <b>Agente: </b>{item.user}
                      </div>
                      <div
                        className="chat-itemD__button"
                        onClick={() => {
                          window.focus()
                          history.push({
                            pathname: '/chat',
                            state: {
                              selectedChatId: item.conversation,
                              contactId: item.contacto_id,
                              type: item.type
                            }
                          })
                        }}
                      >
                        <img className="chat-item__img" src={whatsapp} alt="Avatar" />
                      </div>
                      <div className="chat-itemD__description">
                        <b>Hora de Contacto: </b>{item.hora_proximo_contacto}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {activeTab === '2' && Array.isArray(data) && data.map((item, index) => (
                <div key={index} className='card-conversacionD mt-1'>
                  <div className="chat-itemD" onClick={() => { setManagementFinished(item); setModalFinishedDetails(true) }}>
                    <div className="chat-itemD__info">
                      <div className="chat-itemD__date mt-1 me-2">
                        Fecha: {item.fecha_proximo_contacto}
                      </div>
                      <div className="chat-itemD__name mt-1">{item.contacto}</div>
                      <div className="chat-itemD__description">
                        <b>Agente: </b>{item.user}
                      </div>
                      <div
                        className="chat-itemD__button"
                        onClick={() => {
                          window.focus()
                          history.push({
                            pathname: '/chat',
                            state: {
                              selectedChatId: item.conversation,
                              contactId: item.contacto_id,
                              type: item.type
                            }
                          })
                        }}
                      >
                        <img className="chat-item__img" src={whatsapp} alt="Avatar" />
                      </div>
                      <div className="chat-itemD__description">
                        <b>Hora de Contacto: </b>{item.hora_proximo_contacto}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {activeTab === '3' && Array.isArray(data) && data.map((item, index) => (
                <div key={index} className='card-conversacionD mt-1'>
                  <div className="chat-itemD" onClick={() => { setManagementFinished(item); setModalFinishedDetails(true) }}>
                    <div className="chat-itemD__info">
                      <div className="chat-itemD__date mt-1 me-2">
                        Fecha: {item.fecha_proximo_contacto}
                      </div>
                      <div className="chat-itemD__name mt-1">{item.contacto}</div>
                      <div className="chat-itemD__description">
                        <b>Agente: </b>{item.user}
                      </div>
                      <div
                        className="chat-itemD__button"
                        onClick={() => {
                          window.focus()
                          history.push({
                            pathname: '/chat',
                            state: {
                              selectedChatId: item.conversation,
                              contactId: item.contacto_id,
                              type: item.type
                            }
                          })
                        }}
                      >
                        <img className="chat-item__img" src={whatsapp} alt="Avatar" />
                      </div>
                      <div className="chat-itemD__description">
                        <b>Hora de Contacto: </b>{item.hora_proximo_contacto}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card>
      </Row>
    </>
  )
}
