import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Table,
  Input,
  Button,
  InputGroup,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

// Actions
import { userList, userDelete } from "../../../store/actions";

// Import common components
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Pagination from "../../../components/Common/Pagination";
import Loader from "../../../components/Common/Loader";

// Actions
import { userFilter } from "../../../store/actions";

const UserList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useSelector((state) => state.theme.theme) || "light";

  useEffect(() => {
    dispatch(userList());
  }, [dispatch]);

  const [search, setSearch] = useState("");

  //Delete
  const [confirmText, setConfirmText] = useState(false);
  const [confirmDel, setConfirmDel] = useState("");
  const [cancel, setCancel] = useState(false);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(userFilter(`?search=${search}`));
  };

  const resetSearch = () => {
    dispatch(userFilter(`?search=`));
    setSearch("");
  };

  const handleConfirm = (id) => {
    setConfirmText(true);
    setConfirmDel(id);
  };

  const params = {
    count: props.user.count,
    next: props.user.next,
    previous: props.user.previous,
    results: props.user.results,
  };
  console.log(params.results);
  

  const redirect = () => {
    history.push("/users");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Usuarios</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <div style={{ width:"100%", paddingBottom:"1.5rem"}}>
            <span style={{fontSize:"1.2rem", fontWeight:"600"}}>Usuarios</span>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
            <div>
              <Card className="col-lg-10 mx-auto  mt-3">
                {confirmText ? (
                  <SweetAlert
                    title="¿Estás seguro de que quieres eliminar este Usuario ?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                      dispatch(userDelete(confirmDel));
                    }}
                    onCancel={() => {
                      setConfirmText(false);
                      setCancel(true);
                    }}
                  ></SweetAlert>
                ) : null}

                {cancel ? (
                  <SweetAlert
                    title="Cancelado"
                    warning
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                      setCancel(false);
                    }}
                  >
                    La información de usuario esta a salvo
                  </SweetAlert>
                ) : null}

                {props.userDeleted === true ? (
                  <SweetAlert
                    title="Hecho!"
                    success
                    // timeout={2000}
                    showConfirm={true}
                    onConfirm={() => {
                      setConfirmText(false);
                      dispatch(userList());
                    }}
                  >
                    Se ha eliminado el Usuario con éxito!
                  </SweetAlert>
                ) : null}

                {props.error.detail ? (
                  <SweetAlert
                    title="Lo Sentimos,"
                    error
                    // timeout={2000}
                    showConfirm={true}
                    onConfirm={() => {
                      redirect();
                    }}
                  >
                    {props.error.detail}
                  </SweetAlert>
                ) : null}

                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Link
                        className="btn btn-primary waves-effect waves-light"
                        to="/users/add_user"
                      >
                        Agregar{" "}
                        <span className="bx bx-user-plus fa-lg align-middle"></span>
                      </Link>
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Usuario, nombres, apellidos..."
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="col-lg-10 mx-auto  mt-3">
                {params.count !== 0 ? (
                  <CardBody className="pb-0">
                    <h4 className="card-title">Lista de usuarios</h4>
                    <div className="table-responsive">
                      <Table
                        className={`font-size-13 text-center ${
                          theme === "dark" ? "table-dark" : "table-light"
                        }`}
                        hover
                      >
                        <thead className="">
                          <tr>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              #
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Nombres
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Apellidos
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Username
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Rol
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Acción
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          className={` ${
                            theme === "dark" ? "" : "table-light"
                          }`}
                        >
                          {params.results &&
                            params.results.slice(1).map((user, idx) => (
                              <tr key={idx}>
                                <th
                                  style={{
                                    backgroundColor:
                                      theme === "dark" && "#121e2d",
                                    height: "60px",
                                  }}
                                  scope="row"
                                >
                                  {idx + 1}
                                </th>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" && "#121e2d",
                                  }}
                                >
                                  {user.first_name}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" && "#121e2d",
                                  }}
                                >
                                  {user.last_name}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" && "#121e2d",
                                  }}
                                >
                                  {user.username}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" && "#121e2d",
                                  }}
                                >
                                  <Link to={`/rol/update_rol/${user.id}`}>
                                    {" "}
                                    {user.rol}{" "}
                                  </Link>
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" && "#121e2d",
                                  }}
                                >
                                  <Link to={`/users/${user.id}/edit_user`}>
                                    <span className="fa fa-edit fa-lg align-middle"></span>
                                  </Link>{" "}
                                  <Link
                                    to="#"
                                    onClick={() => handleConfirm(user.id)}
                                  >
                                    <span className="fa fa-trash fa-lg align-middle"></span>
                                  </Link>
                                  <Link
                                    to={`/users/permission_assign/${user.id}`}
                                    id="permission"
                                  >
                                    <span className="fa fa-lock fa-lg align-middle mx-1"></span>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="users/"
                      filterFunction={userFilter}
                    />
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, user, userDeleted } = state.User;
  return { error, loading, user, userDeleted };
};

export default withRouter(connect(mapStateToProps)(UserList));
