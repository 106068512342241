// store/theme/reducer.js
import { TOGGLE_THEME } from "./actionTypes";

const initialState = {
  theme: document.body.getAttribute("data-layout-mode") || "light",
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      const newTheme = state.theme === "light" ? "dark" : "light";
      document.body.setAttribute("data-layout-mode", newTheme);
      return {
        ...state,
        theme: newTheme,
      };
    default:
      return state;
  }
};

export default themeReducer;
