import React from 'react'
import { TbInbox, TbSend, TbFile, TbPencil } from "react-icons/tb";
import { useSelector } from 'react-redux';


const InboxEmail = () => {
  const theme = useSelector((state) => state.theme.theme) || 'light'

  return (
    <section className="contain_inbox_email">
      <div className='content_button_redactar_email'>
        <div className='button_redactar_email'>
          <TbPencil size={22} strokeWidth={2.4}/>
          Redactar
        </div>
      </div>
      <div className={`${theme === 'light' ? "content_inbox_email" : "content_inbox_email_dark"}`}>
        <TbInbox strokeWidth={2.4} size={20} />
        <span className="opcion_inbox_email">Recibidos</span>
      </div>
      <div className={`${theme === 'light' ? "content_inbox_email" : "content_inbox_email_dark"}`}>
        <TbSend size={20} strokeWidth={2.4} />
        <span className="opcion_inbox_email">Enviados</span>
      </div>
      <div className={`${theme === 'light' ? "content_inbox_email" : "content_inbox_email_dark"}`}>
        <TbFile size={20} strokeWidth={2.4} />
        <span className="opcion_inbox_email">Borradores</span>
      </div>
    </section>
  );
}

export default InboxEmail