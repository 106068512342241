import PropTypes from "prop-types";
import React from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser, loginSuccess } from "./store/auth/actions";

// Import Routes all
import { allRoutes, authRoutes, publicRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// Layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import "../src/assets/scss/theme.scss";
import "@fortawesome/fontawesome-svg-core/styles.css";

// const RouteWatcher = () => {
//   const location = useLocation();
//   useEffect(() => {
//     const pathname = location.pathname;
//     const isPrivate = allRoutes.some((route) => route.path === pathname);
//     if (isPrivate) {
//       const currentTabId = sessionStorage.getItem("tabId");
//       localStorage.setItem(
//         "session_active",
//         JSON.stringify({ isActive: true, tabId: currentTabId, isProtected: true })
//       );
//     } else {
//       // Opcionalmente, si la ruta es pública, podrías actualizar con isProtected: false.
//       // const currentTabId = sessionStorage.getItem("tabId");
//       // localStorage.setItem(
//       //   "session_active",
//       //   JSON.stringify({ isActive: true, tabId: currentTabId, isProtected: false })
//       // );
//     }
//   }, [location]);
//   return null;
// };

const App = (props) => {
  // const [isClosing, setIsClosing] = useState(false);

  // // Manejador de unload: se limpia la sesión solo si la pestaña que se cierra es la activa
  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "hidden") {
  //       setIsClosing(true);
  //     }
  //   };

  //   const handleUnload = () => {
  //     const currentTabId = sessionStorage.getItem("tabId");
  //     const sessionActive = JSON.parse(localStorage.getItem("session_active"));
  //     // Solo si la pestaña que se cierra es la activa, se limpian los datos
  //     if (isClosing && sessionActive && sessionActive.tabId === currentTabId) {
  //       localStorage.removeItem("user");
  //       localStorage.removeItem("token");
  //       sessionStorage.clear();
  //       props.logoutUser();
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   window.addEventListener("unload", handleUnload);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //     window.removeEventListener("unload", handleUnload);
  //   };
  // }, [isClosing, props]);

  // // Listener para detectar cambios en session_active desde otras pestañas
  // useEffect(() => {
  //   const handleStorageChange = (event) => {
  //     if (event.key === "session_active") {
  //       const newSession = JSON.parse(event.newValue);
  //       const currentTabId = sessionStorage.getItem("tabId");
  
  //       if (!newSession) return;
  
  //       if (newSession.isActive && newSession.tabId !== currentTabId && newSession.isProtected) {
  //         Swal.fire({
  //           title: "Sesión activa en otra pestaña",
  //           text: "¿Quieres continuar en esta pestaña?",
  //           icon: "warning",
  //           allowOutsideClick: false,
  //           allowEscapeKey: false,
  //           showCancelButton: true,
  //           confirmButtonText: "Sí, continuar aquí",
  //           cancelButtonText: "Cerrar",
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //             // Actualizar session_active para que esta pestaña (A) sea la activa*
  //             localStorage.setItem(
  //               "session_active",
  //               JSON.stringify({ isActive: true, tabId: currentTabId, isProtected: true })
  //             );
  
  //             // Indicarle a la pestaña B que debe ir al login*
  //             localStorage.setItem("force_logout", newSession.tabId);
  //           } else {
  //             // Si elige "Cerrar", redirigimos a /login
  //             window.location.href = "/login";
  //           }
  //         });
  //       }
  //     }
  
  //     // Si esta pestaña (B) recibe la señal de "force_logout", se manda al login sin preguntar
  //     if (event.key === "force_logout") {
  //       const forcedTabId = event.newValue;
  //       const currentTabId = sessionStorage.getItem("tabId");
  
  //       if (forcedTabId === currentTabId) {
  //         console.log("Redirigiendo esta pestaña al login...");
  //         window.location.href = "/login";
  //       }
  //     }
  //   };
  
  //   window.addEventListener("storage", handleStorageChange);
  
  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);
  
  

  // // Asignar un ID único a la pestaña (se ejecuta solo una vez)
  // useEffect(() => {
  //   sessionStorage.setItem("tabId", Math.random().toString(36).substring(2));
  // }, []);

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();

  return (
    <React.Fragment>
      <Router>
        {/* RouteWatcher se encargará de actualizar session_active en cada cambio de ruta */}
        
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {allRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  logoutUser: PropTypes.func.isRequired,
  loginSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, { logoutUser, loginSuccess })(App);
