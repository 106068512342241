import React from 'react';
import './Sidebar.css';

const Sidebar = ({ chats, onCreateChat, onSelectChat }) => {
  return (
    <div className="sidebar">
      <button className="new-chat-button" onClick={onCreateChat}>
        Crear Chat
      </button>
      <div className="chat-history">
        {chats.map((chat) => (
          <div key={chat.id} className="chat-item" onClick={() => onSelectChat(chat.id)}>
            <div className="chat-title">{chat.title}</div>
            <div className="chat-date">{new Date(chat.date).toLocaleDateString()}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;