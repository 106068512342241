import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";
import Select from "react-select";
import {
  getAgent,
  getReportTypifications,
} from "../../helpers/backend";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/l10n/es.js";
import "./Reports.css";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from "recharts";
import { PieChart, Pie, Cell, Tooltip as PieTooltip, Legend as PieLegend } from "recharts";

const ReportTypifications = () => {
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [agente, setAgente] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [detallesModal, setDetallesModal] = useState(false);
  const [gestionesModal, setGestionesModal] = useState([]);
  const [activeTab, setActiveTab] = useState("0");

  const handleSelectChange = (selectedOptions) => {
    const selectedContactData = selectedOptions ? selectedOptions.map(option => option.value).join(',') : '';
    setSelectedAgents(selectedOptions || []);
    setAgente(selectedContactData);
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [start, end] = selectedDates;
      setFechaInicio(start.toISOString().split("T")[0]);
      setFechaFin(end.toISOString().split("T")[0]);
      setSelectedDate(selectedDates);
    }
  };

  const resetSearch = () => {
    setFechaInicio("");
    setFechaFin("");
    setSelectedDate(null);
    setAgente("");
    setSelectedAgents([]);
    setData({});
    setShowWarning(false);
  };

  const getReportData = async () => {
    if (!fechaInicio || !fechaFin) {
      setShowWarning(true);
      return;
    }

    setLoading(true);
    try {
      const response = await getReportTypifications(
        `?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&agents=${agente}`
      );
      setData(response.data);
      setShowWarning(false);
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [agent, setAgent] = useState({});
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data);
  }

  useEffect(() => {
    getReportData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicio, fechaFin]);

  useEffect(() => {
    getAgentData()
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Reporte de Tipificaciones</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="TrueContact"
            breadcrumbItem="Reporte de Tipificaciones"
          />
          {loading ? (
            <Loader />
          ) : (
            <Card>
              <CardBody>
                <Row className="d-flex justify-content-center">
                  <Col lg={12}>
                    <Row>
                      <Col>
                        <Flatpickr
                          className="form-control"
                          placeholder="Fecha"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            locale: "es",
                          }}
                          onChange={handleDateChange}
                          value={selectedDate}
                        />
                      </Col>
                      <Col>
                      <Select
                        className="me-1"
                        options={Array.isArray(agent)
                          ? [
                            { label: "Todos", value: "" },
                            ...agent.map((item) => ({
                            label: item.get_full_name,
                            value: item.id,
                          }))]
                          : []}
                        value={selectedAgents}
                        onChange={handleSelectChange}
                        isMulti={true}
                        placeholder="Agentes"
                        menuPosition="fixed"
                      />
                      </Col>
                      <Col lg={2}>
                        <Button
                          color="warning"
                          className="btn-sm me-1 mt-1"
                          onClick={getReportData}
                        >
                          <b>Consultar</b>
                        </Button>
                        <Button
                          color="danger"
                          className="btn-sm me-1 mt-1"
                          onClick={resetSearch}
                        >
                          <b>Borrar</b>
                        </Button>
                      </Col>
                      {showWarning && (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        Por favor, coloque un rango de fechas. La fecha es requerida.
                      </div>
                    )}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}

          <Nav tabs>
            {data.campañas?.map((campaña, index) => (
              <NavItem key={campaña.id}>
                <NavLink
                  className={classnames({
                    active: activeTab === index.toString(),
                  })}
                  onClick={() => setActiveTab(index.toString())}
                >
                  {campaña.name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <TabContent activeTab={activeTab}>
            {data.campañas?.map((campaña, index) => {
              const calificacionesArray = Object.values(campaña.calificaciones);

              return (
                <TabPane key={campaña.id} tabId={index.toString()}>
                  {/* 🔹 Título y Totales de la Campaña */}
                  <h4>{campaña.name}</h4>
                  <p>
                    <strong>Total:</strong> {campaña.total} Gestiones
                  </p>
                  <p>
                    <strong>Porcentaje de gestiones en la campaña:</strong> {campaña.porcentaje.toFixed(2)}
                    %
                  </p>

                  {/* 🔹 Gráfico Principal: Calificaciones (BarChart) */}
                  {calificacionesArray.length > 0 ? (
                    <div>
                      <h4>Distribución de Resultados</h4>
                      <div className="chart-container d-flex justify-content-center">
                        <BarChart
                          width={600}
                          height={400}
                          data={calificacionesArray}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="total" fill="#8884d8">
                            <LabelList dataKey="porcentaje" position="top" formatter={(value) => `${value.toFixed(1)}%`} />
                          </Bar>
                        </BarChart>
                      </div>
                    </div>
                  ) : (
                    <p>No hay datos para mostrar.</p>
                  )}

                  {/* 🔹 Gráficos por Calificación en 2 Columnas (PieChart) */}
                  <div className="grid-container">
                    {calificacionesArray.map((calificacion) => {
                      const descripcionesArray = calificacion.descripciones;

                      return (
                        <div key={calificacion.id} className="chart-item">
                          <h5 className="text-muted">{calificacion.name}</h5>

                          {descripcionesArray.length > 0 ? (
                            <PieChart width={600} height={350}>
                              <Pie
                                data={descripcionesArray}
                                dataKey="porcentaje"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                label={({ name, porcentaje }) =>
                                  `${porcentaje.toFixed(1)}%`
                                }
                              >
                                {descripcionesArray.map((entry, i) => (
                                  <Cell
                                    key={`cell-desc-${i}`}
                                    fill={
                                      [
                                        "#FF6384",
                                        "#36A2EB",
                                        "#FFCE56",
                                        "#4BC0C0",
                                      ][i % 4]
                                    }
                                  />
                                ))}
                              </Pie>
                              <PieTooltip />
                              <PieLegend
                                formatter={(value, entry) => {
                                  const descripcion = descripcionesArray.find(
                                    (d) => d.name === value
                                  );
                                  return `${value} (${descripcion?.total})`;
                                }}
                              />
                            </PieChart>
                          ) : (
                            <p>No hay Calificacion para este resultado.</p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </TabPane>
              );
            })}
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportTypifications;