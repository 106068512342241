import { useState, useEffect } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input } from "reactstrap";
import "./SessionTimer.css";
import { postEndPause, postStartPause } from "../../../helpers/backend";
import { TbPlayerPauseFilled, TbPlayerPlayFilled  } from "react-icons/tb";


const SessionTimer = () => {
  const getUser = () => JSON.parse(localStorage.getItem("user")); // Obtener usuario
  const [user, setUser] = useState(getUser());
  const [time, setTime] = useState(() => {
    const savedTime = localStorage.getItem("sessionTime");
    return savedTime ? parseInt(savedTime, 10) : 0;
  });

  const [isActive, setIsActive] = useState(user?.status === "CONECTADO");
  const [showAlert, setShowAlert] = useState(false);
  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");

  // Guardar el tiempo en localStorage para que persista
  useEffect(() => {
    localStorage.setItem("sessionTime", time);
  }, [time]);

  // Manejar el cronómetro
  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive]);

  // Monitorear cambios en el usuario y reiniciar tiempo si se desconecta o cambia de usuario
  useEffect(() => {
    const handleStorageChange = () => {
      const newUser = getUser();

      if (!newUser || newUser?.status === "DESCONECTADO") {
        setTime(0); // Reiniciar tiempo solo si el usuario se desconecta
        localStorage.removeItem("sessionTime");
      }

      // Si el usuario cambió, reiniciar el cronómetro
      if (newUser?.id !== user?.id) {
        setTime(0);
        localStorage.setItem("sessionTime", "0");
      }

      setUser(newUser);
      setIsActive(newUser?.status === "CONECTADO");
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [user]);

  // Manejar pausa
  const handlePause = () => setShowAlert(true);

  // Confirmar pausa
  const confirmPause = async () => {
    setIsActive(false);
    setShowAlert(false);

    const updatedUser = { ...user, status: "PAUSA" };
    localStorage.setItem("user", JSON.stringify(updatedUser));
    setUser(updatedUser); // Actualiza el estado para cambiar el icono automáticamente

    await postStartPause({ reason, comment });
  };

  // Reanudar sesión
  const handleResume = async () => {
    setIsActive(true);

    const updatedUser = { ...user, status: "CONECTADO" };
    localStorage.setItem("user", JSON.stringify(updatedUser));
    setUser(updatedUser); // Actualiza el estado para cambiar el icono automáticamente

    await postEndPause();
  };

  // Cancelar pausa
  const cancelPause = () => setShowAlert(false);

  // Formatear tiempo
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="session-timer">
      {user?.status === "CONECTADO" && (
        <button className="pause-button" onClick={handlePause}>
          <TbPlayerPauseFilled size={24}/>
        </button>
      )}
      {user?.status === "PAUSA" && (
        <button className="play-button" onClick={handleResume}>
          <TbPlayerPlayFilled size={22} />
        </button>
      )}

      {/* SweetAlert para la pausa */}
      <SweetAlert
        show={showAlert}
        title="Pausar"
        custom
        showCancel
        confirmBtnText="Aceptar"
        cancelBtnText="Cancelar"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="gray"
        onConfirm={confirmPause}
        onCancel={cancelPause}
      >
        <Input
          type="select"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          style={{ width: "100%", marginBottom: "10px" }}
        >
          <option value="">Selecciona una razón</option>
          <option value="Almuerzo">Almuerzo</option>
          <option value="Reunión interna">Pausa Activa</option>
          <option value="Auditoría">Auditoría</option>
          <option value="Capacitación">Capacitación</option>
          <option value="Soporte técnico">Soporte técnico</option>
          <option value="Smile">Smile</option>
          <option value="Asunto personal">Asunto personal</option>

        </Input>

        <Input
          type="textarea"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Ingresa un comentario..."
          style={{ width: "100%", height: "100px", marginBottom: "10px" }}
        />
      </SweetAlert>
    </div>
  );
};

export default SessionTimer;
