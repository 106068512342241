import React, { useEffect, useState, useCallback } from "react";
import MetaTags from "react-meta-tags";
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Form,
  Button,
  CardTitle,
  Input,
  FormFeedback,
  
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";

import { casosCreate, casosList } from "../../store/actions";
import { getAgent, 
  getGrupoContactoFilterPaginado, 
  getContactsFilter, getGrupoContactoFilter,getContactsList } from "../../helpers/backend";
import ContactsSelect from "../../components/ContactsSelect";
import PaginationTest from '../../components/PaginationTest';
const CasosCreate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [asignarActivado, setAsignarActivado] = useState(true);
  const [selectedContact, setSelectedContact] = useState(null);
   const [selectedGroup, setSelectedGroup] = useState('');
  const [contactsFull, setContactsFull] = useState([]); //ContactaosFull 
   const [contactGroup, setContactGroup] = useState([]);
  const [contacts, setContacts] = useState([]);
    const [next, setNext] = useState([]);
      const [triggerPagination, setTriggerPagination] = useState(false);	
      const [fechacalendario, setFechaAsignada] = useState(null);
      const [horaEntrada, setHoraEntrada] = useState(null);

      const [horaSalida, setHoraSalida] = useState(null);
    
      let lapso_hora_entrada = "";
      let lapso_hora_salida = "";
      const onChangeFecha = (e) => {
        setFechaAsignada(e.target.value);
      };
    
      
  const onChangeHoraEntrada = (e) => {
    if (e && e.target && e.target.value) {
      setHoraEntrada(e.target.value);
    }
  };

  let horaEntraRec = horaEntrada?.toString().slice(0, 5);
  lapso_hora_entrada = horaEntraRec;

  const onChangeHoraSalida = (e) => {
    if (e && e.target && e.target.value) {
      setHoraSalida(e.target.value);
    }
  };

  let horaSalidaRec = horaSalida?.toString().slice(0, 5);
  lapso_hora_salida = horaSalidaRec;

const getContactData = async () => {
    const response = await getContactsList();
    
    setContacts(response.data.results);
    setNext(response.data.next);
  };
   
     
     const getContactGroup = async () => {
       try {
         const response = await getGrupoContactoFilter("FullList/");
         setContactGroup(response.data);
       } catch (error) {
         console.error('Error fetching contact group data', error);
       }
     };

       const getContactDataFIltro = useCallback(async (params, selectedGroup) => {
         try {
           const response = await getGrupoContactoFilterPaginado(params);
           console.log("Respuesta de filterFunction:", response);
       
           if (response && response.data) {
             const newContacts = response.data.results;
       
             // Actualiza contactos generales y paginación
             setContacts(newContacts);
             setNext(response.data.next);
             setTriggerPagination(false);
       
             // Si es el mismo grupo seleccionado, agrega solo los contactos nuevos
             setContactsFull((prevContacts) => [
               ...prevContacts, // Mantén los contactos actuales
               ...newContacts.filter(
                 (contact) => !prevContacts.some((c) => c.id === contact.id) // Evita duplicados
               ),
             ]);
           }
         } catch (error) {
           console.error("Error en filterFunction:", error);
         }
       }, []);
       useEffect(() => {
        if (selectedGroup ==="") {
     
          getContactData();
        }else{
          const changeUrl = `${selectedGroup}/contactos/`;
          getContactDataFIltro({ changeUrl });
        }
        
         getContactGroup();
       
       }, [selectedGroup, getContactDataFIltro]);
  const toggleAsignar = () => {
    setAsignarActivado(!asignarActivado);
  };
  const handleFotosChange = (fotos) => {
    const image = [];
  
    Array.from(fotos).forEach((foto) => {
      var reader = new FileReader();
      reader.readAsDataURL(foto);
      reader.onload = function () {
        var fotoBase64 = [];
        var base64 = reader.result;
        fotoBase64 = base64.split(",");
        image.push({ image: fotoBase64[1] });
  
        if (image.length === fotos.length) {
          setFormData({
            ...formData,
            images: image
          });
        }
      };
    });
  };

  const  [agent, setAgent] = useState ({});
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data)
  }
  useEffect(() => {
   
    getAgentData();

 },[dispatch]);

  const [formData, setFormData] = useState({
    tipo_servicio: "",
    tipo_visita: "",
    observaciones: "",
    descripcion: "",
    contacto: [],
    cliente: selectedContact,
    direccion_1: "", 
    direccion_2: "",
    images: [],
    fecha_asignacion: fechacalendario,
    hora_entrada: lapso_hora_entrada,
    hora_salida: lapso_hora_salida
  });
  const { tipo_visita } = formData;
  const opcionesTipoVisita = [
    "PRESENCIAL",
    "GARANTIA",
    "REVISION",
    "ENTREGA",
    "LEVANTAMIENTO DE INFORMACION",
    "MANTENIMIENTO PREVENTIVO",
    "MANTENIMIENTO CORRECTIVO",
    "MENSAJERIA",
    "COMPRAS",
    "PARTNER",
    "COTIZAR EN EL SITIO",
    "INSTALACION",
  ];
  
  console.log(formData)
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      cliente: selectedContact,
      fecha_asignacion: fechacalendario,
      hora_entrada: lapso_hora_entrada,
      hora_salida: lapso_hora_salida
    }));
  }, [selectedContact, fechacalendario, lapso_hora_entrada, lapso_hora_salida]);


  const { tipo_servicio, observaciones, descripcion, } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "tipo_servicio" && value !== "DESPLAZAMIENTO") {
      // Si el tipo de servicio no es DESPLAZAMIENTO, resetea las direcciones
      setFormData((prevFormData) => ({
        ...prevFormData,
        direccion_1: "",
        direccion_2: "",
      }));
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(casosCreate(formData));
  };

  const redirect = () => {
    dispatch(casosList())
    history.push("/casos");
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags
        contacts = {contacts}
        next = {next}
        triggerPagination = {triggerPagination}
        agent = {agent}
        
        >
          <title>TrueContact | Casos</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem="Casos" />
          {props.loading ? (
            <Loader />
          ) : (
            <div className="d-flex justify-content-center">
              {props.casosCreated.id ? (
                <SweetAlert
                  title="¡Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha agregado <b>{props.casosCreated.nombre}</b> correctamente.
                </SweetAlert>
              ) : null}

              <Card
                style={{
                  width: "60rem",
                }}
              >
                <CardTitle className="ps-3 pt-3">
                  <h2 className="font-size-20">Casos</h2>
                </CardTitle>
                <CardBody>
                  <Form onSubmit={onSubmit} className="form-control">
                    <Row className="justify-content-center">
                      <Col lg={10}>
                        <Row className="mt-3">
                          <Col>
                            <Label for="tipo_servicio">Tipo de Servicio</Label>
                            <select
                              className="form-select"
                              id="tipo_servicio"
                              name="tipo_servicio"
                              onChange={onChange}
                              value={tipo_servicio}
                              required
                            >
                              <option defaultValue>Seleccione una opción</option>
                              <option value="VENTA">Venta</option>
                              <option value="PRESENCIAL">Presencial</option>
                              <option value="REMOTO">Remoto</option>
                              <option value="DESPLAZAMIENTO">Desplazamiento</option>
                              <option value="TRABAJO EN CASA">Trabajo en casa</option>
                              <option value="MIXTO">Mixto</option>
                              <option value="OTRO">Otro</option>
                            </select>
                            {props.error && props.error.tipo_servicio ? (
                            <FormFeedback>{props.error.tipo_servicio}</FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={6}>
                              <Label for="cliente">Cliente </Label>
                              {/* <Select
                                id="cliente"
                                name="cliente"
                                options={Array.isArray(contacts)
                                  ? contacts.map((item) => ({
                                      label: item.get_full_name,
                                      value: item.id,
                                    }))
                                  : []}
                                onChange={(selectedOptions) => {
                                  const selectedContactData = selectedOptions.map(
                                    (option) => option.value
                                  );
                                  setFormData({
                                    ...formData,
                                    cliente: selectedContactData,
                                  });
                                }}
                                placeholder={"Seleccione"}
                                isMulti={true}
                                menuPosition="fixed"
                              /> */}
                                  <ContactsSelect
        filterFunction={getContactsFilter}
        paginationComponent={PaginationTest}
        onContactSelect={setSelectedContact}
      />
                              {!formData.cliente && !formData.cliente ? (
                              <FormFeedback>{props.error.cliente}</FormFeedback>
                              ) : null}
                            </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col lg={6}>
                            <Label for="tipo_visita">Tipo de Visita</Label>
                            <select
                      className="form-select"
                      id="tipo_visita"
                      name="tipo_visita"
                      onChange={onChange}
                      value={tipo_visita}
                      required
                    >
                      <option value="">Seleccione una opción</option>
                      {opcionesTipoVisita.map((opcion) => (
                        <option key={opcion} value={opcion}>
                          {opcion}
                        </option>
                      ))}
                    </select>
                    {props.error && props.error.tipo_visita ? (
  <FormFeedback>{props.error.tipo_visita}</FormFeedback>
) : null}
                          </Col>
                          </Row>
                  {tipo_servicio === "DESPLAZAMIENTO" && (
                        <Row className="mt-3">
                          <Col>
                            <Label for="direccion_1">Ubicación</Label>
                            <Input
                              id="direccion_1"
                              name="direccion_1"
                              type="text"
                              onChange={onChange}
                              value={formData.direccion_1}
                              required
                            />
                            {props.error && props.error.direccion_1 ? (
                              <FormFeedback>{props.error.direccion_1}</FormFeedback>
                            ) : null}
                          </Col>
                          <Col>
                            <Label for="direccion_2">Destino</Label>
                            <Input
                              id="direccion_2"
                              name="direccion_2"
                              type="text"
                              onChange={onChange}
                              value={formData.direccion_2}
                              required
                            />
                            {props.error && props.error.direccion_2 ? (
                              <FormFeedback>{props.error.direccion_2}</FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                  )}
                        <Row className="mt-3">
                        <Col>
                            <Label for="descripcion">Descripción</Label>
                            <Input
                              id="descripcion"
                              name="descripcion"
                              type="text"
                              onChange={onChange}
                              value={descripcion}
                              required
                            />
                            {props.error && props.error.descripcion ? (
                              <FormFeedback>{props.error.descripcion}</FormFeedback>
                            ) : null}
                          </Col>
                          <Col>
                          <Label for="fotos">Fotos</Label>
                              <Input
                              type="file"
                              className="form-control"
                              name="fotos"
                              id="fotos"
                              multiple
                              onChange={(e)=> handleFotosChange(e.target.files)}
                              />

                          </Col>       
                        </Row>
                        <Row>
                        <Col lg={12}>
                            <Label for="observaciones">Observaciones</Label>
                            <Input
                              id="observaciones"
                              name="observaciones"
                              type="textarea"
                              onChange={onChange}
                              value={observaciones}
                              required
                            />
                            {props.error && props.error.observaciones ? (
                                <FormFeedback>{props.error.observaciones}</FormFeedback>
                            ) : null}
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col lg={6}>
                            <Label check>
                              <Input
                                type="checkbox"
                                id="asignarCheckbox"
                                checked={asignarActivado}
                                onChange={toggleAsignar}
                              />{" "}
                              ¿Asignar?
                            </Label>
                          </Col>
                        </Row>
                        {asignarActivado && (
                          <>
                          <Row className="mt-3">
                            <Col lg={6}>
                              <Label for="grupos">Grupos</Label>
                              <Input
                                    type="select"
                                    value={selectedGroup}
                                    onChange={(e) => {
                                      setSelectedGroup(e.target.value)
                                      setContactsFull([])
                            
                                    }} // id del grupo seleccionado
                                    className="group-select"
                                  >
                                    <option value="">Todos los grupos</option>
                                    {Array.isArray(contactGroup) && contactGroup.map((item) => (
                                      <option key={item.id} value={item.id}> {/* El value es el id del grupo */}
                                        {item.name} {/* Muestras el nombre del grupo */}
                                      </option>
                                    ))}
                                  </Input>
                            </Col>
                            <Col lg={6}>
                              <Label for="contacto">Agente</Label>
                              <Select
                                id="contacto"
                                name="contacto"
                                options={Array.isArray(contactsFull)
                                  ? contactsFull.map((item) => ({
                                      label: item.get_full_name,
                                      value: item.id,
                                    }))
                                  : []}
                                onChange={(selectedOptions) => {
                                  const selectedContactData = selectedOptions.map(
                                    (option) => option.value
                                  );
                                  setFormData({
                                    ...formData,
                                    contacto: selectedContactData,
                                  });
                                }}
                                placeholder={"Seleccione"}
                                isMulti={true}
                                menuPosition="fixed"
                              />
                              {!formData.contacto && !formData.contacto ? (
                              <FormFeedback>{props.error.contacto}</FormFeedback>
                              ) : null}
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={6} className="mb-3">
          <Label
            for="tecnico_asignado"
            
          >
        Fecha
          </Label>

          <input
            className="form-control d-block"
            placeholder="Fecha"
            onChange={(e) => onChangeFecha(e)}
            type="date"
          />
        </Col>
      </Row>

      <Row className="col-lg-12 justify-content-center ">
        <Col md={6} className="mb-3">
          <Label for="horaentrada" className="col-form-label col-sm-8 d-flex">
           Hora de Entrada
          </Label>

          <Flatpickr
            className="form-control d-block"
            placeholder="Hora de Entrada"
            onInput={onChangeHoraEntrada}
            options={{
              enableTime: true,

              noCalendar: true,

              dateFormat: "H:i K",
              minuteIncrement: "15",
            }}
          />
        </Col>

        <Col md={6} className="mb-3">
          <Label
            for="tecnico_asignado"
            className="col-form-label col-sm-6 d-flex"
          >
         Hora de Salida
          </Label>

          <Flatpickr
            className="form-control d-block"
            placeholder="Hora de Salida"
            onInput={onChangeHoraSalida}
            options={{
              enableTime: true,

              noCalendar: true,

              dateFormat: "H:i K",
              minuteIncrement: "15",
            }}
          />
        </Col>
      </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row className="col-lg-12 justify-content-center mt-4">
                      <Col md={1} className="mx-1">
                        <Link className="btn btn-secondary text-dark" to="/casos">
                          Volver
                        </Link>
                      </Col>
                      <Col md={1} className="mx-1">
                        <Button color="primary" type="submit">
                          Enviar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, casosCreated } = state.Casos;
  return { error, loading, casosCreated };
};

export default withRouter(connect(mapStateToProps)(CasosCreate));
