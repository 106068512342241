import { connect, useSelector } from "react-redux";
import './index.css';
import ButtonChat from "../../pages/ChatInterno/ButtonChat";
import PhonePanel from "../PhonePanel";
import { FaAddressBook, FaChalkboardTeacher } from "react-icons/fa";
import  ReactDrawer  from "react-drawer";
import "react-drawer/lib/react-drawer.css";
import ContactDirectory from "../ContactDirectory";
import { useState } from "react";

const SidebarTools = ({ open }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useSelector((state) => state.theme.theme) || 'light';

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <section
      style={{
        backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
        borderLeft:
          theme === "dark" ? "1px solid #162436" : "1px solid #e9ebec",
          transition: "all .3s ease"
      }}
      className={`my-right-sidebar ${open ? "open" : ""}`}
    >
      <div className="content_opcions_menuTools">
        <div>
          <div
            style={{
              position: "fixed",
              top: "60px",
              right: drawerOpen ? "-9px" : "-305px", // Se mueve todo junto
              width: "300px", // Ancho del contenedor para el botón y el Drawer
              height: "100%", // Ocupa toda la altura
              transition: "right 0.3s ease", // Animación suave // Sombra cuando está abierto
              zIndex: "1000",
            }}
          >
            {/* Pestaña Flotante */}
            <div
              onClick={toggleDrawer}
              className="content_button_diretory"
              style={{color: theme === 'dark' ? '#fff' : '#545a6d'}}
            >
              <FaAddressBook style={{ fontSize: "20px" }} />
            </div>

            {/* Drawer Content */}
            <ReactDrawer
              open={drawerOpen}
              onClose={toggleDrawer}
              direction="right"
              className="custom-drawer"
            >
              <ContactDirectory />
            </ReactDrawer>
            <PhonePanel />
          </div>
        </div>
        <div>
          <ButtonChat />
        </div>
        <div>
          <PhonePanel />
        </div>
        <div>
          <FaChalkboardTeacher />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  isOpen: state.Layout.isOpen,
});

export default connect(mapStateToProps)(SidebarTools);