import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Input,
  Button,
  InputGroup,
  Label,
  Table,
} from "reactstrap";
import Select from "react-select";

import TicketCaso from "../../assets/images/ticket-caso.svg";
import TicketCasoDark from "../../assets/images/ticket-casos.svg";
import CasoChecket from "../../assets/images/check.svg";

import {
  IoCheckmarkDoneSharp,
  IoPlaySharp,
  IoPauseSharp,
  IoStopSharp,
} from "react-icons/io5";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect, useSelector } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";
import Pagination from "../../components/Common/Pagination_ansync";
import "./Casos.css";
import {
  getAgent,
  getCasosEstados,
  getCasosEstadosFilter,
  getCasosFilter,
  getCasosList,
  postCasosStatusChange,
} from "../../helpers/backend";

import ModalMateriales from "./ModalMateriales";
import ModalInforme from "./ModalInforme";
import {
  TbLayoutList,
  TbLayoutGrid,
  TbCalendarEvent,
  TbSearch,
  TbChevronLeft,
  TbChevronRight,
  TbLayoutSidebarRightExpand,
  TbLayoutSidebarLeftExpand,
  TbTools,
  TbFileDescription
} from "react-icons/tb";
import ListaCasos from "./components/ListaCasos";
import CalendarCasos from "./components/CalendarCasos";

const Casos = (props) => {
  const history = useHistory();

  const [botonActivo, setBotonActivo] = useState("Todas");
  const [modalMateriales, setModalMateriales] = useState(false);
  const [modalInforme, setModalInforme] = useState(false);
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });
  const [viewCasos, setViewCasos] = useState("card");

  const [agent, setAgent] = useState({});
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data);
  };
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [actionAlert, setActionAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [action, setAction] = useState("");
  const [ticket, setTicket] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [coordenadas, setCoordenadas] = useState("");
  const [ticketId, setTicketId] = useState(null);

  const [casoId, setCasoId] = useState(null);
  const onChange = (e) => {
    setSearch(e.target.value);
  };

  // const BASE_URL = process.env.REACT_APP_API_URL;

  const theme = useSelector((state) => state.theme.theme) || "light";

  const handleViewCasos = (tipo_vista) => {
    setViewCasos(tipo_vista);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let url;
    if (botonActivo === "Todas") {
      url = `?search=${search}`;
    } else {
      url = `casos_por_estado/?estado=${botonActivo}&search=${search}`;
    }
    const response = await getCasosFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const resetSearch = async () => {
    setLoading(true);
    let url;
    if (botonActivo === "Todas") {
      url = `?search=`;
    } else {
      url = `casos/casos/casos_por_estado/?estado=${botonActivo}&search=`;
    }
    const response = await getCasosFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setSearch("");
    setLoading(false);
  };

  const getCasosData = async () => {
    setLoading(true);
    const response = await getCasosList();
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };
  const getCasosPorEstados = async (estado) => {
    setLoading(true);
    const response = await getCasosEstados(estado);
    console.log(response);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
    setBotonActivo(estado);
  };
  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getCasosFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };
  const getFilterCasosData = async (url) => {
    setLoading(true);
    const response = await getCasosEstadosFilter(botonActivo, url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const handleAction = (action, ticket, id) => {
    setAction(action);
    setTicket(ticket);
    setTicketId(id);
    setActionAlert(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setCoordenadas(latitude + " " + longitude);
      });
    } else {
      console.log("La geolocalización no está disponible en este navegador.");
    }
  };

  const handleMateriales = (id) => {
    setCasoId(id);
    setModalMateriales(true);
  };
  const actionSuccess = async () => {
    const response = await postCasosStatusChange(ticketId, {
      accion: action,
      observaciones: observaciones,
      coordenadas: coordenadas,
    });
    if (response.status === 200) {
      setShowAlertSuccess(true);
    } else {
      setErrorAlert(true);
    }
    setActionAlert(false);
  };

  useEffect(() => {
    getCasosData();
    getAgentData();
  }, []);

  function Status({ estado }) {
    const estados = {
      EN_PAUSA: { className: "bg-info", text: "En Pausa" },
      PENDIENTE: { className: "bg-danger", text: "Pendiente" },
      SIN_ASIGNAR: { className: "bg-warning ", text: "Sin Asignar" },
      EN_PROCESO: { background: "#6f42c1", text: "En Proceso" },
      FINALIZADO: { background: "#09cc09", text: "Finalizado" },
      ASIGNADO: { className: "bg-primary ", text: "Asignado" },
    };

    const estadoActual = estados[estado];

    return (
      <div
        className={`badge rounded-pill ${estadoActual.className} font-size-10`}
        style={{ background: estadoActual.background }}
      >
        <strong>{estadoActual.text}</strong>
      </div>
    );
  }
  console.log(params);

  const handleCloseModal = () => {
    setModalMateriales(false);
    setModalInforme(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Casos </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Casos" /> */}
          {modalMateriales ? (
            <ModalMateriales
              modalMateriales={modalMateriales}
              handleCloseModal={handleCloseModal}
              casosId={casoId}
              getCasosData={getCasosData}
              agent={agent}
            />
          ) : null}

          {modalInforme ? (
            <ModalInforme
              modalInforme={modalInforme}
              handleCloseModal={handleCloseModal}
              casosId={casoId}
              getCasosData={getCasosData}
            />
          ) : null}

          {actionAlert ? (
            <SweetAlert
              title={`¿${action} caso ${ticket} ?`}
              warning
              showConfirm={true}
              confirmBtnText="Si"
              showCancel={true}
              cancelBtnBsStyle="danger"
              cancelBtnText="No"
              onCancel={() => setActionAlert(false)}
              onConfirm={() => actionSuccess()}
            >
              <Label>Observaciones</Label>
              <Input
                type="textarea"
                id="observaciones"
                name="observaciones"
                onChange={(e) => setObservaciones(e.target.value)}
              />
            </SweetAlert>
          ) : null}
          {showAlertSuccess ? (
            <SweetAlert
              title="Hecho!"
              success
              showConfirm={false}
              timeout={2000}
              onConfirm={() => {
                getCasosData();
                setShowAlertSuccess(false);
              }}
            ></SweetAlert>
          ) : null}
          {errorAlert ? (
            <SweetAlert
              title="Error!"
              danger
              showConfirm={false}
              timeout={2000}
              onConfirm={() => {
                getCasosData();
                setErrorAlert(false);
              }}
            ></SweetAlert>
          ) : null}
          {loading ? (
            <Loader />
          ) : (
            <div>
              <CardBody className="">
                <Row className="d-flex justify-content-between mb-4">
                  {viewCasos !== "calendar" && (
                    <>
                      <Col>
                        <Form
                          className="content_search_input"
                          onSubmit={onSubmit}
                        >
                          <div className="">
                            <input
                              type="text"
                              className={`${
                                theme === "dark"
                                  ? "input_search_casos_dark"
                                  : "input_search_casos"
                              }`}
                              name="search"
                              placeholder="Caso, nombre, descripcion..."
                              value={search}
                              onChange={onChange}
                            />

                            <button className="button_x_casos">
                              <TbSearch size={18} />
                            </button>
                          </div>
                        </Form>
                      </Col>

                      <Col
                        md={6}
                        className="d-flex gap-2 justify-content-center mb-4 flex-wrap"
                        key={botonActivo}
                      >
                        <button
                          key="todas"
                          className={`${
                            theme === "dark"
                              ? "button_estado_caso_dark"
                              : "button_estado_caso"
                          } ${
                            botonActivo === "Todas" && "button_todas_active"
                          }`}
                          onClick={() => {
                            getCasosData();
                            setBotonActivo("Todas");
                          }}
                        >
                          Todas
                        </button>
                        <button
                          key="asignado"
                          className={`${
                            theme === "dark"
                              ? "button_estado_caso_dark"
                              : "button_estado_caso"
                          } ${
                            botonActivo === "ASIGNADO" &&
                            "button_asignado_active"
                          }`}
                          onClick={() => getCasosPorEstados("ASIGNADO")}
                        >
                          Asignado
                        </button>
                        <button
                          key="sin_asignar"
                          className={`${
                            theme === "dark"
                              ? "button_estado_caso_dark"
                              : "button_estado_caso"
                          } ${
                            botonActivo === "SIN_ASIGNAR" &&
                            "button_sinasignar_active"
                          }`}
                          onClick={() => getCasosPorEstados("SIN_ASIGNAR")}
                        >
                          Sin Asignar
                        </button>
                        <button
                          key="pendiente"
                          className={`${
                            theme === "dark"
                              ? "button_estado_caso_dark"
                              : "button_estado_caso"
                          } ${
                            botonActivo === "PENDIENTE" &&
                            "button_pendiente_active"
                          }`}
                          onClick={() => getCasosPorEstados("PENDIENTE")}
                        >
                          Pendiente
                        </button>
                        <button
                          key="en_pausa"
                          className={`${
                            theme === "dark"
                              ? "button_estado_caso_dark"
                              : "button_estado_caso"
                          } ${
                            botonActivo === "EN_PAUSA" &&
                            "button_enpausa_active"
                          }`}
                          onClick={() => getCasosPorEstados("EN_PAUSA")}
                        >
                          En Pausa
                        </button>
                        <button
                          key="en_proceso"
                          className={`${
                            theme === "dark"
                              ? "button_estado_caso_dark"
                              : "button_estado_caso"
                          } ${
                            botonActivo === "EN_PROCESO" &&
                            "button_enproceso_active"
                          }`}
                          onClick={() => getCasosPorEstados("EN_PROCESO")}
                        >
                          En Proceso
                        </button>
                        <button
                          key="finalizado"
                          className={`${
                            theme === "dark"
                              ? "button_estado_caso_dark"
                              : "button_estado_caso"
                          } ${
                            botonActivo === "FINALIZADO" &&
                            "button_finalizado_active"
                          }`}
                          onClick={() => getCasosPorEstados("FINALIZADO")}
                        >
                          Finalizado
                        </button>
                      </Col>
                    </>
                  )}
                  {viewCasos === "calendar" && (
                    <>
                      <Col className="font-size-13">
                        <div
                          style={{ maxWidth: "150px", paddingLeft: ".5rem" }}
                        >
                          <Select
                            id="timeRange"
                            name="timeRange"
                            options={[
                              { label: "Día", value: "dia" },
                              { label: "Semana", value: "semana" },
                              { label: "Mes", value: "mes" },
                            ]}
                            defaultValue={{ label: "Día", value: "Dia" }} // Valor por defecto
                            placeholder={"Selecciona un rango"}
                            isMulti={false}
                            menuPosition="fixed"
                            styles={{
                              control: (base) => ({
                                ...base,
                                backgroundColor:
                                  theme === "dark" ? "#121e2d" : "#fff",
                                color: theme === "dark" ? "#c3cbe4" : "#000",
                                borderColor:
                                  theme === "dark" ? "#162436" : "#d2d2d2",
                                cursor: "pointer",
                              }),
                              menu: (base) => ({
                                ...base,
                                backgroundColor:
                                  theme === "dark" ? "#121e2d" : "#fff",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                              }),
                              option: (base, { isFocused, isSelected }) => ({
                                ...base,
                                backgroundColor: isSelected
                                  ? theme === "dark"
                                    ? "#121e2d"
                                    : "#ccc"
                                  : isFocused
                                  ? theme === "dark"
                                    ? "#0e1824"
                                    : "#ddd"
                                  : "transparent",
                                color: theme === "dark" ? "#c3cbe4" : "#000",
                                padding: "10px",
                                cursor: "pointer",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                color: theme === "dark" ? "#c3cbe4" : "#000",
                              }),
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="d-flex gap-2 justify-content-center">
                        <div
                          style={{
                            border:
                              theme === "dark"
                                ? "1px solid #162436"
                                : "1px solid #d2d2d2",
                            color: theme === "dark" ? "#c3cbe4" : "#000",
                            backgroundColor: theme === "dark" && "#121e2d",
                          }}
                          className="button_chevron_fecha"
                        >
                          <TbChevronLeft size={24} strokeWidth={1.5} />
                        </div>
                        <div
                          style={{
                            border:
                              theme === "dark"
                                ? "1px solid #162436"
                                : "1px solid #d2d2d2",
                            backgroundColor: theme === "dark" && "#121e2d",
                          }}
                          className="content_fecha_cass"
                        >
                          <span className="fecha_casos_">Mié., 5 Mar</span>
                          <span
                            style={{
                              color: theme === "dark" ? "#c3cbe4" : "#979797",
                            }}
                            className="hora_fecha_casos"
                          >
                            10:00 - 19:00
                          </span>
                        </div>
                        <div
                          style={{
                            border:
                              theme === "dark"
                                ? "1px solid #162436"
                                : "1px solid #d2d2d2",
                            color: theme === "dark" ? "#c3cbe4" : "#000",
                            backgroundColor: theme === "dark" && "#121e2d",
                          }}
                          className="button_chevron_fecha"
                        >
                          <TbChevronRight size={24} strokeWidth={1.5} />
                        </div>
                      </Col>
                    </>
                  )}
                  <Col>
                    <div className="d-flex gap-2 justify-content-end ">
                      <Link
                        className="btn btn-primary font-size-14 waves-effect waves-light me-2"
                        to="/casos/create"
                      >
                        Agregar
                      </Link>
                      <button
                        className={`
                      ${
                        theme === "dark" ? "button_view_dark" : "button_view"
                      } ${viewCasos === "card" ? "border-primary" : ""}
                      `}
                        onClick={() => {
                          handleViewCasos("card");
                        }}
                      >
                        <TbLayoutGrid
                          className={` ${
                            viewCasos === "card" ? "text-primary" : "text-dark"
                          }`}
                          size={20}
                          strokeWidth={1.9}
                        />
                      </button>

                      <button
                        className={`
                      ${
                        theme === "dark" ? "button_view_dark" : "button_view"
                      } ${viewCasos === "list" ? "border-primary" : ""}
                      `}
                        onClick={() => {
                          handleViewCasos("list");
                        }}
                      >
                        <TbLayoutList
                          className={` ${
                            viewCasos === "list" ? "text-primary" : "text-dark"
                          }`}
                          size={20}
                          strokeWidth={1.9}
                        />
                      </button>

                      <button
                        className={`
                      ${
                        theme === "dark" ? "button_view_dark" : "button_view"
                      } ${viewCasos === "calendar" ? "border-primary" : ""}
                      `}
                        onClick={() => {
                          handleViewCasos("calendar");
                        }}
                      >
                        <TbCalendarEvent
                          className={` ${
                            viewCasos === "calendar"
                              ? "text-primary"
                              : "text-dark"
                          }`}
                          size={20}
                          strokeWidth={1.9}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              {/*BOTONES DE FILTROS*/}

              {viewCasos === "card" && (
                <div className="card-container">
                  {params.results &&
                    params.results.map((casos, idx) => {
                      // const imgUrl =
                      //   casos.images &&
                      //   casos.images[0] &&
                      //   `${BASE_URL.replace("/api", "")}${
                      //     casos.images[0].image
                      //   }`;
                      return (
                        <>
                          <div
                            style={{
                              backgroundColor: theme === "dark" && "#121e2d",
                              border: theme === "dark" && "1px solid #121e2d",
                            }}
                            className={`ticket`}
                          >
                            <div>
                              <div
                                onClick={() =>
                                  history.push(`/casos/update/${casos.id}`)
                                }
                                className="ticket-header"
                                style={{
                                  backgroundColor:
                                    casos.estado === "FINALIZADO"
                                      ? "#09ce0933"
                                      : casos.estado === "EN_PROCESO"
                                      ? "#915eef33"
                                      : casos.estado === "ASIGNADO"
                                      ? "#49b0ea4d"
                                      : casos.estado === "PENDIENTE"
                                      ? "#bf1b2133"
                                      : casos.estado === "SIN_ASIGNAR"
                                      ? "#e16b2033"
                                      : casos.estado === "EN_PAUSA"
                                      ? "#3272b133"
                                      : "",
                                }}
                              >
                                <div className="contain_img_caso">
                                  <div
                                    style={{
                                      backgroundColor:
                                        theme === "dark" && "#0e1824",

                                      border:
                                        theme === "dark" && "1px solid #121e2d",
                                    }}
                                    className="content_img_caso"
                                  >
                                    {/* {imgUrl ? (
                                      <img
                                        className="img_caso"
                                        src={imgUrl}
                                        alt=""
                                      />
                                    ) : ( */}
                                    <img
                                      className="img_caso_ticket"
                                      src={
                                        theme === "dark"
                                          ? TicketCasoDark
                                          : TicketCaso
                                      }
                                      alt=""
                                    />
                                    {/* )} */}
                                  </div>
                                </div>

                                <div className="position-absolute top-0 end-0 me-1">
                                  {casos.estado ? (
                                    <>
                                      <b>
                                        <Status estado={casos.estado} />
                                      </b>{" "}
                                    </>
                                  ) : null}
                                </div>
                              </div>

                              <div
                                onClick={() =>
                                  history.push(`/casos/view/${casos.id}`)
                                }
                                className="ticket-body"
                              >
                                <div
                                  style={{ height: "65px" }}
                                  className="content_name_caso"
                                >
                                  <p
                                    className={`mb-0 name_caso ${
                                      casos.name.startsWith("Desplazamiento")
                                        ? "font-size-13"
                                        : "font-size-18"
                                    }`}
                                  >
                                    {casos.name}{" "}
                                  </p>
                                  {casos.descripcion ? (
                                    <span className="font-size-13 descripcion_casos">
                                      {casos.descripcion}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color:
                                          theme === "dark"
                                            ? "#c3cbdc66"
                                            : "#00000066",
                                      }}
                                      className="font-size-13 descripcion_casos"
                                    >
                                      Sin descripción
                                    </span>
                                  )}
                                </div>

                                {casos.estado === "PENDIENTE" ? (
                                  <Button
                                    className="btn-sm btn-warning mt-1"
                                    onClick={() =>
                                      handleAction(
                                        "INICIAR",
                                        casos.name,
                                        casos.id
                                      )
                                    }
                                  >
                                    <IoPlaySharp /> Iniciar
                                  </Button>
                                ) : null}

                                <div className="content_info_caso_card">
                                  <div className="content_title_info_caso">
                                    {casos.total_duracion ? (
                                      <>
                                        <span className="font-size-12 text-start">
                                          Duración:
                                        </span>
                                      </>
                                    ) : null}
                                    <span className="font-size-12">
                                      Agente:
                                    </span>
                                    <span className="font-size-12">
                                      Cliente:
                                    </span>
                                    <span className="font-size-12 text-start">
                                      Tipo servicio:
                                    </span>
                                  </div>

                                  <div className="content_info_caso">
                                    {casos.total_duracion ? (
                                      <span className="font-size-12">
                                        {casos.total_duracion}
                                      </span>
                                    ) : null}
                                    <span className="font-size-12">
                                      {Array.isArray(casos.contacto)
                                        ? casos.contacto
                                            .map((item) => item[0])
                                            .join(", ")
                                        : []}
                                    </span>
                                    {casos.cliente.length > 0 ? (
                                      <span className="font-size-12">
                                        {Array.isArray(casos.cliente)
                                          ? casos.cliente
                                              .map((item) => item[0])
                                              .join(", ")
                                          : []}
                                      </span>
                                    ) : (
                                      <span className="font-size-12">
                                        Sin cliente asignado
                                      </span>
                                    )}
                                    {casos.tipo_servicio ? (
                                      <span className="font-size-12">
                                        {casos.tipo_servicio}
                                      </span>
                                    ) : (
                                      <span className="font-size-12">
                                        Sin tipo de servicio
                                      </span>
                                    )}
                                  </div>
                                </div>
                                {casos.estado === "EN_PROCESO" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "max-content",
                                      gap: ".5rem",
                                      paddingTop:".5rem"
                                    }}
                                    className="mt-1"
                                  >
                                    <Button
                                      title="Materiales"
                                      color="link"
                                      style={{ padding: "0" }}
                                      className="btn-sm waves-light waves-effect hover-scale"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleMateriales(casos.id);
                                      }}
                                    >
                                      <TbTools style={{color: theme === 'dark' ? 'c3cbe4' : '545a6d'}} size={22}/>
                                    </Button>
                                    <Button
                                      title="Informe"
                                      color="link"
                                      style={{ padding: "0" }}
                                      //  style={{background: "rgb(232, 62, 140)", color: "white"}}
                                      className="btn-sm waves-light waves-effect hover-scale"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setCasoId(casos.id);
                                        setModalInforme(true);
                                      }}
                                    >
                                      <TbFileDescription style={{color: theme === 'dark' ? 'c3cbe4' : '545a6d'}} size={22}/>
                                    </Button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            {casos.estado === "FINALIZADO" && (
                              <div className="content_img_checket">
                                <img
                                  className="img_checket"
                                  src={CasoChecket}
                                  alt=""
                                />
                              </div>
                            )}
                            {/* Botones de accion */}
                            {casos.estado !== "FINALIZADO" ? (
                              <>
                                <div className="ticket-fixed-button">
                                  {casos.estado === "ASIGNADO" ||
                                  casos.estado === "SIN_ASIGNAR" ? (
                                    <div className="content_btn_asignar">
                                      <button
                                        className="btn_asignar"
                                        onClick={() =>
                                          handleAction(
                                            "ACEPTAR",
                                            casos.name,
                                            casos.id
                                          )
                                        }
                                      >
                                        <IoCheckmarkDoneSharp /> Aceptar
                                      </button>
                                    </div>
                                  ) : null}
                                  {casos.estado === "PENDIENTE" ? (
                                    <Button
                                      className="btn-sm btn-warning mt-1"
                                      onClick={() =>
                                        handleAction(
                                          "INICIAR",
                                          casos.name,
                                          casos.id
                                        )
                                      }
                                    >
                                      <IoPlaySharp /> Iniciar
                                    </Button>
                                  ) : null}
                                  {casos.estado === "EN_PROCESO" ? (
                                    <>
                                      <button
                                        className="btn_pausar"
                                        onClick={() =>
                                          handleAction(
                                            "PAUSAR",
                                            casos.name,
                                            casos.id
                                          )
                                        }
                                      >
                                        <IoPauseSharp /> Pausar
                                      </button>
                                      <button
                                        className="btn_finalizar"
                                        onClick={() =>
                                          handleAction(
                                            "FINALIZAR",
                                            casos.name,
                                            casos.id
                                          )
                                        }
                                      >
                                        <IoStopSharp /> Finalizar
                                      </button>
                                    </>
                                  ) : null}
                                  {casos.estado === "EN_PAUSA" ? (
                                    <Button
                                      color="pink"
                                      className="btn-sm bg-pink text-white mt-1"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleAction(
                                          "REANUDAR",
                                          casos.name,
                                          casos.id
                                        );
                                      }}
                                    >
                                      <IoPlaySharp /> Reanudar
                                    </Button>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </>
                      );
                    })}
                </div>
              )}
              {viewCasos === "list" && <ListaCasos params={params} />}
              {viewCasos === "calendar" && <CalendarCasos />}
            </div>
          )}
          {viewCasos !== "calendar" && (
            <div style={{ padding: "1rem 2rem" }}>
              <Pagination
                count={params.count}
                next={params.next}
                previous={params.previous}
                limit={30}
                filter={botonActivo !== "Todas" ? true : null}
                baseUrl={`${
                  botonActivo === "Todas"
                    ? "/casos/casos/"
                    : "/casos/casos/casos_por_estado/?estado=" + botonActivo
                }`}
                filterFunction={
                  botonActivo === "Todas" ? getFilterData : getFilterCasosData
                }
              />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, casos } = state.Casos;
  return { error, loading, casos };
};

export default withRouter(connect(mapStateToProps)(Casos));
