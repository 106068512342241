import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Table,
  Input,
  Button,
  InputGroup,
  Modal,
  Label,
} from "reactstrap";


// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";

//Action
import { calificacionContactoList } from "../../store/actions";
import { calificacionContactoListChange } from "../../store/actions";
import { deleteCalificacionContact, getCalificacionContactoRead, patchCalificacionContactoUpdate } from "../../helpers/backend";
import SweetAlert from "react-bootstrap-sweetalert";


const CalificacionContacto = (props) => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"))
  const groups = String(user.groups);


  const [search, setSearch] = useState("");
  const [calificacionContacto, setCalificacionContacto] = useState("")
  const [calificacionContactoId, setCalificacionContactoId] = useState(null)
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [calificacionContactoDetail, setCalificacionContactoDetail] = useState({})
  const getCalificacionContactoDetailData = async (id) => {
    const response = await getCalificacionContactoRead(id);
    const data = response.data;
    setCalificacionContactoDetail(data);
    setCalificacionContactoId(id)
  };
  useEffect(() => {
    dispatch(calificacionContactoList());
  }, [dispatch]);

  useEffect(() => {
    if (calificacionContactoDetail.name) {
      setCalificacionContacto(calificacionContactoDetail.name);
    }
  }, [calificacionContactoDetail]);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(calificacionContactoListChange(`?search=${search}`));
  };

  const onUpdateCalificacionContacto = () => {
    patchCalificacionContactoUpdate(calificacionContactoId, {
      name: calificacionContacto,
    })
    setShow(false)
    setShowAlert(true)
  }

  const resetSearch = () => {
    dispatch(calificacionContactoListChange(`?search=`));
    setSearch("");
  };

  const params = {
    count: props.calificacionContacto.count,
    next: props.calificacionContacto.next,
    previous: props.calificacionContacto.previous,
    results: props.calificacionContacto.results,
  };

  const theme = useSelector((state) => state.theme.theme) || 'light'

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Resultados </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <div style={{ width: "100%", paddingBottom: "1.5rem" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>
              Resultados
            </span>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
            <div>
              <Card className="col-lg-10 mx-auto  mt-3">
                <Modal
                  isOpen={show}
                  centered={true}
                  toggle={() => setShow(false)}
                >
                  <div className="modal-header">
                    <h3 className="modal-title mt-2">Actualizar Resultados</h3>
                    <button
                      type="button"
                      onClick={() => {
                        setShow(false);
                      }}
                      className="btn btn-lg"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Row className="col-lg-12 justify-content-center">
                      <Col lg={10} className="mb-3">
                        <Label for="name" className="form-label">
                          Nombre
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="name"
                          value={calificacionContacto}
                          onChange={(e) =>
                            setCalificacionContacto(e.target.value)
                          }
                        />
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary text-dark"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        {" "}
                        Atras
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => onUpdateCalificacionContacto()}
                      >
                        Actualizar
                      </button>
                    </div>
                  </div>
                </Modal>
                {showAlert ? (
                  <SweetAlert
                    title="Hecho!"
                    success
                    timeout={2000}
                    showConfirm={false}
                    onConfirm={() => {
                      dispatch(calificacionContactoList());
                      setShowAlert(false);
                    }}
                  >
                    Se ha Actualizado con éxito
                  </SweetAlert>
                ) : null}
                <CardBody>
                  <Row>
                    <Col md={7}>
                      {groups.includes("SuperAdmin") ||
                      groups.includes("Administrador") ||
                      groups.includes("Supervisor") ||
                      groups.includes("Operador") ||
                      groups.includes(
                        "Crear y modificar CalificacionContacto"
                      ) ? (
                        <Link
                          className="btn btn-primary waves-effect waves-light"
                          to="/calificacionContacto/add_calificacion"
                        >
                          Agregar <span className=""></span>
                        </Link>
                      ) : null}
                      <Link
                        className="btn btn-success waves-effect waves-light ms-1"
                        to="/campaign"
                      >
                        Volver
                        <span className=""></span>
                      </Link>
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Buscar"
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="col-lg-10 mx-auto  mt-3">
                {params.count !== 0 ? (
                  <CardBody>
                    <h4 className="card-title">Resultados del Contacto</h4>
                    <div className="table-responsive">
                      <Table
                        responsive
                        hover
                        className={`font-size-13 text-center ${
                          theme === "dark" ? "table-dark" : "table-light"
                        }`}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              #
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Campaña
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Nombre
                            </th>
                            {groups.includes("SuperAdmin") ||
                            groups.includes("Administrador") ||
                            groups.includes("Supervisor") ||
                            groups.includes("Operador") ||
                            groups.includes(
                              "Crear y modificar CalificacionContacto"
                            ) ? (
                              <th
                                style={{ color: theme === "dark" && "#c3cbe4" }}
                              >
                                Acción
                              </th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {params.results &&
                            params.results.map((calificacionContacto, idx) => (
                              <tr key={idx}>
                                <th
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                    height: "60px",
                                  }}
                                  scope="row"
                                >
                                  {idx + 1}
                                </th>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {calificacionContacto.campaign}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {calificacionContacto.name}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {groups.includes("SuperAdmin") ||
                                  groups.includes("Administrador") ||
                                  groups.includes("Supervisor") ||
                                  groups.includes("Operador") ||
                                  groups.includes(
                                    "Crear y modificar CalificacionContacto"
                                  ) ? (
                                    <>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          getCalificacionContactoDetailData(
                                            calificacionContacto.id
                                          );
                                          setShow(true);
                                        }}
                                      >
                                        <span className="bx bx-edit bx-sm align-middle"></span>
                                      </Link>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          deleteCalificacionContact(
                                            calificacionContacto.id
                                          );
                                          dispatch(calificacionContactoList());
                                        }}
                                      >
                                        <span className="bx bx-trash bx-sm align-middle"></span>
                                      </Link>
                                    </>
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>

                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="contact/calificacionContacto/"
                      filterFunction={calificacionContactoListChange}
                    />
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, calificacionContacto } = state.CalificacionContacto;
  return { error, loading, calificacionContacto };
};

export default withRouter(connect(mapStateToProps)(CalificacionContacto));