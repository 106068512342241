import React from 'react'

const ChatInterno = ({children}) => {
  return (
    <section  className='bandeja_chat'>
      {children}
    </section>
  )
}

export default ChatInterno