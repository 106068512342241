/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getReportSupervisor } from '../../../helpers/backend';
import { TbChevronDown,TbChevronRight ,TbChevronLeft } from "react-icons/tb";
import ImageAgente from '../../../assets/images/user-circle-svgrepo-com.svg'
import ImageAgenteWhite from '../../../assets/images/user-circle-svgrepo-com-white.svg'

const CalendarCasos = () => {
  const [agentes, setAgentes] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());  
  
    const theme = useSelector((state) => state.theme.theme) || 'light'
  
    const getReportData = async () => { 
      const response = await getReportSupervisor();  
      setAgentes(response.data.agentes);  
      console.log(response);
      
    };
    
    useEffect(() => {  
      getReportData();  
    }, [])

    const getDaysInMonth = (year, month) => {
      return new Date(year, month + 1, 0).getDate();
    };
  
    const generateCalendar = () => {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const daysInMonth = getDaysInMonth(year, month);
  
      const firstDay = new Date(year, month, 1).getDay();
      const lastDay = new Date(year, month, daysInMonth).getDay();
      
      const prevMonthDays = firstDay === 0 ? 6 : firstDay - 1;
      const nextMonthDays = lastDay === 0 ? 0 : 7 - lastDay;
  
      const daysArray = [];
  
      // Días del mes anterior
      for (let i = prevMonthDays; i > 0; i--) {
        const date = new Date(year, month, -i + 1);
        daysArray.push({ day: date.getDate(), outside: true, date });
      }
  
      // Días del mes actual
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        daysArray.push({ day, outside: false, date });
      }  
  
      // Días del mes siguiente
      for (let i = 1; i <= nextMonthDays; i++) {
        const date = new Date(year, month + 1, i);
        daysArray.push({ day: date.getDate(), outside: true, date });
      }
  
      return daysArray;
    };
  
    const prevMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };
  
    const nextMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const selectDay = (day, outside) => {
      if (!outside) { // Solo permite seleccionar días del mes actual
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), day));
      }
    };

  return (
    <main
      className="contain_calendar_casos"
      style={{
        borderTop: theme === "dark" && "1px solid #162436",
        padding: "0",
      }}
    >
      <div
        className="content_menu_casos"
        style={{ borderRight: theme === "dark" && "1px solid #162436" }}
      >
        <section>
          <div style={{ padding: "10px" }}>
            {/* Header del Calendario */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <span style={{ fontWeight: "700" }}>
                {currentDate
                  .toLocaleDateString("es-ES", { month: "long" })
                  .replace(/^\w/, (c) => c.toUpperCase())}{" "}
                {currentDate.getFullYear()}
              </span>
              <div>
                <button
                  onClick={prevMonth}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    color: theme === "dark" ? "#c3cbc4" : "#000",
                  }}
                >
                  <TbChevronLeft size={19} strokeWidth={1.5} />
                </button>
                <button
                  onClick={nextMonth}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    color: theme === "dark" ? "#c3cbc4" : "#000",
                  }}
                >
                  <TbChevronRight size={19} strokeWidth={1.5} />
                </button>
              </div>
            </div>

            {/* Días de la semana */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
                textAlign: "center",
                fontWeight: "600",
                gap: "0",
                fontSize: ".7rem",
                color: theme === "dark" ? "#c3cbe4" : "#979797",
              }}
            >
              {["Lun.", "Mar.", "Mié.", "Jue.", "Vie.", "Sáb.", "Dom."].map(
                (day, index) => (
                  <div
                    key={day}
                    style={{
                      padding: "3px 0",
                    }}
                  >
                    {day}
                  </div>
                )
              )}
            </div>

            {/* Días del mes */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
                gap: "10px 0",
                textAlign: "center",
                fontSize: ".75rem",
              }}
            >
              {generateCalendar().map((item, index) => {
                // Verificar si item.date está definido y obtener el día de la semana
                const isSunday = item.date ? item.date.getDay() === 0 : false;
                const isSelected =
                  item.day === currentDate.getDate() && !item.outside;

                return (
                  <div
                    key={index}
                    onClick={() => selectDay(item.day, item.outside)}
                    style={{
                      padding: "4px",
                      borderRadius: "50%",
                      background: isSelected
                        ? theme === "dark"
                          ? "#c3cbe4"
                          : "#545a6d"
                        : "transparent",
                      color: isSelected
                        ? theme === "dark"
                          ? "#000" // Si está seleccionado y el tema es dark -> Negro
                          : "#fff"
                        : item.outside
                        ? "#c1c1c1"
                        : isSunday
                        ? "#c1c1c1"
                        : item.day === currentDate.getDate()
                        ? "#fff"
                        : theme === "dark"
                        ? "#c3cbe4"
                        : "#000",
                      cursor: item.outside ? "default" : "pointer",
                      opacity: item.outside || isSunday ? 0.7 : 1,
                    }}
                  >
                    {item.day}
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
      <div className="content_casos_en_calendario">
        <section
          style={{ backgroundColor: theme === "dark" && "#121e2d" }}
          className="content_agentes_casos"
        >
          <div
            style={{
              width: "50px",
              borderRight:
                theme === "dark" ? "1px solid #162436" : "1px solid #e9ebec99",
            }}
          ></div>
          <div className="content_agentes">
            {agentes.map((agente) => (
              <article key={agente.id} className="content_agente_casos">
                <div className="content_img_agente">
                  {theme === "dark" ? (
                    <img className="img_agente" src={ImageAgenteWhite} alt="" />
                  ) : (
                    <img className="img_agente" src={ImageAgente} alt="" />
                  )}
                </div>
                <div className="content_info_agente">
                  <span className="name_agente_casos">{agente.full_name}</span>
                  <span
                    style={{ color: theme === "dark" ? "#c3cbe4" : "#979797" }}
                    className="hora_trabajo_agente"
                  >
                    10:00-14:00, 15:00-19:00
                  </span>
                </div>
                <div className="">
                  <TbChevronDown size={18} />
                </div>
              </article>
            ))}
          </div>
        </section>
        <section className="content_horario_casos">content</section>
      </div>
    </main>
  );
}

export default CalendarCasos