// src/Agendamiento.js
import React from 'react';


const Schedule = () => {

  return (
    <div className='page-content' style={{ height: '500px', margin: '50px' }}>

    </div>
  );
};

export default Schedule;