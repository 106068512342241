import React, {useEffect, useState } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Table,
  Input,
  Label,
  Modal,
  Button,
  InputGroup,

} from "reactstrap";


// Redux
import { connect, useDispatch, useSelector} from "react-redux";
import { withRouter, Link} from "react-router-dom";


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";

import SweetAlert from "react-bootstrap-sweetalert";
//Action
import { rolCreate, rolDelete, rolList, rolUpdate } from "../../store/actions";
import { rolFilter } from "../../store/actions";
import { getRolRead } from "../../helpers/backend";


const RolList = (props) => {
const dispatch= useDispatch();
const user = JSON.parse(localStorage.getItem("user"))
const groups  =String(user.groups);

const theme = useSelector((state) => state.theme.theme) || 'light'

const [rolDetail, setRolDetail] = useState({});
const [rolNombre, setRolNombre] = useState('');
const [rolId, setRolId] = useState('');
//Delete
const [confirmText, setConfirmText] = useState(false);
const [confirmDel, setConfirmDel] = useState("");
const [cancel, setCancel]= useState(false)

const getRolDetailData = async (id) => {
  const response = await getRolRead(id);
  const data = response.data;
  setRolDetail(data);
  setRolId(id)
};

useEffect(() => {
  if (rolDetail.nombre) {
    setRolNombre(rolDetail.nombre);
  }
}, [rolDetail.nombre]);
const [search, setSearch] = useState("");

const onChange = (e) => {
  setSearch(e.target.value);
};

const onSubmit = (e) => {
  e.preventDefault();
  dispatch(rolFilter(`?search=${search}`));
};

const resetSearch = () => {
  dispatch(rolFilter(`?search=`));
  setSearch("");
};

useEffect(() => {
  dispatch(rolList());
},[dispatch]);

const [show, setShow] = useState(false);
const [upShow, setUpShow] = useState(false)
const [error, setError] = useState(false)

const handleRolNombre = (e)=>{
    setRolNombre(e.target.value)
};

const onSubmitRol = ()=>{
  if(rolNombre !== ""){
    dispatch(rolCreate({
        nombre: rolNombre,
    }))
    setShow(false);
  }
  else{
    setError(true);
  }
}
const onUpdateRol = ()=>{
  if(rolNombre !== ""){
    dispatch(rolUpdate(rolId,{
        nombre: rolNombre,
    }))
    setUpShow(false);
  }
  else{
    setError(true);
  }
}
const handleConfirm =(id) => {
  setConfirmText(true)
  setConfirmDel(id)
}
const params = {
  count: props.rol.count,
  next: props.rol.next,
  previous: props.rol.previous,
  results: props.rol.results,
};

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Roles</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <div style={{ width: "100%", paddingBottom: "1.5rem" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>Roles</span>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
            <div>
              {props.rolCreated.id ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    dispatch(rolList());
                  }}
                >
                  Se ha agregado el Rol con éxito
                </SweetAlert>
              ) : null}
              {props.rolUpdated.id ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    dispatch(rolList());
                  }}
                >
                  Se ha Actualizado el Rol con éxito
                </SweetAlert>
              ) : null}
              {confirmText ? (
                <SweetAlert
                  title="¿Estás seguro de que quieres eliminar este Rol ?"
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    dispatch(rolDelete(confirmDel));
                    setConfirmText(false);
                  }}
                  onCancel={() => {
                    setConfirmText(false);
                    setCancel(true);
                  }}
                ></SweetAlert>
              ) : null}

              {cancel ? (
                <SweetAlert
                  title="Cancelado"
                  warning
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    setCancel(false);
                  }}
                >
                  La información de usuario esta a salvo
                </SweetAlert>
              ) : null}

              {props.rolDeleted === true ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    setConfirmText(false);
                    dispatch(rolList());
                  }}
                >
                  Se ha eliminado el Rol con éxito!
                </SweetAlert>
              ) : null}

              {error ? (
                <SweetAlert
                  title="Error"
                  warning
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    setError(false);
                  }}
                >
                  Este Campo no puede estar Vacio
                </SweetAlert>
              ) : null}

              <Card className="col-lg-10 mx-auto  mt-3">
                {/* Modeal de Agregar Rol*/}
                <Modal
                  isOpen={show}
                  centered={true}
                  toggle={() => setShow(false)}
                >
                  <div className="modal-header">
                    <h3 className="modal-title mt-2">Rol</h3>
                    <button
                      type="button"
                      onClick={() => {
                        setShow(false);
                      }}
                      className="btn btn-lg"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Row className="col-lg-12 justify-content-center">
                      <Col lg={10} className="mb-3">
                        <Label for="nombre" className="form-label">
                          Nombre
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="nombre"
                          placeholder="Escriba un Rol"
                          onChange={handleRolNombre}
                        />
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary text-dark"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        {" "}
                        Atras
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => onSubmitRol()}
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </Modal>
                {/* Modeal de Actualizar Rol*/}
                <Modal
                  isOpen={upShow}
                  centered={true}
                  toggle={() => setUpShow(false)}
                >
                  <div className="modal-header">
                    <h3 className="modal-title mt-2">Actaulizar Rol</h3>
                    <button
                      type="button"
                      onClick={() => {
                        setUpShow(false);
                      }}
                      className="btn btn-lg"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Row className="col-lg-12 justify-content-center">
                      <Col lg={10} className="mb-3">
                        <Label for="nombre" className="form-label">
                          Nombre
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="nombre"
                          value={rolNombre}
                          onChange={(e) => setRolNombre(e.target.value)}
                        />
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary text-dark"
                        onClick={() => {
                          setUpShow(false);
                        }}
                      >
                        {" "}
                        Atras
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => onUpdateRol()}
                      >
                        Actualizar
                      </button>
                    </div>
                  </div>
                </Modal>

                <CardBody>
                  <Row>
                    <Col md={7}>
                      {groups.includes("SuperAdmin") ||
                      groups.includes("Supervisor") ||
                      groups.includes("Operador") ? (
                        <Link
                          className="btn btn-primary waves-effect waves-light"
                          to="#"
                          onClick={() => setShow(true)}
                        >
                          Agregar Nuevo Rol <span className=""></span>
                        </Link>
                      ) : null}
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Buscar"
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="col-lg-10 mx-auto  mt-3">
                {params.count !== 0 ? (
                  <CardBody>
                    <h4 className="card-title">Roles</h4>
                    <div className="table-responsive">
                      <Table
                        responsive
                        hover
                        className={`font-size-13 text-center ${
                          theme === "dark" ? "table-dark" : "table-light"
                        }`}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              #
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Rol
                            </th>
                            {groups.includes("SuperAdmin") ||
                            groups.includes("Supervisor") ||
                            groups.includes("Operador") ? (
                              <th
                                style={{ color: theme === "dark" && "#c3cbe4" }}
                              >
                                Acción
                              </th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody
                          className={` ${
                            theme === "dark" ? "" : "table-light"
                          }`}
                        >
                          {params.results &&
                            params.results.map((rol, idx) => (
                              <tr key={idx}>
                                <th
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                  scope="row"
                                >
                                  {idx + 1}
                                </th>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                    height: "60px",
                                  }}
                                >
                                  {rol.nombre}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      theme === "dark" ? "#121e2d" : "#fff",
                                  }}
                                >
                                  {groups.includes("SuperAdmin") ||
                                  groups.includes("Supervisor") ||
                                  groups.includes("Operador") ? (
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        getRolDetailData(rol.id);
                                        setUpShow(true);
                                      }}
                                    >
                                      <span className="bx bx-edit bx-sm align-middle"></span>
                                    </Link>
                                  ) : null}{" "}
                                  {groups.includes("SuperAdmin") ||
                                  groups.includes("Supervisor") ? (
                                    <>
                                      <Link
                                        to="#"
                                        onClick={() => handleConfirm(rol.id)}
                                      >
                                        <span className="bx bx-trash bx-sm align-middle"></span>
                                      </Link>

                                      <Link
                                        to={`/rol/permission_assign/${rol.id}`}
                                        id="permission"
                                      >
                                        <span className="fa fa-lock fa-lg mx-1"></span>
                                      </Link>
                                    </>
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="rol/"
                      filterFunction={rolFilter}
                    />
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, rol, rolCreated, rolUpdated, rolDeleted } = state.Rol;
  return { error, loading, rol, rolCreated, rolUpdated, rolDeleted };
};
RolList.propTypes = {
    error: PropTypes.any,
    loading: PropTypes.bool,
    rolCreated: PropTypes.object,
    rolUpdated :PropTypes.object,
    rolDeleted: PropTypes.bool,
    };
export default withRouter(connect(mapStateToProps , { rolList, rolCreate, rolDelete }) (RolList));