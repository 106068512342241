import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, ME, LOGOUT_USER } from "../auth/actionTypes";
import { me, loginSuccess, logoutSuccess, authRequestFailed } from "../auth/actions";

import {
  postLogin,
  getMe,
  postLogout,
} from "../../helpers/backend";

import axiosApi from "../../helpers/api";

function* loginUserSaga({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, user);
    console.log(response);
    
    if (response.status === 200) {
      localStorage.setItem('token', response.data.access);
      axiosApi.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;

      // Guardar la sesión activa con un identificador único
      localStorage.setItem("session_active", JSON.stringify({ isActive: true, tabId: sessionStorage.getItem("tabId") }));

      yield put(me(history));
    } else {
      yield put(authRequestFailed(response.data));
    }
  } catch (error) {
    yield put(authRequestFailed(error.response.data));
  }
}


function* meSaga({ payload: { history } }) {
  try { 
    const response = yield call(getMe);
    console.log(response);
    
    if (response.status === 200) {
      let user = {
        id: response.data.id,        
        username: response.data.username,
        password: response.data.password,
        get_full_name: response.data.get_full_name,
        groups: response.data.grupos,
        user_permissions: response.data.user_permissions,
        roles:response.data.roles,     
        status:response.data.status,     
      };
      localStorage.setItem("user", JSON.stringify(user));
      yield put(loginSuccess(response.data));
      if (history.location.pathname === "/login") {
        history.push("/dashboard");
      }

    } else {
      yield put(authRequestFailed(response.data));
    }
  } catch (error) {
    yield put(authRequestFailed(error.data));
  }
}

function* logoutUserSaga({ payload: { history } }) {
  try {
    const response = yield call(postLogout);

    if (response.status === 205) {
      localStorage.removeItem("user");
      localStorage.removeItem("session_active");
      localStorage.removeItem("token");

      localStorage.removeItem("sessionTime");
      yield put(logoutSuccess());
      history.push("/login");
    } else {
      yield put(authRequestFailed(response.data));
    }
  } catch (error) {
    yield put(authRequestFailed(error.data));
  }
}

export default function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUserSaga);
  yield takeEvery(ME, meSaga);
  yield takeEvery(LOGOUT_USER, logoutUserSaga);
}