import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Card, CardBody, Button, Table } from "reactstrap";
// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";
import Select from "react-select";
import {
  getAgent,
  getCampaignFullList,
  getGestionesContactoRead,
  getReportsForHourList,
} from "../../helpers/backend";
import ModalManagementDetails from "../Whatsapp/components/ModalManagementDetails";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/l10n/es.js"; // Importa el idioma español

const ReportForTime = () => {
  const [detallesModal, setDetallesModal] = useState(false);
  const [GestionDetallada, setGestionDetallada] = useState(false);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [estado, setEstado] = useState("");
  const [agente, setAgente] = useState("");
  const [campaña, setCampaña] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [selectedGestiones, setSelectedGestiones] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState([]);
  const [showGraphic, setShowGraphic] = useState(false);

  const handleSelectChange = (selectedOptions) => {
    const selectedContactData = selectedOptions
      ? selectedOptions.map((option) => option.value).join(",")
      : "";
    setSelectedAgents(selectedOptions || []);
    setAgente(selectedContactData);
  };
  const handleSelectCampaignChange = (selectedOptions) => {
    setSelectedCampaign(selectedOptions || []);
    setCampaña(selectedOptions.value);
  };
  const handleSelectEstadoChange = (selectedOptions) => {
    setSelectedEstado(selectedOptions || []);
    setEstado(selectedOptions.value); // Asumo que setAgente está definido en otro lugar y actualiza el estado correspondiente
  };

  const [agent, setAgent] = useState({});
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data);
  };
  const [campaignData, setCampaignData] = useState({});
  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    const data = response.data;
    setCampaignData(data);
  };
  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [start, end] = selectedDates;
      setFechaInicio(start.toISOString().split("T")[0]); // Formato YYYY-MM-DD
      setFechaFin(end.toISOString().split("T")[0]); // Formato YYYY-MM-DD
      setSelectedDate(selectedDates);
    }
  };
  const getReportData = async () => {
    setSelectedGestiones([]); // Reinicia las gestiones seleccionadas
    if (!fechaInicio || !fechaFin) {
      setShowWarning(true);
      return;
    }
    setLoading(true);
    setShowGraphic(true)
    try {
      const response = await getReportsForHourList(
        `?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campaña}&type=WhatsApp`
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false);
      setShowWarning(false)
    }
  };

  const resetSearch = () => {
    setFechaFin("");
    setFechaInicio("");
    setSelectedDate("");
    setEstado("");
    setAgente("");
    setCampaña("");
    setSelectedGestiones([]); // Reinicia las gestiones seleccionadas
    setData({}); // Reinicia los datos del reporte
    setShowGraphic(false)
  };

  useEffect(() => {
    getAgentData();
    getCampaignData();
  }, []);

  // Formatear los datos para el gráfico
  const formattedData = Object.entries(data)
    .filter(([key]) => key !== "total_gestiones") // Excluir total
    .map(([hour, value]) => ({
      hora: hour.replace("_", " "), // Reemplazar guiones bajos
      gestiones: value.contador || 0,
      Details: value.gestiones || [], // Asegúrate de que Details sea un array
    }));

  // Manejar el clic en una barra del gráfico
  const handleBarClick = (data) => {
    if (
      data &&
      data.activePayload &&
      data.activePayload[0] &&
      data.activePayload[0].payload.Details
    ) {
      setSelectedGestiones(data.activePayload[0].payload.Details); // Guarda las gestiones de la barra clickeada
    } else {
      setSelectedGestiones([]); // Si no hay detalles, establece un array vacío
    }
  };

  // Manejar la apertura del modal con los detalles de la gestión
  const handleGestionDetallada = async (id) => {
    const response = await getGestionesContactoRead(id);
    setGestionDetallada(response.data);
    setDetallesModal(true);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Reporte por Hora</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div style={{ width: "100%", paddingBottom: "1.5rem" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>Reporte por hora</span>
          </div>

          {/* Modal de detalles de gestión */}
          {detallesModal && (
            <ModalManagementDetails
              isOpen={detallesModal}
              onCloseClick={() => setDetallesModal(false)}
              GestionDetallada={GestionDetallada}
            />
          )}

          {loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                  <Row className="d-flex justify-content-center">
                    <Col lg={12}>
                      <Row>
                        <Col>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Fecha"
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d",
                              locale: "es",
                            }}
                            onChange={handleDateChange}
                            value={selectedDate}
                          />
                        </Col>
                        <Col>
                          <Select
                            onChange={handleSelectEstadoChange}
                            options={[
                              { value: "", label: "---------" },
                              { value: "EN_GESTION", label: "En Gestión" },
                              { value: "VENCIDO", label: "Vencido" },
                              { value: "FINALIZADO", label: "Finalizado" },
                              { value: "PENDIENTE", label: "Pendiente" },
                            ]}
                            placeholder="Estado"
                            value={selectedEstado}
                            menuPosition="fixed"
                          />
                        </Col>
                        <Col>
                          <Select
                            className="me-1"
                            options={
                              Array.isArray(agent)
                                ? [
                                    { label: "Todos", value: "" },
                                    ...agent.map((item) => ({
                                      label: item.get_full_name,
                                      value: item.id,
                                    })),
                                  ]
                                : []
                            }
                            value={selectedAgents}
                            onChange={handleSelectChange}
                            isMulti={true}
                            placeholder="Agentes"
                            menuPosition="fixed"
                          />
                        </Col>
                        <Col>
                          <Select
                            className="me-1"
                            options={
                              Array.isArray(campaignData)
                                ? [
                                    { label: "----------", value: "" },
                                    ...campaignData.map((item) => ({
                                      label: item.name,
                                      value: item.id,
                                    })),
                                  ]
                                : []
                            }
                            onChange={handleSelectCampaignChange}
                            value={selectedCampaign}
                            menuPosition="fixed"
                            placeholder="Campañas"
                          />
                        </Col>
                        <Col lg={2}>
                          <Button
                            color="warning"
                            className="btn-sm me-1 mt-1"
                            onClick={getReportData}
                          >
                            <b>Consultar</b>
                          </Button>
                          <Button
                            color="danger"
                            className="btn-sm me-1 mt-1"
                            onClick={resetSearch}
                          >
                            <b>Borrar</b>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    {showWarning && (
                      <div style={{ color: "red", marginTop: "10px" }}>
                        Por favor, coloque un rango de fechas. La fecha es
                        requerida.
                      </div>
                    )}
                  </Row>
                </CardBody>
              </Card>
              {showGraphic ? (
                <Row>
                  <Col lg={6}>
                    <Card>
                      <div className="d-flex justify-content-center mt-3 mb-5">
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart
                            data={formattedData}
                            onClick={handleBarClick}
                          >
                            <XAxis
                              dataKey="hora"
                              tick={{ fontSize: 12, fill: "#333" }}
                              angle={-15}
                              textAnchor="end"
                              interval={0}
                            />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="gestiones" fill="#82ca9d" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Card>
                  </Col>

                  {selectedGestiones.length > 0 && (
                    <Col lg={6}>
                      <div
                        className="custom-scroll "
                        style={{ maxHeight: "400px", overflowY: "auto" }}
                      >
                        <Table className="table text-center mb-3" hover>
                          <thead className="table-dark align-middle">
                            <tr>
                              <th>Contacto</th>
                              <th>Estado</th>
                              <th>Fecha</th>
                              <th>Canal de Comunicación</th>
                              <th>Acción</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedGestiones.map((gestion) => (
                              <tr key={gestion.id}>
                                <td>{gestion.contacto}</td>
                                <td>{gestion.estado}</td>
                                <td>{gestion.created_date}</td>
                                <td>{gestion.type}</td>
                                <td>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      handleGestionDetallada(gestion.id)
                                    }
                                  >
                                    <span className="fas fa-eye fa-lg"></span>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  )}
                </Row>
              ) : null}
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportForTime;
