import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Table,
  Input,
  Button,
  InputGroup,
  Label,
  Modal,
} from "reactstrap";


// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";

//Action
import { descripcionCalificacionList } from "../../store/descripcionCalificacion/actions";
import { descripcionCalificacionListChange } from "../../store/descripcionCalificacion/actions";
import { getDescripcionCalificacionRead, patchDescripcionCalificacionUpdate } from "../../helpers/backend";
import SweetAlert from "react-bootstrap-sweetalert";


const DescripcionCalificacion = (props) => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"))
  const groups = String(user.groups);

  const [search, setSearch] = useState("");
  const [eescripcionCalificacion, setDescripcionCalificacion] = useState("")
  const [eescripcionCalificacionId, setDescripcionCalificacionId] = useState(null)
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [eescripcionCalificacionDetail, setDescripcionCalificacionDetail] = useState({})
  const getDescripcionCalificacionDetailData = async (id) => {
    const response = await getDescripcionCalificacionRead(id);
    const data = response.data;
    setDescripcionCalificacionDetail(data);
    setDescripcionCalificacionId(id)
  };

  useEffect(() => {
    dispatch(descripcionCalificacionList());
  }, [dispatch])

  useEffect(() => {
    if (eescripcionCalificacionDetail.name) {
      setDescripcionCalificacion(eescripcionCalificacionDetail.name);
    }
  }, [eescripcionCalificacionDetail]);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(descripcionCalificacionListChange(`?search=${search}`));
  };
  const onUpdateDescripcionCalificacion = () => {
    patchDescripcionCalificacionUpdate(eescripcionCalificacionId, {
      name: eescripcionCalificacion,
    })
    setShow(false)
    setShowAlert(true)
  }
  const resetSearch = () => {
    dispatch(descripcionCalificacionListChange(`?search=`));
    setSearch("");
  };

  const params = {
    count: props.descripcionCalificacion.count,
    next: props.descripcionCalificacion.next,
    previous: props.descripcionCalificacion.previous,
    results: props.descripcionCalificacion.results,
  };

  const theme = useSelector((state) => state.theme.theme) || 'light'

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Calificación </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <div style={{ width: "100%", paddingBottom: "1.5rem" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>
              Calificación
            </span>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
            <div>
              <Card className="col-lg-10 mx-auto  mt-3">
                <Modal
                  isOpen={show}
                  centered={true}
                  toggle={() => setShow(false)}
                >
                  <div className="modal-header">
                    <h3 className="modal-title mt-2">Calificación</h3>
                    <button
                      type="button"
                      onClick={() => {
                        setShow(false);
                      }}
                      className="btn btn-lg"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Row className="col-lg-12 justify-content-center">
                      <Col lg={10} className="mb-3">
                        <Label for="name" className="form-label">
                          Nombre
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="name"
                          value={eescripcionCalificacion}
                          onChange={(e) =>
                            setDescripcionCalificacion(e.target.value)
                          }
                        />
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary text-dark"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        {" "}
                        Atras
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => onUpdateDescripcionCalificacion()}
                      >
                        Actualizar
                      </button>
                    </div>
                  </div>
                </Modal>
                {showAlert ? (
                  <SweetAlert
                    title="Hecho!"
                    success
                    timeout={2000}
                    showConfirm={false}
                    onConfirm={() => {
                      dispatch(descripcionCalificacionList());
                      setShowAlert(false);
                    }}
                  >
                    Se ha Actualizado con éxito
                  </SweetAlert>
                ) : null}
                <CardBody>
                  <Row>
                    <Col md={7}>
                      {groups.includes("SuperAdmin") ||
                      groups.includes("Administrador") ||
                      groups.includes("Supervisor") ||
                      groups.includes("Operador") ||
                      groups.includes(
                        "Crear y modificar DescripcionCalificacion Interna"
                      ) ? (
                        <Link
                          className="btn btn-primary waves-effect waves-light"
                          to="/descripcionCalificacion/add_descripcion"
                        >
                          Agregar{" "}
                        </Link>
                      ) : null}
                      <Link
                        className="btn btn-success waves-effect waves-light ms-1"
                        to="/campaign"
                      >
                        Volver
                        <span className=""></span>
                      </Link>
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Buscar"
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="col-lg-10 mx-auto  mt-3">
                {params.count !== 0 ? (
                  <CardBody className="pb-0">
                    <h4 className="card-title"> Calificación </h4>
                    <div className="table-responsive">
                      <Table
                        responsive
                        hover
                        className={`font-size-13 text-center ${
                          theme === "dark" ? "table-dark" : "table-light"
                        }`}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              #
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Nombre
                            </th>
                            <th
                              style={{ color: theme === "dark" && "#c3cbe4" }}
                            >
                              Pertenece a{" "}
                            </th>
                            {groups.includes("SuperAdmin") ||
                            groups.includes("Administrador") ||
                            groups.includes("Supervisor") ||
                            groups.includes("Operador") ||
                            groups.includes(
                              "Crear y modificar DescripcionCalificacion Interna"
                            ) ? (
                              <th
                                style={{ color: theme === "dark" && "#c3cbe4" }}
                              >
                                Acción
                              </th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody
                          className={` ${
                            theme === "dark" ? "" : "table-light"
                          }`}
                        >
                          {params.results &&
                            params.results.map(
                              (descripcionCalificacion, idx) => (
                                <tr key={idx}>
                                  <th
                                    style={{
                                      backgroundColor:
                                        theme === "dark" ? "#121e2d" : "#fff",
                                      height: "60px",
                                    }}
                                    scope="row"
                                  >
                                    {idx + 1}
                                  </th>
                                  <td
                                    style={{
                                      backgroundColor:
                                        theme === "dark" ? "#121e2d" : "#fff",
                                    }}
                                  >
                                    {descripcionCalificacion.name}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor:
                                        theme === "dark" ? "#121e2d" : "#fff",
                                    }}
                                  >
                                    {descripcionCalificacion.calificacion}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor:
                                        theme === "dark" ? "#121e2d" : "#fff",
                                    }}
                                  >
                                    {groups.includes("SuperAdmin") ||
                                    groups.includes("Administrador") ||
                                    groups.includes("Supervisor") ||
                                    groups.includes("Operador") ||
                                    groups.includes(
                                      "Crear y modificar DescripcionCalificacion"
                                    ) ? (
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          getDescripcionCalificacionDetailData(
                                            descripcionCalificacion.id
                                          );
                                          setShow(true);
                                        }}
                                      >
                                        <span className="bx bx-edit bx-sm align-middle"></span>
                                      </Link>
                                    ) : null}
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="contact/descripcionCalificacion/"
                      filterFunction={descripcionCalificacionListChange}
                    />
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, descripcionCalificacion } = state.DescripcionCalificacion;
  return { error, loading, descripcionCalificacion };
};

export default withRouter(connect(mapStateToProps)(DescripcionCalificacion));