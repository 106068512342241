import React, { useState } from 'react';
import Sidebar from './Sidebar';
import ChatWindow from './ChatWindow';
import './ChatApp.css';
import { Container } from 'reactstrap';

const ChatApp = () => {
  const [chats, setChats] = useState([]); // Historial de chats
  const [currentChatId, setCurrentChatId] = useState(null); // ID del chat activo
  const [messages, setMessages] = useState([]); // Mensajes del chat actual

  // Función para crear un nuevo chat
  const createNewChat = () => {
    const newChatId = Date.now(); // Genera un ID único basado en la hora
    setChats([...chats, { id: newChatId, title: `Chat ${chats.length + 1}`, date: new Date() }]);
    setCurrentChatId(newChatId);
    setMessages([]); // Reinicia los mensajes para el nuevo chat
  };

  // Función para seleccionar un chat existente
  const selectChat = (chatId) => {
    setCurrentChatId(chatId);
    // Aquí podrías cargar los mensajes del chat seleccionado desde una API o estado
    setMessages([]); // Simulamos que no hay mensajes previos
  };

  return (
    < Container fluid>
    <div className="chat-app page-content">
      <Sidebar chats={chats} onCreateChat={createNewChat} onSelectChat={selectChat} />
      <ChatWindow messages={messages} onSendMessage={(message) => setMessages([...messages, message])} />
    </div>
    </Container>

  );
};

export default ChatApp;