import React from 'react'
import ButtonVolver from '../../../components/ButtonVolver'
import { useSelector } from 'react-redux'
import { CardBody, Col, Row } from 'reactstrap';
import { TbChevronLeft, TbChevronRight, TbSearch } from "react-icons/tb";
import Select from "react-select";


const NavbarCalendar = ({ setViewMode, viewMode, selectedDate, setSelectedDate }) => {

  const theme = useSelector((state) => state.theme.theme) || 'light';

  const options = [
    { label: "Día", value: "dia" },
    { label: "Semana", value: "semana" },
    { label: "Mes", value: "mes" },
    { label: "Año", value: "year" },
  ];

  const formattedDate = selectedDate.toLocaleDateString("es-ES", {
    day: "numeric",
    month: "long",
    year: "numeric"
  });

  return (
    <>
      <CardBody>
        <Row className="d-flex justify-content-between mx-1">
          <Col
            className="content_title_navbar_calendar"
            style={{ maxWidth: "250px" }}
          >
            <ButtonVolver />
            <div>
              <span
                style={{ color: theme === "dark" ? "#fff" : "#000" }}
                className="title_navbar_cal"
              >
                Calendario
              </span>
            </div>
          </Col>

          <Col className="content_button_reset_fecha">
            <button
              className="font-size-15 button_resetFecha"
              style={{ color: theme === "dark" && "#c3cbe4" }}
              onClick={() => setSelectedDate(new Date())}
            >
              Hoy
            </button>
            <div className="content_fecha_indicador">
              <div>
                <TbChevronLeft size={22} strokeWidth={1.5} />
                <TbChevronRight size={22} strokeWidth={1.5} />
              </div>
              <div>
                <span
                  style={{ color: theme === "dark" ? "#fff" : "#000" }}
                  className="title_navbar_cal"
                >
                  {viewMode === "dia"
                    ? selectedDate.toLocaleDateString("es-ES", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })
                    : viewMode === "semana" || viewMode === "mes"
                    ? selectedDate
                        .toLocaleDateString("es-ES", {
                          month: "long",
                          year: "numeric",
                        })
                        .replace(
                          /^([a-záéíóúü]+)/,
                          (match) =>
                            match.charAt(0).toUpperCase() + match.slice(1)
                        )
                    : selectedDate.getFullYear()}
                </span>
              </div>
            </div>
          </Col>

          <Col className="d-flex justify-content-end align-items-center gap-2">
            <div className="content_button_search">
              <TbSearch size={22} />
            </div>
            <div
              className="font-size-15"
              style={{
                width: "150px",
              }}
            >
              <Select
                id="timeRange"
                name="timeRange"
                options={options}
                onChange={(option) => setViewMode(option.value)}
                defaultValue={options[0]}
                placeholder={"Selecciona un rango"}
                isMulti={false}
                menuPosition="fixed"
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                    color: theme === "dark" ? "#c3cbe4" : "#000",
                    borderColor: theme === "dark" ? "#162436" : "#d2d2d2",
                    cursor: "pointer",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                  }),
                  option: (base, { isFocused, isSelected }) => ({
                    ...base,
                    backgroundColor: isSelected
                      ? theme === "dark"
                        ? "#121e2d"
                        : "#ccc"
                      : isFocused
                      ? theme === "dark"
                        ? "#0e1824"
                        : "#ddd"
                      : "transparent",
                    color: theme === "dark" ? "#c3cbe4" : "#000",
                    padding: "10px",
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: theme === "dark" ? "#c3cbe4" : "#000",
                  }),
                }}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
    </>
  );
}

export default NavbarCalendar