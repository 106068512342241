import React, { useState } from 'react';
import './ChatWindow.css';

const ChatWindow = ({ messages, onSendMessage }) => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  // Simulación de envío de mensaje y respuesta de la IA
  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;

    const userMessage = { text: inputValue, sender: 'user' };
    onSendMessage(userMessage);
    setInputValue('');

    setLoading(true); // Activa la animación de carga

    // Simula una respuesta de la IA después de 2 segundos
    setTimeout(() => {
      const aiMessage = { text: 'Respuesta de la IA...', sender: 'ai' };
      onSendMessage(aiMessage);
      setLoading(false); // Desactiva la animación de carga
    }, 2000);
  };

  return (
    <div className="chat-window">
      <div className="messages-container">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            {msg.text}
          </div>
        ))}
        {loading && <div className="loading-indicator">Esperando respuesta...</div>}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Escribe un mensaje..."
        />
        <button onClick={handleSendMessage}>Enviar</button>
      </div>
    </div>
  );
};

export default ChatWindow;