import React from 'react'
import { useSelector } from 'react-redux'
import { TbReload } from "react-icons/tb";

const BandejaEmail = () => {  
  const theme = useSelector((state) => state.theme.theme) || 'light'

  return (
    <section
      className="content_bandeja_emails"
      style={{ backgroundColor: theme === "light" ? "#fff" : "#121e2d" }}
    >
      <article className="contain_acciones_emails">
        <div className="content_acciones_email">
          <div style={{paddingTop:"4px"}}>
            <div title='Seleccionar' className="check_accion_email"></div>
          </div>
          <div>
            <TbReload
              style={{ cursor: "pointer" }}
              size={20}
              strokeWidth={2.4}
              title='Actualizar'
            />
          </div>
        </div>
        <div></div>
      </article>
      BandejaEmail
    </section>
  );
}

export default BandejaEmail