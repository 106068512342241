import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/l10n/es.js"; // Importa el idioma español
import { getSessionHistory } from "../../../helpers/backend";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Container, Row, Table, Button } from "reactstrap";


const SessionHistory = () => {
  const id = useParams().id;
  const historyHook = useHistory();
  const [history, setHistory] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    fetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicio, fechaFin]);

  const fetchHistory = async () => {
    try {
      const response = await getSessionHistory(
        id,
        `&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );
      setHistory(response.data);
    } catch (error) {
      console.error("Error fetching history:", error);
    }
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [start, end] = selectedDates;
      setFechaInicio(start.toISOString().split("T")[0]); // Formato YYYY-MM-DD
      setFechaFin(end.toISOString().split("T")[0]); // Formato YYYY-MM-DD
      setSelectedDate(selectedDates);
    }
  };

  const exportToExcel = () => {

  };

  const handleGoBack = () => {
    historyHook.goBack();
  };
console.log(history)
  return (
    <React.Fragment>
      <Container fluid>
        <div className="page-content">
          <h4>Historial de Conexiones</h4>
          <div className="d-flex mt-2 mb-2">
            <Row>
              <Col>
                <Flatpickr
                  className="form-control d-block"
                  placeholder="Fecha"
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                    locale: "es",
                  }}
                  onChange={handleDateChange}
                  value={selectedDate}
                />
              </Col>

              <Col>
              <Button className="btn btn-sm " color="success" onClick={exportToExcel}>
                  Exportar a Excel
                </Button>
              </Col>
            </Row>
          </div>
          <Table className="table text-center mb-3" hover>
            <thead className="table-dark align-middle">
              <tr>
                <th>Usuario</th>
                <th>Hora de Login</th>
                <th>Hora de Logout</th>
                <th>Duración de Sesión</th>
                <th>Tiempo Total de Pausa</th>
                <th>Tiempo Neto de Trabajo</th>
                <th>Pausas</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(history)
                ? history.map((session) => (
                    <tr key={session.id}>
                      <td>{session.user}</td>
                      <td>{session.login_time}</td>
                      <td>{session.logout_time}</td>
                      <td>{session.session_duration}</td>
                      <td>{session.total_pause_time}</td>
                      <td>{session.net_work_time}</td>
                      <td>
                        <ul>
                          {session.pauses.map((pause) => (
                            <li key={pause.id}>
                              {pause.reason}: {pause.comment} ({pause.time_range})
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))
                : []}
            </tbody>
          </Table>
          <Row>
            <Col className="d-flex justify-content-center text-center">
                <Button className="btn btn-sm text-dark" color="secondary" onClick={handleGoBack}>
                  Volver
                </Button>
              </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default SessionHistory;