/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import '../styles/Calendar.css';
import { useSelector } from 'react-redux';

const MainCalendarContent = ({ selectedDate, setSelectedDate, viewMode, setViewMode }) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [animationClass, setAnimationClass] = useState("");
  const [isAnimating, setIsAnimating] = useState(false);
  
  const theme = useSelector((state) => state.theme.theme) || 'light';

  // Lógica común para el modo día (ya implementada)
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const hourHeight = 50;
  const totalDayHeight = 24 * hourHeight;
  const offsetCorrection = 24;

  const calculatePosition = () => {
    const now = new Date();
    const totalMinutesInDay = 24 * 60;
    const minutesSoFar = now.getHours() * 60 + now.getMinutes();
    const fractionOfDay = minutesSoFar / totalMinutesInDay; 
    return fractionOfDay * totalDayHeight;
  };

  useEffect(() => {
    setCurrentPosition(calculatePosition());
    const intervalId = setInterval(() => {
      setCurrentPosition(calculatePosition());
    }, 60_000);
    return () => clearInterval(intervalId);
  }, []);

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  // SEMANA
  function getWeekDates(baseDate) {
    const current = new Date(baseDate.getTime());
    const dayOfWeek = current.getDay(); 
    const sunday = new Date(current);
    sunday.setDate(sunday.getDate() - dayOfWeek);
  
    const days = [];
    for (let i = 0; i < 7; i++) {
      const newDate = new Date(sunday);
      newDate.setDate(sunday.getDate() + i);
      days.push(newDate);
    }
    return days;
  }
  
  function dayName(date) {
    const options = { weekday: "short" };
    return date
      .toLocaleDateString("es-ES", options)
      .replace(/\.$/, "")
      .toUpperCase();
  }

  const prevSelectedDate = useRef(selectedDate);

  useEffect(() => {
    const previous = new Date(prevSelectedDate.current);
    previous.setHours(0, 0, 0, 0);
    const current = new Date(selectedDate);
    current.setHours(0, 0, 0, 0);

    if (current.getTime() > previous.getTime()) {
      setAnimationClass("animate-right-to-left");
    } else if (current.getTime() < previous.getTime()) {
      setAnimationClass("animate-left-to-right");
    } else {
      setAnimationClass("");
    }
    prevSelectedDate.current = selectedDate;

    const timer = setTimeout(() => {
      setAnimationClass("");
    }, 500);
    return () => clearTimeout(timer);
  }, [selectedDate]);

  useEffect(() => {
    setIsAnimating(true);

    const timer = setTimeout(() => {
      setIsAnimating(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [viewMode]);

  const combinedClass = isAnimating ? `${animationClass} fade_zoom` : animationClass;


  if (viewMode === 'dia') {
    return (
      <main
        style={{ backgroundColor: theme === "dark" ? "#121e2d" : "#fafafa", border: theme === 'light' && '1px solid #e9ebec' }}
        className="_calendar_contain_"
      >
        <div className={`${combinedClass}`}>
          <div className="content_dia_actual_events">
            <span className="name_dia_actual_events">
              {selectedDate
                .toLocaleDateString("es-ES", { weekday: "short" })
                .replace(/\.$/, "")
                .toUpperCase()}
            </span>
            <span className={`${isToday(selectedDate) ? 'dia_actual_events' : 'dia_events'}`}>{selectedDate.getDate()}</span>
          </div>
          <div className="content_calendar_events">
            <div className="day_view_container">
              <div
                style={{
                  color: theme === "dark" ? "#c3cbe4" : "#000",
                  borderRight: theme === "dark" ? "1px solid #333537" : "1px solid #ddd",
                }}
                className="time_labels"
              >
                {hours.map((hour) => (
                  <div key={hour} className="time_label">
                    {hour}:00
                  </div>
                ))}
              </div>
              <div className="time_slots">
                {hours.map((hour) => (
                  <div
                    key={hour}
                    style={{ position: "relative", height: "50px" }}
                    className="time_slot"
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "-10px",
                        right: 0,
                        transform: "translateY(-50%)",
                        borderTop: theme === "dark" ? "1px solid #333537" : "1px solid #ddd",
                      }}
                    />
                  </div>
                ))}
                {isToday(selectedDate) && (
                  <div
                    className="current-time-line"
                    style={{ top: `${currentPosition + offsetCorrection}px` }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  } else if (viewMode === 'semana') {
    const weekDays = getWeekDates(selectedDate);
    return (
      <main
        style={{ backgroundColor: theme === "dark" ? "#121e2d" : "#fafafa", border: theme === 'light' && '1px solid #e9ebec' }}
        className="_calendar_contain_"
      >
        <div className={`${combinedClass}`}>
          <div className="week_header">
            <div className="week_header_empty" />
            {weekDays.map((day, index) => (
              <div key={index} className="content_dia_semana_events">
                <span className="name_dia_actual_events">{dayName(day)} </span>
                <span
                  onClick={() => {
                    setSelectedDate(day);
                    setViewMode("dia");
                  }}
                  className={`${
                    isToday(day) ? "dia_actual_events" : "dia_events"
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  {day.getDate()}
                </span>
              </div>
            ))}
          </div>

          <div className="content_calendar_events">
            <div className="week_view_container">
              <div
                style={{
                  color: theme === "dark" ? "#c3cbe4" : "#000",
                }}
                className="time_labels"
              >
                {hours.map((hour) => (
                  <div key={hour} className="time_label">
                    {hour}:00
                  </div>
                ))}
              </div>

              <div className="week_grid">
                {weekDays.map((day, dayIndex) => (
                  <div
                    key={dayIndex}
                    className="week_day_column"
                    style={{
                      borderLeft:
                        theme === "dark"
                          ? "1px solid #333537"
                          : "1px solid #ddd",
                    }}
                  >
                    {hours.map((hour) => (
                      <div
                        key={hour}
                        style={{ position: "relative", height: "50px" }}
                        className="time_slot"
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "-10px",
                            right: 0,
                            transform: "translateY(-50%)",
                            borderTop:
                              theme === "dark"
                                ? "1px solid #333537"
                                : "1px solid #ddd",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  } else if (viewMode === 'mes') {
    
    function getCalendarMatrix(date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const firstDayOfMonth = new Date(year, month, 1);
      const firstDayOfMatrix = new Date(firstDayOfMonth);
      const dayOfWeek = firstDayOfMonth.getDay(); 
      firstDayOfMatrix.setDate(firstDayOfMatrix.getDate() - dayOfWeek);

      const matrix = [];
      let current = new Date(firstDayOfMatrix);
  
      for (let i = 0; i < 6; i++) {
        const week = [];
        for (let j = 0; j < 7; j++) {
          week.push(new Date(current));
          current.setDate(current.getDate() + 1);
        }
        matrix.push(week);
      }
      return matrix;
    }

    const weekDayNames = ["DOM", "LUN", "MAR", "MIÉ", "JUE", "VIE", "SÁB"];

    const calendarMatrix = getCalendarMatrix(selectedDate);

    function prevMonth() {
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      setSelectedDate(new Date(year, month - 1, 1));
    }
    function nextMonth() {
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      setSelectedDate(new Date(year, month + 1, 1));
    }
    function goToday() {
      const now = new Date();
      setSelectedDate(new Date(now.getFullYear(), now.getMonth(), 1));
    }

    return (
      <main
        style={{ backgroundColor: theme === "dark" ? "#121e2d" : "#fafafa", border: theme === 'light' && '1px solid #e9ebec' }}
        className="_calendar_contain_"
      >
        <div className={combinedClass}>
          {/* Barra de navegación del mes (opcional) */}
          {/* <div className="month_navbar">
            <button onClick={prevMonth}>&lt;</button>
            <button onClick={goToday}>Hoy</button>
            <button onClick={nextMonth}>&gt;</button>
          </div> */}

          <div className="month_header">
            {weekDayNames.map((dayName) => (
              <div key={dayName} className="month_header_day">
                {dayName}
              </div>
            ))}
          </div>

          <div className="month_grid">
            {calendarMatrix.map((week, wIndex) => (
              <div
                key={wIndex}
                style={{
                  borderBottom:
                    wIndex === calendarMatrix.length - 1
                      ? "none"
                      : theme === "dark"
                      ? "1px solid #333537"
                      : "1px solid #ddd",
                }}
                className="month_row"
              >
                {week.map((day, dIndex) => {
                  const isCurrentMonth =
                    day.getMonth() === selectedDate.getMonth();

                  const today = new Date();
                  const isCurrentDay =
                    day.getDate() === today.getDate() &&
                    day.getMonth() === today.getMonth() &&
                    day.getFullYear() === today.getFullYear();

                  return (
                    <div
                      key={dIndex}
                      style={{
                        borderRight:
                          dIndex === week.length - 1
                            ? "none"
                            : theme === "dark"
                            ? "1px solid #333537"
                            : "1px solid #ddd",
                      }}
                      className={`
                        month_cell
                        "current-month"
                        
                      `}
                    >
                      <span
                        className={`${isCurrentDay ? "today" : "dia_del_mes"} ${
                          isCurrentMonth ? "current-month" : "other-month"
                        }`}
                        onClick={() => {
                          setSelectedDate(day);
                          setViewMode("dia");
                        }}
                      >
                        {day.getDate()}
                      </span>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </main>
    );
  } else if (viewMode === 'year') {
    return (
      <main
        style={{ backgroundColor: theme === "dark" ? "#121e2d" : "#e9ebec99" }}
        className="_calendar_contain_"
      >
        <div className="year-view-placeholder">
          <h2 style={{ color: theme === "dark" ? "#fff" : "#000" }}>Vista de Año</h2>
          {/* Aquí iría la lógica para renderizar la vista de año */}
        </div>
      </main>
    );
  } else {
    return null;
  }
};

export default MainCalendarContent;
