/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react';
import PhotoUser from '../../../assets/images/user-chat.svg';
import { TbSearch } from "react-icons/tb";
import LoadingChatApp from './LoadingChatApp';
import { TbMessage2Plus } from "react-icons/tb";
import { getContactsList } from '../../../helpers/backend';
import { TbArrowNarrowLeft } from "react-icons/tb";

const BandejaChats = ({ onChatSelect, chats, contactConnect, onBack }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingContacts, setLoadingContacts] = useState(true);
  const [isNewChatVisible, setIsNewChatVisible] = useState(false);
  const [allContacts, setAllContacts] = useState([]);
  const searchRef = useRef(null);  

  const filteredChats = chats.filter(chat => 
    chat.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNewChatClick = async () => {
    setIsNewChatVisible(true);
    const response = await getContactsList();
    const data = response.data.results;
    setAllContacts(data);
    setLoadingContacts(false)
    
  };

  const handleContactSelect = (contact) => {
    onChatSelect({
      id_contact: contact.id,
      name: contact.get_full_name,
    });
    setIsNewChatVisible(false); // Cierra la lista de contactos
  };

  const handleBack = () => {
    setIsNewChatVisible(false)
  };
  
  return (
    <>
      {loading ? (
        <LoadingChatApp />
        ) : (
        <div className="bandeja_de_entrada__">
          <div className="header_chat">
            <div
              className={`${isSearchVisible ? "hidden_title_header_chat" : ""}`}
            >
              <h3 className="title_header_chat">Bandeja de entrada</h3>
            </div>
            <div
              ref={searchRef}
              className={`${
                isSearchVisible ? "content_input_search_chat" : ""
              }`}
            >
              {isSearchVisible && (
                <input
                  type="text"
                  className="input_buscar_chat"
                  placeholder="Buscar chat..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              )}
              <div
                className="icon_search"
                onClick={() => setIsSearchVisible(!isSearchVisible)}
              >
                <TbSearch size={18} strokeWidth={2.5} />
              </div>
            </div>
          </div>
          <article className="contain_contacts_connect">
            {contactConnect.length === 0 ? (
              <div className="content_no_users">
                <span className="p_no_users">No hay usuarios conectados.</span>
              </div>
            ) : (
              contactConnect.map((contact) => (
                <div key={contact.name} className="content_contact_connect">
                  <div className="content_img_chat_connect">
                    <img className="img_chat_connect" src={PhotoUser} alt="" />
                    <div className="point_connect" />
                  </div>
                  <div className="name_contact_connect">
                    {contact.name.split(" ")[0]}
                  </div>
                </div>
              ))
            )}
          </article>
          <div className="chat_list">
            {filteredChats.map((chat) => (
              <article
                className="chat_opcion"
                key={chat.id_contact}
                onClick={() => onChatSelect(chat)}
              >
                <div className="content_img_chat">
                  <img className="img_chat" src={PhotoUser} alt="" />
                  <div
                    className={`${
                      chat.status === "CONECTADO"
                        ? "point_contact_connect"
                        : "point_contact_disconnect"
                    }`}
                  />
                </div>
                <div className="content_chat_info">
                  <div className="name_banadeja_chat">
                    {chat.id} {chat.name}
                  </div>
                  <div className='content_ultimate_message_chat'>
                    <p className="ultimate_message_chat">{chat.last_message}</p>
                  </div>
                </div>
                <div className="content_time_last_massge">
                  <span className="time_last_message">
                    {chat.hora.split(" ")[1].split(":").slice(0, 2).join(":")}
                  </span>
                </div>
              </article>
            ))}
          </div>

          <div onClick={handleNewChatClick} className="content_message_new">
            <TbMessage2Plus className="button_new_message" size={24} />
          </div>

          {isNewChatVisible && (
            <div className={`new_chat_overlay ${isNewChatVisible ? 'visible' : ''}`}>
              <div className="new_chat_container">
                <div className="header_chat_contact">
                  <div className="button_volver_chat" onClick={handleBack}>
                    <TbArrowNarrowLeft size={18} />
                  </div>
                  <h3 className="title_header_chat">Selecciona un contacto</h3>
                </div>
                <div className="new_chat_list">
                  {loadingContacts ? (
                    <div className="loading_spinner">
                      <div className="spinner"></div>
                    </div>
                  ) : (
                    allContacts.map((contact) => (
                      <div
                        key={contact.id}
                        className="chat_opcion"
                        onClick={() => handleContactSelect(contact)}
                      >
                        <div className="content_img_chat">
                          <img
                            src={PhotoUser}
                            alt={contact.get_full_name}
                            className="img_chat"
                          />
                          {/* <div
                    className={`${
                      chat.status === "CONECTADO"
                        ? "point_contact_connect"
                        : "point_contact_disconnect"
                    }`}
                  /> */}
                        </div>
                        <div className="content_chat_info">
                          <span className="name_banadeja_chat">
                            {contact.get_full_name}
                          </span>
                        </div>
                      </div>
                    ))
                  )}
                  <div className="patner_info_chat">
                    <span className="p_patner_chat">Inicia un nuevo chat.</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default BandejaChats