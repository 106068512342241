import React, { useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import './styles/Calendar.css'
import SidebarCalendar from './Components/SidebarCalendar';
import NavbarCalendar from './Components/NavbarCalendar';
import MainCalendarContent from './Components/MainCalendarContent';

const Calendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [viewMode, setViewMode] = useState('dia');

  return (
    <main className="page-content">
      <MetaTags>
        <title>TrueContact | Calendario</title>
      </MetaTags>

      <section>
        <div>
          <NavbarCalendar setViewMode={setViewMode} viewMode={viewMode} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        </div>
        <div className="content_navbar_main">
          <div>
            <SidebarCalendar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>
          <div>
            <MainCalendarContent
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
          </div>
        </div>
      </section>
    </main>
  );
}

export default Calendar