import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction,
  changelayoutMode,
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";

// Importaciones nuevas para Drawer, íconos, etc.
import { TbChevronLeft } from "react-icons/tb";

import "./index.css";
import SidebarTools from "./SidebarTools";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Estado nuevo
      isMobile: false,
      drawerOpen: false,
      openTools: false,
    };

    // Enlaces de métodos (bindings)
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
    this.hideRightbar = this.hideRightbar.bind(this);

    // Nuevos métodos
    this.handleTools = this.handleTools.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  // ======== MÉTODOS NUEVOS ========
  handleTools() {
    this.setState((prevState) => ({ openTools: !prevState.openTools }));
  }

  toggleDrawer() {
    this.setState((prevState) => ({ drawerOpen: !prevState.drawerOpen }));
  }

  handleClickOutside(event) {
    const configPhone = document.querySelector(".content_config_phone");
    const toolButtons = document.querySelector(".tool-buttons-wrapper");
    const drawer = document.querySelector(".custom-drawer");

    if (
      configPhone &&
      !configPhone.contains(event.target) &&
      toolButtons &&
      !toolButtons.contains(event.target) &&
      drawer &&
      !drawer.contains(event.target)
    ) {
      this.setState({ openTools: false, drawerOpen: false });
    }
  }
  // ======== FIN MÉTODOS NUEVOS ========

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    // 1. Listeners para rightbar y click outside
    document.body.addEventListener("click", this.hideRightbar, true);
    document.addEventListener("mousedown", this.handleClickOutside);

    // 2. Manejo de preloader
    if (this.props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }

    // 3. Scroll top
    window.scrollTo(0, 0);

    // 4. Aplicar configuraciones (tema, sidebar, etc.)
    if (this.props.layoutMode) {
      this.props.changelayoutMode(this.props.layoutMode);
    }
    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme);
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }
    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType);
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
  }

  componentWillUnmount() {
    // Remover listeners
    document.body.removeEventListener("click", this.hideRightbar, true);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // Oculta rightbar al hacer click fuera
  hideRightbar(event) {
    const rightbar = document.getElementById("right-bar");
    if (rightbar && rightbar.contains(event.target)) {
      return;
    }
  }

  toggleMenuCallback() {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile);
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("default", this.state.isMobile);
    }
  }

  render() {
    const isUIpath = window.location.pathname === "/ui-components";

    

    return (
      <React.Fragment>
        {/* Preloader */}
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>

        {/* Layout Wrapper */}
        <div id="layout-wrapper">
          <Header toggleMenuCallback={this.toggleMenuCallback} />
          <Sidebar
            type={this.props.leftSideBarType}
            isMobile={this.state.isMobile}
          />

          <div
            className={classNames(
              { "main-content overflow-visible": isUIpath },
              { "main-content": !isUIpath },
              { "with-tools": this.state.openTools }
            )}
          >
            {this.props.children}
          </div>

          <div>
            <div className={`${this.state.openTools ? "button_menu_tools_close" : "content_menu_tools"}`} onClick={this.handleTools}>
            <TbChevronLeft size={18} className={`icon_chevron_tools ${this.state.openTools && "rotate_tools"}`}/>
            </div>
          </div>
          {
            this.state.openTools && (
              <div >
                <SidebarTools open={this.state.openTools}/>
              </div>
            )
          }

          {/* Footer */}
          <Footer />
        </div>

        {/* Rightbar (si aplica) */}
        {this.props.showRightSidebar ? <Rightbar /> : null}
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  layoutMode: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};

export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changelayoutMode,
  changeLayoutWidth,
  showRightSidebarAction,
})(withRouter(Layout));
