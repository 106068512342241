import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import classnames from "classnames";
//Import Breadcrumb
import Loader from "../../components/Common/Loader";
import { getCasosRead } from "../../helpers/backend";
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Timeline from "./TimeLine";
import ButtonVolver from "../../components/ButtonVolver";

import TicketCaso from '../../assets/images/ticket-caso.svg'

import './Casos.css'
import { useSelector } from "react-redux";

const CasosDetails = (props) => {
  const id = useParams().id;
  const URL = process.env.REACT_APP_API_URL;
  const url = URL.split("/api");
  const URL1 = process.env.REACT_APP_API_URL.split("/api").join("");
  const [activeTab, setActiveTab] = useState("1");
  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
  

  const [caso, setCaso] = useState({});
  console.log(caso);
  const getCasoData = async () => {
    const response = await getCasosRead(id);
    const data = response.data;
    setCaso(data);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const abrirImagenEnNuevaPestana = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };
    const theme = useSelector((state) => state.theme.theme) || "light";
  
  useEffect(() => {
    getCasoData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function Status({ estado }) {
    const estados = {
      EN_PAUSA: { className: "bg-info", text: "En Pausa" },
      PENDIENTE: { className: "bg-danger", text: "Pendiente" },
      SIN_ASIGNAR: { className: "bg-warning ", text: "Sin Asignar" },
      EN_PROCESO: { background: "#6f42c1", text: "En Proceso" },
      FINALIZADO: { background: "#09cc09", text: "Finalizado" },
      ASIGNADO: { className: "bg-primary ", text: "Asignado" },
    };

    const estadoActual = estados[estado];

    return (
      <div
        className={`badge rounded-pill ${estadoActual.className} font-size-10`}
        style={{ background: estadoActual.background }}
      >
        <strong>{estadoActual.text}</strong>
      </div>
    );
  }
  
  const fotos = caso.images;

  useEffect(() => {
    if (fotos && fotos.length > 0) {
      setImagenSeleccionada(fotos[0].image);
    }
  }, [fotos]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Detalles del Caso </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          {props.loading ? (
            <Loader />
          ) : (
            <div className="d-flex justify-content-center">
              <Card
                className="ticket_detail"
                style={{ color: theme === "dark" && "#c3cbe4" }}
              >
                <div className="">
                  <ButtonVolver />
                </div>

                <div className="ticket_header_detail">
                  <div className="content_title_caso_detail">
                    <span className="title_caso_detail">{caso.name} </span>
                    {caso.estado ? (
                      <div style={{ zIndex: "999" }}>
                        <b className="">
                          <Status estado={caso.estado} />
                        </b>
                      </div>
                    ) : null}
                  </div>
                  {caso.total_duracion ? (
                    <p className="d-flex gap-2 justify-content-center">
                      Duración:
                      <span className="text_info_caso_detail">
                        {caso.total_duracion}
                      </span>
                    </p>
                  ) : null}
                </div>
                <div className="ticket_body_detail">
                  <Card style={{ width: "100%", height: "100%", border: "0" }}>
                    <Nav
                      tabs
                      justified
                      className="nav-tabs-custom"
                      role="tablist"
                      style={{}}
                    >
                      <NavItem>
                        <NavLink
                          to="#"
                          className={`p-2 ${classnames({
                            active: activeTab === "1",
                          })}`}
                          onClick={() => {
                            toggle("1");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fas fa-ticket-alt fa-md"></i>
                          <span className="d-none d-sm-block font-size-14">
                            Detalles del Caso
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={`p-2 ${classnames({
                            active: activeTab === "2",
                          })}`}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            toggle("2");
                          }}
                        >
                          <i className="fas fa-tasks fa-md "></i>
                          <span className="d-none d-sm-block font-size-14">
                            Gestiones del Caso
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={`p-2 ${classnames({
                            active: activeTab === "3",
                          })}`}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            toggle("3");
                          }}
                        >
                          <i className="fas fa-file-alt fa-md"></i>
                          <span className="d-none d-sm-block font-size-14">
                            Informe
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={`p-2 ${classnames({
                            active: activeTab === "4",
                          })}`}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            toggle("4");
                          }}
                        >
                          <i className="fas fa-box fa-md"></i>
                          <span className="d-none d-sm-block font-size-14">
                            Materiales
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent className="p-4" activeTab={activeTab}>
                      <TabPane id="1" role="tabpanel" tabId="1">
                        <Row
                          className="d-flex"
                          style={{
                            lineHeight: "1rem",
                            display: "flex",
                            color: theme === "dark" && "#c3cbe4",
                            paddingBottom: "0",
                            fontSize: ".9rem",
                          }}
                        >
                          <Col lg={6}>
                            <p className="text_info_caso_detail">Detalles:</p>
                            <p>
                              Fecha de Creación:
                              <span className="text_info_caso_detail">
                                {" "}
                                {caso.created_date}
                              </span>
                            </p>
                            <p>
                              Tipo de Servicio:
                              <span className="text_info_caso_detail">
                                {" "}
                                {caso.tipo_servicio}
                              </span>
                            </p>
                            <p>
                              Descripción:
                              <span className="text_info_caso_detail">
                                {" "}
                                {caso.descripcion}
                              </span>
                            </p>
                            {caso.tipo_servicio === "DESPLAZAMIENTO" ? (
                              <>
                                <p>Ubicación: {caso.direccion_1}</p>
                                <p>Destino: {caso.direccion_2}</p>{" "}
                              </>
                            ) : null}
                            <p>
                              Observaciones:
                              <span className="text_info_caso_detail">
                                {" "}
                                {caso.observaciones}
                              </span>
                            </p>
                            <p>
                              Agente(s):
                              {caso.contacto &&
                                caso.contacto.map((contacto, index) => (
                                  <span
                                    key={index}
                                    className="text_info_caso_detail"
                                  >
                                    {" "}
                                    {contacto[0] || contacto}
                                  </span>
                                ))}
                            </p>
                            <p>
                              Cliente(s):
                              {caso.cliente &&
                                caso.cliente.map((cliente, index) => (
                                  <span
                                    key={index}
                                    className="text_info_caso_detail"
                                  >
                                    {" "}
                                    {cliente[0] || cliente}
                                  </span>
                                ))}
                            </p>

                            <p
                              className="text_info_caso_detail"
                              style={{ paddingTop: "1rem" }}
                            >
                              Documentos:
                            </p>
                            <p
                              style={{
                                paddingTop: ".5rem",
                                color: theme === "dark" ? "#c1c1c1" : "#c1c1c1",
                                textAlign: "center",
                                opacity: theme === "dark" && ".5",
                              }}
                            >
                              Sin documentos
                            </p>
                          </Col>

                          {caso.images && caso.images.length !== 0 ? (
                            <>
                              <Col lg={6}>
                                <div>
                                  <Card
                                    style={{
                                      border: "none",
                                      width: "100%",
                                      padding: "0",
                                    }}
                                  >
                                    <div style={{}}>
                                      <section className="contain_images_caso">
                                        <div>
                                          {imagenSeleccionada ? (
                                            // Imagen principal
                                            <div
                                              className="content_img_caso_detail"
                                              onClick={() =>
                                                abrirImagenEnNuevaPestana(
                                                  url[0] + imagenSeleccionada
                                                )
                                              }
                                            >
                                              <img
                                                className={`${
                                                  theme === "dark"
                                                    ? "img_caso_detail_dark"
                                                    : "img_caso_detail"
                                                }`}
                                                src={
                                                  url[0] + imagenSeleccionada
                                                }
                                                alt={imagenSeleccionada}
                                              />
                                            </div>
                                          ) : (
                                            // Imagen predeterminada si no hay seleccionada
                                            <div className="content_img_caso_detail">
                                              <img
                                                className={`${
                                                  theme === "dark"
                                                    ? "img_caso_detail_dark"
                                                    : "img_caso_detail"
                                                }`}
                                                src={TicketCaso}
                                                alt="Imagen no disponible"
                                              />
                                            </div>
                                          )}
                                        </div>

                                        <div className="content_img_miniatura_casos">
                                          {fotos &&
                                            fotos.map((item, index) => (
                                              <div
                                                key={index}
                                                style={{
                                                  border:
                                                    imagenSeleccionada ===
                                                    item.image
                                                      ? "2px solid #247fb4"
                                                      : "1px solid #d2d2d2",
                                                }}
                                                className="content_img_miniatura_casos"
                                                onClick={() =>
                                                  setImagenSeleccionada(
                                                    item.image
                                                  )
                                                }
                                              >
                                                <img
                                                  src={url[0] + item.image}
                                                  alt={item.image}
                                                  className="img_miniatura_caso_detail"
                                                />
                                              </div>
                                            ))}
                                        </div>
                                      </section>
                                    </div>
                                  </Card>
                                </div>
                              </Col>
                            </>
                          ) : (
                            <Col lg={6}>
                              <div
                                className="content_img_caso_detail"
                                style={{
                                  width: "80%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  className={`${
                                    theme === "dark"
                                      ? "img_caso_detail_dark"
                                      : "img_caso_detail"
                                  }`}
                                  src={TicketCaso}
                                  alt="Imagen no disponible"
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                      </TabPane>
                      <TabPane id="2" role="tabpanel" tabId="2">
                        <Timeline gestiones={caso.gestiones} />
                      </TabPane>

                      <TabPane id="3" role="tabpanel" tabId="3">
                        <Row className="mb-4">
                          <Col lg={10} className="mx-auto">
                            <Card>
                              <CardBody style={{color: theme === 'dark' && '#c3cbe4'}}>
                                <h5  className="mb-4 text-center">
                                  Detalles del Informe
                                </h5>
                                <Row>
                                  <Col lg={12}>
                                    <p>
                                      Descripción:{" "}
                                      <span className="text_info_caso_detail">
                                        {caso.informe?.[0]?.descripcion ||
                                          "Sin descripción"}
                                      </span>
                                    </p>
                                    <p>
                                      Persona que recibe:{" "}
                                      <span className="text_info_caso_detail">
                                        {caso.informe?.[0]?.persona_recibe ||
                                          "Sin descripción"}
                                      </span>
                                    </p>
                                    <p>
                                      Cargo:{" "}
                                      <span className="text_info_caso_detail">
                                        {caso.informe?.[0]?.cargo ||
                                          "Sin descripción"}
                                      </span>
                                    </p>
                                    <p>
                                      Observaciones:{" "}
                                      <span className="text_info_caso_detail">
                                        {caso.informe?.[0]?.observaciones ||
                                          "Sin observaciones"}
                                      </span>
                                    </p>
                                    
                                  </Col>
                                </Row>
                                <hr className="my-4" />
                                <Row className="text-center">
                                  <Col>
                                    <p>
                                      <b>Firma:</b>
                                    </p>
                                    {caso.informe?.[0]?.firma ? (
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          display: "inline-block",
                                        }}
                                        onClick={() =>
                                          abrirImagenEnNuevaPestana(
                                            `${URL1}${caso.informe?.[0]?.firma}`
                                          )
                                        }
                                      >
                                        <img
                                          style={{
                                            width: "30%",
                                            height: "auto",
                                            border: "1px solid #ddd",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            backgroundColor: theme === 'dark' && '#fff'
                                          }}
                                          src={`${URL1}${caso.informe?.[0]?.firma}`}
                                          alt="Firma"
                                        />
                                      </div>
                                    ) : (
                                      <p className="text-muted">
                                        Sin firma disponible
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane id="4" role="tabpanel" tabId="4">
                        {Array.isArray(caso?.materiales) &&
                        caso.materiales.length > 0 ? (
                          <Table
                            className={`font-size-13 text-center mt-4 mb-0 ${
                              theme === "dark" ? "table-dark" : "table-light"
                            }`}
                            responsive
                            hover
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    color: theme === "dark" && "#c3cbe4",
                                  }}
                                >
                                  Descripcion
                                </th>
                                <th
                                  style={{
                                    color: theme === "dark" && "#c3cbe4",
                                  }}
                                >
                                  Cantidad
                                </th>
                                <th
                                  style={{
                                    color: theme === "dark" && "#c3cbe4",
                                  }}
                                >
                                  Observaciones
                                </th>
                              </tr>
                            </thead>
                            <tbody
                              className={`align-middle ${
                                theme === "dark" ? "" : "table-light"
                              }`}
                            >
                              {caso.materiales.map((material, index) => (
                                <tr key={material.id || index}>
                                  <td
                                    style={{
                                      backgroundColor:
                                        theme === "dark" ? "#121e2d" : "#fff",
                                      height: "60px",
                                    }}
                                  >
                                    {material.descripcion}
                                  </td>
                                  {material.cantidad ? (
                                    <td
                                      style={{
                                        backgroundColor:
                                          theme === "dark" ? "#121e2d" : "#fff",
                                      }}
                                    >
                                      {material.cantidad}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        backgroundColor:
                                          theme === "dark" ? "#121e2d" : "#fff",
                                        color:
                                          theme === "dark"
                                            ? "#c3cbdc66"
                                            : "#00000066",
                                      }}
                                    >
                                      Sin cantidad definida
                                    </td>
                                  )}
                                  {material.observaciones ? (
                                    <td
                                      style={{
                                        backgroundColor:
                                          theme === "dark" ? "#121e2d" : "#fff",
                                      }}
                                    >
                                      {material.observaciones}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        backgroundColor:
                                          theme === "dark" ? "#121e2d" : "#fff",
                                        color:
                                          theme === "dark"
                                            ? "#c3cbdc66"
                                            : "#00000066",
                                      }}
                                    >
                                      Sin observaciones
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <p>No hay materiales disponibles.</p>
                        )}
                      </TabPane>
                    </TabContent>
                  </Card>
                </div>
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CasosDetails;
