import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader";

import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

//Select
import { contactsUpdate } from "../../store/contacts/actions";
import { getAgent } from "../../helpers/backend";

import { getContactsFullList } from "../../helpers/backend";

const AgentAssign = (props) => {
  const history = useHistory();
  const dispatch= useDispatch();

  
  useEffect(() => {
       getAgentData();
       getContactsData();
  
    },[dispatch]);


    const  [selectedContacts, setselectedContacts] = useState(null); 
    const  [agentList, setAgentList] = useState ({});
    const  [contacts, setContacts] = useState ({});
    const  [selectedAgente, setSelectedAgente] = useState([]);
    const  [assign, setAssign] = useState(false);
    const  [alert, setAlert] = useState(false)


    const getAgentData = async () => {
      const response = await getAgent();
      const data = response.data;
      setAgentList(data)
    };
    
    const getContactsData = async () => {
      const response = await getContactsFullList();
      const data = response.data;
      setContacts(data)
    };
    const handleSelectContacts = ({value}) => {
      setselectedContacts (value);
   
    }  

    const contacto = selectedContacts;
    const agent = selectedAgente && selectedAgente.map( item => item.value );

    const results = props.contacts.results;
    const profile = results && results.filter (item => item.id === contacto)
      const nombre =  profile && profile.map(item => item.nombre)
      const apellido = profile && profile.map(item => item.apellido)
      const tipo_contacto =profile && profile.map(item => item.tipo_contacto)
      const tipo_identificacion = profile && profile.map(item => item.tipo_identificacion)
      const documento = profile && profile.map(item => item.documento)

  
 const onSubmit = (e) => {
  e.preventDefault();
  dispatch(contactsUpdate( contacto , {
    nombre: String(nombre),
    apellido:String(apellido),
    tipo_contacto: String(tipo_contacto),
    tipo_identificacion: String(tipo_identificacion),
    documento:String(documento),
    agent,
  }));
  setAlert(true)
 };


  const redirect = () => {
    history.push(`/rol/tramite_assign/${contacto}`);
    setAlert(false)
  };
 console.log(agentList);
   return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Roles| Asignar Agente </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <div style={{ width:"100%", paddingBottom:"1.5rem"}}>
            <span style={{fontSize:"1.2rem", fontWeight:"600"}}>Asignar agente</span>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
            <Card className= "col-lg-8  mx-auto  mt-3">
              
              {props.contactsUpdated.id && alert ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha asignado el Agente con éxito 
                </SweetAlert>
              ) : null}
              <CardBody>
                <h4 className="card-title">Asignar Agente</h4>
                <Form className="container form-control" onSubmit={onSubmit}>

                <Row className="col-lg-12 justify-content-center">
                    <Col lg={6} className="mb-3">
                      <Label
                        for="constacts"
                        className="form-label"
                      >
                     <strong>Contactos</strong> 
                      </Label>
                    <Select
                            id="contacto"
                            name="contacto"
                            placeholder ="Seleccione"
                            onChange={handleSelectContacts}
                            options={Array.isArray(contacts) ? 
                              contacts.map(item=> ({label: item.documentNumber+" - " + (item.first_name ?  
                                item.get_full_name : item.razon_social), value: item.id})): []} 
                            menuPosition="fixed"    
                          />
                       
                    </Col>

                  </Row> 
                {contacto && assign === false ? (
                  <Row className="col-lg-12 justify-content-center">
                  <Col md={1} className=''>
                    <Button color="primary" onClick={()=> redirect()}>
                      Ver
                    </Button>
                  </Col>
                  <Col md={1} className=''>
                    <Link className="btn btn-success" to="#" onClick={()=> setAssign(true)}>
                      Asignar
                    </Link>
                  </Col>

                </Row>
                ): null}
                {assign ? (
                  <>                  <Row className="col-lg-12 justify-content-center">
                  <Col lg={6} className="mb-3">
                      <Label
                        for="Agente"
                        className="form-label"
                      >
                    <strong>Agente</strong> 
                      </Label>
                      <Select
                            id="agente"
                            name="agente"
                            onChange={(item) => setSelectedAgente(item)}                            
                            options={agentList && agentList.map(item =>({label: item.get_full_name,  value:item.id }))}
                            placeholder ={'Seleccione'}
                            isMulti={true}
                            menuPosition="fixed"
                          />
                      <FormFeedback>{props.error.Agente}</FormFeedback>
                    </Col>
                    </Row>

                  <Row className="col-lg-12 justify-content-center">
                    <Col md={4} className='mx-1'>
                      <Link className="btn btn-secondary text-dark text-dark" to="/dashboard">
                        Volver
                      </Link>
                      <Button color="primary" type="submit" className=" ms-1">
                        Enviar
                      </Button>
                    </Col>

                  </Row>
                  </>

                ):null}
                </Form>               
                
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, contacts, contactsUpdated } = state.Contacts;
  return { error, loading, contacts, contactsUpdated };
};
contactsUpdate.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  contactsUpdated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { contactsUpdate })(AgentAssign));
