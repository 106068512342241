import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "react-drawer/lib/react-drawer.css";

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo2 from "../../assets/images/logo-tc.png";
import logo3 from "../../assets/images/logo-symbol-tc.png";
import { TbMoonFilled, TbSunFilled, TbArrowNarrowRight, TbArrowNarrowLeft } from "react-icons/tb";

// Redux Store
import { toggleLeftmenu, changeSidebarType } from "../../store/actions";
import { toggleTheme } from "../../store/theme/actions"; // Importa la acción para cambiar el tema
import PhonePanel from "../PhonePanel";
import SessionTimer from "../CommonForBoth/TopbarDropdown/SessionTimer";
import './index.css'

const Header = (props) => {
  
  // Extraemos el tema desde Redux a través de props
  const { theme } = props;
  const [arrowMenu, setArrowMenu] = useState(true)

  const handleArrowMenu = () => {
    setArrowMenu(!arrowMenu)
  }

  // Actualiza los atributos del body cada vez que el tema cambie
  useEffect(() => {
    document.body.setAttribute("data-layout-mode", theme);
    document.body.setAttribute("data-topbar", theme);
    document.body.setAttribute("data-sidebar", theme);
  }, [theme]);

  // Manejador para el cambio de tema usando Redux
  const handleToggleTheme = () => {
    // Si deseas agregar una transición, puedes hacerlo así:
    document.body.classList.add("theme-transition");
    props.toggleTheme(); // Despacha la acción de cambio de tema
    setTimeout(() => {
      document.body.classList.remove("theme-transition");
    }, 1000);
  };

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    /*
      Lógica para cambiar el sidebar
    */
    if (document.body.clientWidth >= 993) {
      // Vista de escritorio
      if (props.leftSideBarType === "default") {
        props.changeSidebarType("small");
      } else if (
        props.leftSideBarType === "small" ||
        props.leftSideBarType === "compact"
      ) {
        props.changeSidebarType("default");
      }
    } else {
      // Vista móvil
      document.body.classList.toggle("sidebar-enable");
      props.changeSidebarType("default");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div
              className="navbar-brand-box"
              style={{
                transition: "all .35s ease",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRight:
                    theme === "dark"
                      ? "1px solid #192a3f"
                      : "1px solid #e9ebec",
                width:"230px",
                borderBottom: theme === 'dark' ? "1px solid #121e2d" : "1px solid #fff"
              }}
            >
              <Link to="/dashboard" className="logo ">
                <span className="logo-sm">
                  <img
                    src={logo3}
                    alt=""
                    height="25"
                    style={{ filter: "drop-shadow(0 0 .4px white)" }}
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={logo2}
                    alt=""
                    height="40"
                    width="120"
                    style={{ filter: "drop-shadow(0 0 .2px white)" }}
                  />
                </span>
              </Link>
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                onClick={() => {
                  tToggle();
                  handleArrowMenu();
                }}
                style={{
                  border:
                    theme === "dark"
                      ? "1px solid #192a3f"
                      : "1px solid #e9ebec",
                      borderLeft:
                    theme === "dark"
                      ? "1px solid #121e2d"
                      : "1px solid #fff",
                  backgroundColor: theme === "dark" ? "#121e2d" : "#fff",
                  color: theme === 'dark' ? '#c3cbe4' : '#545a6d',
                  height: "max-content",
                  position: "absolute",
                  left: "-1px",
                }}
                className="button_menu"
                id="vertical-menu-btn"
              >
                {arrowMenu ? (
                  <TbArrowNarrowRight size={19} />
                ) : (
                  <TbArrowNarrowLeft size={19} />
                )}
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center gap-0">
            <SessionTimer />
            <button
              onClick={handleToggleTheme}
              className="btn header-item noti-icon"
              style={{
                background: "transparent",
                display: "grid",
                placeItems: "center",
                border: "none",
                cursor: "pointer",
                fontSize: "19px",
                color: theme === "light" ? "#555b6d" : "#f8f9fa",
              }}
            >
              {theme === "light" ? <TbSunFilled size={21} /> : <TbMoonFilled />}
            </button>

            <NotificationDropdown />
            <ProfileMenu />

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={toggleFullscreen}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen" />
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  theme: PropTypes.string.isRequired,       // Agregado para Redux
  toggleTheme: PropTypes.func.isRequired,     // Agregado para Redux
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
    theme: state.theme.theme, // Obtenemos el tema desde Redux
  };
};

export default connect(mapStatetoProps, {
  toggleLeftmenu,
  changeSidebarType,
  toggleTheme, // Conectamos la acción para cambiar el tema
})(Header);
