import { all,fork } from "redux-saga/effects";

//public
import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import userSaga from "./admin/user/saga";
import rolSaga from "./roles/saga"
import accountSaga from "./account/saga"
import campaignSaga from "./campaign/saga"
import leadsSaga from "./leads/saga"
import contactsSaga from "./contacts/saga";
import contactsDataSaga from "./contactsData/saga"
import contactsDocumentSaga from "./contactsDocument/saga"
import auditsSaga from "./audits/saga"
import calificacionContactoSaga from "./calificacionContacto/saga";
import descripcionCalificacionSaga from "./descripcionCalificacion/saga";
import gestionesContactoSaga from "./gestionesContacto/saga";
import tipoGestionContactoSaga from "./tipoGestionContacto/saga"
import casosSagas from "./casos/saga"
import productoSagas from "./productos/saga"
import projectSagas from "./project/saga"



export default function* rootSaga() {
  yield all([
    layoutSaga(),
    fork(authSaga),
    fork(rolSaga),
    fork(accountSaga),
    fork(campaignSaga),
    fork(leadsSaga),
    fork(userSaga),
    fork(contactsSaga),
    fork(contactsDataSaga),
    fork(contactsDocumentSaga),
    fork(descripcionCalificacionSaga),
    fork(calificacionContactoSaga),
    fork(tipoGestionContactoSaga),
    fork(auditsSaga),
    fork(gestionesContactoSaga),
    fork(casosSagas),
    fork(productoSagas),
    fork(projectSagas),

  ]);
}
