import React from 'react';
import '../App.css';

const ChatHistory = ({ chats, onSelectChat, onCreateChat }) => {
  return (
    <div className="ds-chat-history">
      <button className="ds-chat-history__button" onClick={onCreateChat}>
        Crear nuevo chat
      </button>
      <ul className="ds-chat-history__list">
        {chats.map((chat, index) => (
          <li
            key={index}
            className="ds-chat-history__item"
            onClick={() => onSelectChat(chat.id)}
          >
            <div className="ds-chat-history__date">{chat.date}</div>
            <div className="ds-chat-history__preview">
              {chat.preview || "Nuevo chat"}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatHistory;