import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import MetisMenu from "metismenujs";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import logo3 from "../../assets/images/logo-symbol-tc.png";
import { getModules } from "../../helpers/backend";
import {
  TbUser,
  TbUsers,
  TbFlag,
  TbFileDescription,
  TbBuildingStore,
  TbLayoutDashboard,
  TbFlagFilled,
  TbUsersGroup,
  TbTicket,
  TbListCheck,
  TbProgressHelp,
  TbMapQuestion,
  TbQuestionMark,
  TbCalendar,
  TbFileCheck,
} from "react-icons/tb";

const SidebarContent = (props) => {
  const ref = useRef();
  const user = JSON.parse(localStorage.getItem("user"));
  const groups = String(user.groups);

  const [activeModules, setActiveModules] = useState([]);
  const getDataModule = async () => {
    const response = await getModules();
    // Filtrar módulos activos
    const active = response.data.results.filter((module) => module.active);
    setActiveModules(active);
  };

  useEffect(() => {
    getDataModule();
  }, []);

  useEffect(() => {
    const initMenu = () => {
      new MetisMenu("#side-menu");
    };
    initMenu();
  }, []);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  // Función para verificar si un módulo está activo
  const isModuleActive = (moduleName) => {
    return activeModules.some((module) => module.name === moduleName);
  };

  // Función para verificar si el usuario es SuperAdmin
  const isSuperAdmin = () => {
    return groups.includes("SuperAdmin");
  };

  // Función para verificar si el usuario es Administrador
  const isAdministrador = () => {
    return groups.includes("Administrador");
  };

  // Función para verificar si el usuario es Supervisor
  const isSupervisor = () => {
    return groups.includes("Supervisor");
  };

  // Función para verificar si el usuario tiene permisos de SuperAdmin o Administrador
  const hasAdminPermissions = () => {
    return isSuperAdmin() || isAdministrador();
  };

  return (
    <React.Fragment>
      <SimpleBar style={{ height: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul
            className="metismenu list-unstyled"
            style={{ display: "grid", gap: "0" }}
            id="side-menu"
          >
            <li className="menu-title">{props.t("Menu")} </li>

            {/* Dashboard */}
            <li>
              <Link
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: ".7rem",
                }}
                to="/dashboard"
              >
                <TbLayoutDashboard
                  className="icon_menu"
                  size={22}
                  strokeWidth={2.3}
                />
                <span style={{ fontSize: ".9rem" }} key="t-dashboard">
                  Dashboard
                </span>
              </Link>
            </li>

            {/* Administración (Solo SuperAdmin) */}
            {isSuperAdmin() && isModuleActive("User") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".7rem",
                  }}
                  to="#"
                >
                  <TbUsers className="icon_menu" size={22} strokeWidth={3} />
                  <span style={{ fontSize: ".9rem" }} key="t-users">
                    Administración
                  </span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link style={{ fontSize: ".775rem" }} to="/users">
                      Usuarios
                    </Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: ".775rem" }} to="/modules">
                      Módulos
                    </Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: ".775rem" }} to="/rol/add_rol">
                      Roles
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            {/* Campañas (SuperAdmin y Administrador) */}
            {hasAdminPermissions() && isModuleActive("Campañas") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".7rem",
                  }}
                  to="/campaign"
                >
                  <TbFlagFilled
                    className="icon_menu"
                    size={22}
                    strokeWidth={3}
                  />
                  <span style={{ fontSize: ".9rem" }} key="t-Parametrizaciones">
                    Campañas
                  </span>
                </Link>
              </li>
            ) : null}

            {/* Contactos (Todos los roles) */}
            {isModuleActive("Contactos") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".7rem",
                  }}
                  to="/contacts"
                >
                  <TbUser className="icon_menu" size={22} strokeWidth={3} />
                  <span style={{ fontSize: ".9rem" }} key="t-contacts">
                    Contactos
                  </span>
                </Link>
              </li>
            ) : null}

            {/* Leads (Todos los roles) */}
            {isModuleActive("Leads") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".8rem",
                  }}
                  to="/leads"
                >
                  <TbUsersGroup
                    className="icon_menu"
                    size={22}
                    strokeWidth={2.2}
                  />
                  <span style={{ fontSize: ".9rem" }} key="t-Parametrizaciones">
                    Leads
                  </span>
                </Link>
              </li>
            ) : null}

            {/* TrueContact (Todos los roles) */}
            <li>
              <Link
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: ".75rem",
                }}
                to="/chat"
              >
                <img src={logo3} alt="" height="22" className="" />
                <span style={{ fontSize: ".9rem" }} key="t-canales">
                  TrueContact
                </span>
              </Link>
            </li>

            {/* Casos Todos los roles */}
            {isModuleActive("Casos") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".7rem",
                  }}
                  to="/casos"
                >
                  <TbTicket className="icon_menu" size={22} strokeWidth={2.3} />
                  <span style={{ fontSize: ".9rem" }} key="t-casos">
                    Casos
                  </span>
                </Link>
              </li>
            ) : null}

            {/* Tareas (SuperAdmin y Administrador) */}
            {isModuleActive("Tareas") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".7rem",
                  }}
                  to={`/project`}
                >
                  <TbListCheck
                    className="icon_menu"
                    size={22}
                    strokeWidth={2.5}
                  />
                  <span style={{ fontSize: ".9rem" }} key="t-survey">
                    Tareas
                  </span>
                </Link>
              </li>
            ) : null}

            {/* Encuestas (SuperAdmin y Administrador) */}
            {hasAdminPermissions() && isModuleActive("Encuestas") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".6rem",
                  }}
                  to={`/survey`}
                >
                  <TbProgressHelp
                    className="icon_menu"
                    size={24}
                    strokeWidth={2.3}
                  />
                  <span style={{ fontSize: ".9rem" }} key="t-survey">
                    Encuestas
                  </span>
                </Link>
              </li>
            ) : null}

            {/* Calendario (SuperAdmin y Administrador) */}
            { isModuleActive("Calendario") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".6rem",
                  }}
                  to={`/calendar`}
                >
                  <TbCalendar
                    className="icon_menu"
                    size={24}
                    strokeWidth={2.3}
                  />
                  <span style={{ fontSize: ".9rem" }} key="t-calendar">
                    Calendario
                  </span>
                </Link>
              </li>
            ) : null}

            {/* Tienda (SuperAdmin y Administrador) */}
            {hasAdminPermissions() && isModuleActive("Tienda") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".75rem",
                  }}
                  to="/audits"
                >
                  <TbBuildingStore
                    className="icon_menu"
                    size={22}
                    strokeWidth={2.1}
                  />
                  <span style={{ fontSize: ".9rem" }} key="t-audits">
                    Tienda
                  </span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link style={{ fontSize: ".775rem" }} to="/productos">
                      Productos
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            {/* Auditoría (SuperAdmin y Administrador) */}
            {hasAdminPermissions() && isModuleActive("Auditoría") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".65rem",
                  }}
                  to="/audits"
                >
                  <TbFileCheck
                    className="icon_menu"
                    size={23}
                    strokeWidth={2.1}
                  />
                  <span style={{ fontSize: ".9rem" }} key="t-audits">
                    Auditoría
                  </span>
                </Link>
              </li>
            ) : null}

            {/* Reportes (SuperAdmin, Administrador y Supervisor) */}
            {(hasAdminPermissions() || isSupervisor()) &&
            isModuleActive("Reportes") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".7rem",
                  }}
                  to="#"
                >
                  <TbFileDescription
                    className="icon_menu"
                    size={22}
                    strokeWidth={2.3}
                  />
                  <span style={{ fontSize: ".9rem" }} key="t-file">
                    Reportes
                  </span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link style={{ fontSize: ".775rem" }} to="/reports">
                      Reportes
                    </Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: ".775rem" }} to="/managementList">
                      Lista de Gestiones
                    </Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: ".775rem" }} to="/issabelReport">
                      Reportes Issabel
                    </Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: ".775rem" }} to="/report_supervision">
                      Reporte de Supervisión
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
};

export default withRouter(withTranslation()(SidebarContent));