import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Form,
  Button,
  CardTitle,
  Input,
  FormFeedback,
  Alert,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"

import { campaignCreate } from "../../store/campaign/actions";
import { getSupervisor } from "../../helpers/backend";

const CampaignCreate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [contactsList, setContactsList] = useState({});
  const getContactsData = async () => {
    const response = await getSupervisor();
    const data = response.data;
    setContactsList(data);
  };

  useEffect(() => {
    getContactsData();

  }, []);

  const [formData, setFormData] = useState({
    
    name: "",
    description:"",
    encargado: [],
  });
  
  const {
    name,    
    description,    
  } = formData;
  
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    }

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(campaignCreate(formData));
  };
  
  const redirect = () => {
    history.push("/campaign");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact  | Campañas </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div style={{ width:"100%", paddingBottom:"1.5rem"}}>
            <span style={{fontSize:"1.2rem", fontWeight:"600"}}>Campañas</span>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
          <div className="d-flex justify-content-center">
              {props.campaignCreated.id ? (
                <SweetAlert
                  title="¡Hecho!"
                  success
                  showConfirm={false}
                  timeout={2000}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha
                  agregado <b>{props.campaignCreated.nombre}</b> correctamente.
                </SweetAlert>
              ) : null}

              <Card
                style={{
                  width: "60rem",
                }}
              >
                <CardTitle className="ps-3 pt-3">
                  <h2 className="font-size-20"> Agregar Campañas</h2>
                </CardTitle>
                <CardBody>
                <Form onSubmit={onSubmit} className="form-control">
                  <Row className="justify-content-md-center" >
                    <Col lg={10}>
                <Row className="mt-3">
                  <Col>
                    <Label
                      for="name"                      
                    >
                      Nombre
                    </Label>
                    <Input                      
                      id="name"
                      name="name"
                      type="name"
                      onChange={onChange}
                      value= {name}
                      autoFocus
                      required
      
                    />
                    {props.error && props.error.name ? (
                      <Alert color = 'danger' className="mt-2">{props.error.name}</Alert>
                    ):null}
                  </Col>      
                </Row>
                <Row className="mt-3">
                  <Col>  
                    <Label
                      for="description"
                      
                    >
                      Descripción
                    </Label>
                    <Input
                     
                      id="description"
                      name="description"
                      type="text"
                      onChange={onChange}
                      value={description}

                      required 
                    />
                   
                    <FormFeedback>{props.error.description}</FormFeedback>
                    

                  </Col>
                  <Col>
                    <Label
                      for="encargado"                      
                    >  Encargado
                    </Label>  
                   <Select   
                      isMulti={true}                  
                      id="encargado"
                      name="encargado"  
                      options={ Array.isArray(contactsList) ? 
                        contactsList.map(item=> ({label: item.get_full_name, value: item.id})) : [] } 
                      placeholder='Seleccione'                  
                      onChange={(item) => setFormData({
                        ...formData,
                        encargado : item && item.map( item => item.value)
                      })}                                          
                      required 
                      menuPosition="fixed"
                    /> 
                    

                    {props.error && props.error.encargado ? (
                    <Alert color = 'danger' className="mt-2">{props.error.encargado}</Alert>
                    ):null}

                  </Col>
                </Row>

                    <Row>
                      <Col className="d-flex justify-content-center mt-5 mb-2 " >
                        <Link className="btn btn-secondary text-dark me-1" to="/campaign">
                          Volver
                        </Link>
                        <Button color="primary"  type="submit">
                          Agregar
                        </Button>
                      </Col>
                    </Row>
                    </Col>
                  </Row>
                </Form>
                </CardBody>
              </Card>

            
          </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { error, loading, campaignCreated } = state.Campaign;
  return { error, loading, campaignCreated };
};

export default withRouter(connect(mapStateToProps)(CampaignCreate));